import { useMemo } from "react";
import { Control, Controller, useController } from "react-hook-form";
import {
  DatabaseService,
  DBSAddon,
  DBSAvailability,
  DBSCapabilities,
  DBSEngine,
  DBSGroupAvailability,
  DBSNodeType,
} from "../../types/database";
import { RadioGroup } from "@headlessui/react";
import { binarySize, humanizeSize, biteSize } from "../../utils/file";
import { capitalizeFirstLetter } from "../../utils";
import Slider from "rc-slider";
import { getDBserviveData } from "./../../components/databases/services/DatabaseServicesTab";
import { RadioGroupeItem } from "../../components/general/RadioGrouper";
import { regionsData } from "../../components/instances/create/utils";
import {
  Alert,
  AnimatedFormError,
  Button,
  Flex,
  Input,
  Tag,
  Typography,
  cn,
} from "djuno-design";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "./../../assets/icons/minus.svg";

export const dbsPeriodItems: Array<RadioGroupeItem<"hour" | "month">> = [
  { label: "Hour", value: "hour" },
  { label: "Month", value: "month" },
];

export const DatabaseServiceTypesInput: React.FC<{
  engines: DBSEngine[];
  control: Control<any>;
  errorMessage: string | undefined;
}> = ({ engines, control, errorMessage }) => {
  const {
    field: { onChange: onChangeVersion },
  } = useController({ name: "SelectedEngineVersion", control });

  return (
    <Controller
      name="SelectedEngine"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup
          value={value || null}
          onChange={(v) => {
            onChange(v);
            const selectedEngine = engines.find((e) => e.name === v);
            if (selectedEngine) onChangeVersion(selectedEngine.defaultVersion);
          }}
        >
          <div className="flex flex-col w-full">
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
              {engines.map((engine, i) => (
                <RadioGroup.Option key={i} value={engine.name}>
                  {({ checked }) => {
                    const serviceData = getDBserviveData(engine.name);
                    return (
                      <div
                        className={cn(
                          "h-full col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer",
                          {
                            "border-primary-400 dark:border-primary-400":
                              checked,
                          }
                        )}
                      >
                        <div className="flex items-center justify-between">
                          <Typography.Text className="!text-sm font-medium">
                            {serviceData.title}
                          </Typography.Text>
                          <div className="w-16 aspect-square flex justify-center items-center">
                            {serviceData.Icon}
                          </div>
                        </div>
                        <Typography.Text
                          className="!text-xs mt-1"
                          uiType="secondary"
                        >
                          {engine.description}
                        </Typography.Text>
                      </div>
                    );
                  }}
                </RadioGroup.Option>
              ))}
            </div>
            <AnimatedFormError error={errorMessage} />
          </div>
        </RadioGroup>
      )}
    />
  );
};

export const DatabaseServicePlansInput: React.FC<{
  plans: DBSGroupAvailability[];
  control: Control<any>;
  addons: Array<DBSAddon>;
  period: string;
  errorMessage: string | undefined;
  disabledIndex?: number;
  selectedService?: DatabaseService | null;
}> = ({
  plans,
  control,
  addons,
  period,
  errorMessage,
  disabledIndex,
  selectedService,
}) => {
  return (
    <Controller
      name="SelectedPlan"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup
          value={value || null}
          onChange={(v) => {
            onChange(v);
          }}
        >
          <div className="flex flex-col w-full">
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
              {plans.map((plan, i) => {
                const filteredAddons = addons.filter((addon) =>
                  addon.planCode.includes(
                    plan.plan +
                      "-" +
                      (selectedService
                        ? selectedService.flavor
                        : plan.availability[0].specifications.flavor)
                  )
                );
                const exVAT =
                  filteredAddons.length > 0
                    ? getDBSPriceAmout(
                        filteredAddons[0].pricings[0].price *
                          (plan.minNodeNumber || 1)
                      )
                    : "0";
                const inclVAT =
                  filteredAddons.length > 0
                    ? getDBSPriceAmout(
                        (filteredAddons[0].pricings[0].price +
                          filteredAddons[0].pricings[0].tax) *
                          (plan.minNodeNumber || 1)
                      )
                    : "0";

                return (
                  <RadioGroup.Option
                    key={i}
                    value={plan.plan}
                    disabled={
                      filteredAddons.length === 0
                        ? true
                        : disabledIndex
                        ? i < disabledIndex
                        : false
                    }
                  >
                    {({ checked, disabled }) => {
                      let minDisk;
                      let maxDisk;

                      if (plan.minDiskSize && plan.minDiskSizeUnit) {
                        const minDiskBinary = binarySize(
                          plan.minDiskSize,
                          plan.minDiskSizeUnit.slice()[0],
                          1000
                        );
                        minDisk = humanizeSize(minDiskBinary, {
                          binaryBaseValue: 1000,
                        });
                      }

                      if (plan.maxDiskSize && plan.maxDiskSizeUnit) {
                        const maxDiskBinary = binarySize(
                          plan.maxDiskSize,
                          plan.maxDiskSizeUnit.slice()[0],
                          1000
                        );
                        maxDisk = humanizeSize(maxDiskBinary, {
                          binaryBaseValue: 1000,
                        });
                      }

                      let minMemory;
                      let maxMemory;
                      if (plan.minMemory && plan.minMemoryUnit) {
                        const minMemoryBinary = binarySize(
                          plan.minMemory,
                          plan.minMemoryUnit.slice()[0],
                          1000
                        );
                        minMemory = humanizeSize(minMemoryBinary, {
                          binaryBaseValue: 1000,
                        });
                      }

                      if (plan.maxMemory && plan.maxMemoryUnit) {
                        const maxMemoryBinary = binarySize(
                          plan.maxMemory,
                          plan.maxMemoryUnit.slice()[0],
                          1000
                        );
                        maxMemory = humanizeSize(maxMemoryBinary, {
                          binaryBaseValue: 1000,
                        });
                      }

                      return (
                        <div
                          className={cn(
                            "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                            {
                              "border-primary-400 dark:border-primary-400":
                                checked,
                              "!cursor-not-allowed": disabled,
                            }
                          )}
                        >
                          <div className="flex items-center justify-between mb-2">
                            <Typography.Text className="!text-sm">
                              {capitalizeFirstLetter(plan.plan)}
                            </Typography.Text>
                            {selectedService &&
                              selectedService.plan === plan.plan && (
                                <Tag className="text-xs ml-2" color="success">
                                  Current solution
                                </Tag>
                              )}
                          </div>
                          <div className="flex flex-col gap-1 border-t border-t-white/20 dark:border-t-gray-400/10 pt-2">
                            {minMemory && maxMemory && (
                              <Typography.Text
                                className="!text-xs mt-1"
                                uiType="secondary"
                              >
                                {plan.minMemory === plan.maxMemory
                                  ? `${minMemory.join("B")} RAM`
                                  : `From ${minMemory.join(
                                      "B"
                                    )} to ${maxMemory.join("B")} RAM`}
                              </Typography.Text>
                            )}
                            {typeof plan.minCore !== "undefined" &&
                              typeof plan.maxCore !== "undefined" && (
                                <>
                                  {plan.maxCore !== 0 && (
                                    <Typography.Text
                                      className="!text-xs mt-1"
                                      uiType="secondary"
                                    >
                                      {plan.minCore === plan.maxCore
                                        ? `${plan.minDiskSize} vCores`
                                        : `From ${plan.minCore} to ${plan.maxCore} vCores`}
                                    </Typography.Text>
                                  )}
                                </>
                              )}
                            {minDisk && maxDisk && (
                              <Typography.Text
                                className="!text-xs mt-1"
                                uiType="secondary"
                              >
                                {plan.minDiskSize === plan.maxDiskSize
                                  ? `${minDisk.join("B")} Storage`
                                  : `From ${minDisk.join(
                                      "B"
                                    )} to ${maxDisk.join("B")} Storage`}
                              </Typography.Text>
                            )}

                            <Typography.Text
                              className="!text-xs mt-1"
                              uiType="secondary"
                            >
                              {plan.minNodeNumber === plan.maxNodeNumber
                                ? `${plan.minNodeNumber} nodes`
                                : `${plan.minNodeNumber} nodes included, up to ${plan.maxNodeNumber} nodes`}
                            </Typography.Text>
                            {plan.backups.length > 0 && (
                              <Typography.Text
                                className="!text-xs mt-1"
                                uiType="secondary"
                              >
                                Manual and automatic backups
                              </Typography.Text>
                            )}
                          </div>
                          <div className="flex items-center border-t dark:border-t-gray-400/10 mt-2 pt-2 gap-0.5">
                            {filteredAddons.length === 0 ? (
                              <Alert
                                showIcon
                                uiType="error"
                                message={
                                  <Typography.Text size="xs">
                                    Not available due to current configuration
                                  </Typography.Text>
                                }
                              />
                            ) : (
                              <>
                                {" "}
                                {Number(exVAT) > 0 && (
                                  <Typography.Text className="!text-xs">
                                    From{" "}
                                  </Typography.Text>
                                )}
                                <Typography.Text className="!text-xs font-medium">
                                  €{exVAT} ex. VAT
                                </Typography.Text>
                                <Typography.Text className="!text-[0.6rem]">
                                  (€
                                  {inclVAT} incl. VAT)
                                </Typography.Text>
                                <Typography.Text className="!text-xs font-medium">
                                  /{period}
                                </Typography.Text>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </RadioGroup.Option>
                );
              })}
            </div>
            <AnimatedFormError error={errorMessage} />
          </div>
        </RadioGroup>
      )}
    />
  );
};

export const DatabaseServiceRegionsInput: React.FC<{
  regions: string[];
  control: Control<any>;
  errorMessage: string | undefined;
  selectedService?: DatabaseService | null;
}> = ({ regions, control, errorMessage, selectedService }) => {
  return (
    <Controller
      name="SelectedRegion"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value || null} onChange={onChange}>
          <div className="flex flex-col w-full">
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
              {regions.map((region, i) => {
                const regionData = regionsData.find(
                  (rd) => rd.datacenterLocation === region
                );
                return (
                  <RadioGroup.Option key={i} value={region}>
                    {({ checked }) => (
                      <div
                        className={cn(
                          "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer",
                          {
                            "border-primary-400 dark:border-primary-400":
                              checked,
                          }
                        )}
                      >
                        <div className="flex items-center justify-between mb-2">
                          <Typography.Text className="!text-sm">
                            {regionData ? regionData.title : region}
                          </Typography.Text>
                          <div className="flex items-center gap-2">
                            {selectedService &&
                              selectedService.nodes[0].region === region && (
                                <Tag className="text-xs ml-2" color="success">
                                  Current solution
                                </Tag>
                              )}
                            {regionData && regionData.dataImage && (
                              <span
                                style={{
                                  backgroundImage: `url(${regionData.dataImage})`,
                                  backgroundSize: "contain",
                                  backgroundPosition: "50%",
                                }}
                                className="w-4 h-4 inline-block align-middle bg-no-repeat"
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col border-t border-slate-200 dark:border-t-gray-400/10 mt-2 pt-2 gap-0.5">
                          <Typography.Text
                            uiType="secondary"
                            className="!text-xs"
                          >
                            {region.toUpperCase()}
                          </Typography.Text>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                );
              })}
            </div>
            <AnimatedFormError error={errorMessage} />
          </div>
        </RadioGroup>
      )}
    />
  );
};

export const NumberOfNodesInput: React.FC<{
  plan: DBSGroupAvailability;
  control: Control<any>;
  errorMessage: string | undefined;
}> = ({ plan, control, errorMessage }) => {
  const { minNodeNumber, maxNodeNumber } = plan;

  if (minNodeNumber === maxNodeNumber) {
    return (
      <Typography.Text className="!text-sm">
        {`Your ${capitalizeFirstLetter(plan.plan)} solution includes
            ${plan.minNodeNumber} nodes.`}
      </Typography.Text>
    );
  }
  return (
    <div>
      <Controller
        name="NumberOfNodes"
        control={control}
        render={({ field: { value, onChange: nodeChanger } }) => {
          const handlePlus = () => {
            if (value + 1 <= maxNodeNumber) {
              nodeChanger(value + 1);
            }
          };
          const handleMinus = () => {
            if (value - 1 >= minNodeNumber) {
              nodeChanger(value - 1);
            }
          };
          return (
            <div className="flex flex-col gap-2">
              <Typography.Text className="!text-xs">
                Select the number of nodes in the cluster
              </Typography.Text>
              <Flex items="center" className="gap-1">
                <Button
                  disabled={value <= minNodeNumber}
                  uiType={`light`}
                  className="!px-2"
                  onClick={handleMinus}
                >
                  <MinusIcon className="w-4 text-slate-800" />
                </Button>
                <Input
                  className="!w-16"
                  error={errorMessage}
                  value={value}
                  // onChange={(e: any) => {
                  //   const v = Number(e.target.value);
                  //   if (v < maxNodeNumber) {
                  //     nodeChanger(v);
                  //   } else if (v >= maxNodeNumber) {
                  //     nodeChanger(maxNodeNumber);
                  //   }

                  //   if (v === 0) {
                  //     nodeChanger("");
                  //   } else if (v < minNodeNumber) {
                  //     nodeChanger(minNodeNumber);
                  //   }
                  // }}
                  type="number"
                  readOnly
                />

                <Button
                  disabled={value >= maxNodeNumber}
                  uiType={`light`}
                  className="!px-2"
                  onClick={handlePlus}
                >
                  <PlusIcon className="w-4 text-slate-800" />
                </Button>
              </Flex>
            </div>
          );
        }}
      />
    </div>
  );
};

export const AdditionalStorageInput: React.FC<{
  nodeType: DBSNodeType;
  control: Control<any>;
  period: string;
  nodeCount: number;
  addon?: DBSAddon;
  errorMessage?: string;
}> = ({ nodeType, control, period, addon, nodeCount, errorMessage }) => {
  const {
    availability: {
      flavor,
      specifications: { storage },
    },
  } = nodeType;

  const additionalStorageData = useMemo(() => {
    let additionalBinary;
    let stepValue;
    if (storage) {
      const { minimum, maximum, step } = storage;
      const minStorageBinary = binarySize(
        minimum.value,
        minimum.unit.slice()[0],
        1000
      );
      const maxStorageBinary = binarySize(
        maximum.value,
        maximum.unit.slice()[0],
        1000
      );
      additionalBinary = maxStorageBinary - minStorageBinary;
      stepValue = step ? step.value : 0;
    } else {
      additionalBinary = 0;
      stepValue = 0;
    }

    return {
      storage: humanizeSize(additionalBinary, {
        fractionDigits: 2,
        binaryBaseValue: 1000,
      }),
      binary: additionalBinary,
      step: stepValue,
    };
  }, [storage]);

  if (additionalStorageData.binary === 0) {
    return null;
  }

  return (
    <div className="mt-5">
      <Flex direction="col" className="gap-3">
        <Typography.Text className="!text-base !font-medium">
          Additional storage
        </Typography.Text>
        {storage && (
          <Typography.Text className="!text-sm">
            {`The ${capitalizeFirstLetter(flavor)} node model includes ${
              storage.minimum.value
            } ${
              storage.minimum.unit
            } storage, to which you can add up to ${additionalStorageData.storage.join(
              "B"
            )} of additional storage in increments of ${
              additionalStorageData.step
            } GB.`}
          </Typography.Text>
        )}
      </Flex>
      <Controller
        name="AdditionalStorage"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <div className="flex items-center w-full gap-8 mt-3">
              <div className="flex flex-1 flex-col">
                <div className="flex flex-col">
                  <div className="flex items-center justify-between">
                    <Typography.Text className="!text-sm">None</Typography.Text>
                    <Typography.Text className="!text-sm">
                      {additionalStorageData.storage.join("B")}
                    </Typography.Text>
                  </div>
                  <div className="mx-2">
                    <Slider
                      className=""
                      step={10}
                      min={0}
                      max={biteSize(additionalStorageData.binary, "G", 1000)}
                      onChange={(value) =>
                        onChange(Array.isArray(value) ? value[0] : value)
                      }
                      value={value}
                    />
                  </div>
                </div>
                <div className="flex justify-center">
                  <Typography.Text className="!text-sm">
                    {value
                      ? humanizeSize(binarySize(value, "G", 1000), {
                          fractionDigits: 2,
                          binaryBaseValue: 1000,
                        }).join("B")
                      : "0"}
                  </Typography.Text>
                </div>
              </div>
              <div className="flex items-center w-1/6 flex-wrap">
                <Typography.Text className="!text-xs !font-medium !whitespace-nowrap">
                  €
                  {addon
                    ? getDBSPriceAmout(
                        addon.pricings[0].price *
                          (Number(value || 0) * nodeCount)
                      )
                    : "0"}{" "}
                  ex. VAT
                </Typography.Text>
                <Typography.Text className="!text-[0.6rem]  !whitespace-nowrap">
                  (€
                  {addon
                    ? getDBSPriceAmout(
                        (addon.pricings[0].price + addon.pricings[0].tax) *
                          (Number(value || 0) * nodeCount)
                      )
                    : "0"}{" "}
                  incl. VAT)
                </Typography.Text>
                <Typography.Text className="!text-xs !font-medium  !whitespace-nowrap">
                  /{period}
                </Typography.Text>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

// export function groupAvailabilityByPlan(
//   availability: Array<DBSAvailability>,
//   capabilities: DBSCapabilities
// ): Array<DBSGroupAvailability> {
//   const planGroups: { [key: string]: DBSGroupAvailability } = {};

//   availability.forEach((item) => {
//     const {
//       plan,
//       flavor: flavorName,
//       minNodeNumber,
//       maxNodeNumber,
//       backups,
//       network,
//     } = item;

//     let minCore: number | undefined;
//     let maxCore: number | undefined;
//     let minDiskSize: number | undefined;
//     let maxDiskSize: number | undefined;
//     let minDiskSizeUnit: string | undefined;
//     let maxDiskSizeUnit: string | undefined;
//     let minMemory: number | undefined;
//     let maxMemory: number | undefined;
//     let minMemoryUnit: string | undefined;
//     let maxMemoryUnit: string | undefined;

//     // Find the corresponding flavor object
//     const flavor = capabilities.flavors.find(
//       (flavor) => flavor.name === flavorName
//     );

//     if (flavor) {
//       minCore = flavor.core;
//       maxCore = flavor.core;

//       // Check if storage information exists in specifications
//       if (flavor.specifications.storage) {
//         minDiskSize = flavor.specifications.storage.value;
//         maxDiskSize = flavor.specifications.storage.value;
//         minDiskSizeUnit = flavor.specifications.storage.unit;
//         maxDiskSizeUnit = flavor.specifications.storage.unit;
//       }

//       // Check if memory information exists in specifications
//       if (flavor.specifications.memory) {
//         minMemory = flavor.specifications.memory.value;
//         maxMemory = flavor.specifications.memory.value;
//         minMemoryUnit = flavor.specifications.memory.unit;
//         maxMemoryUnit = flavor.specifications.memory.unit;
//       }
//     }

//     if (!planGroups[plan]) {
//       planGroups[plan] = {
//         plan,
//         availability: [],
//         minNodeNumber,
//         maxNodeNumber,
//         minCore,
//         maxCore,
//         minDiskSize,
//         maxDiskSize,
//         minDiskSizeUnit,
//         maxDiskSizeUnit,
//         minMemory,
//         maxMemory,
//         minMemoryUnit,
//         maxMemoryUnit,
//         backups: [],
//         networks: [],
//       };
//     } else {
//       if (minCore !== undefined && maxCore !== undefined) {
//         planGroups[plan].minCore = Math.min(planGroups[plan].minCore!, minCore);
//         planGroups[plan].maxCore = Math.max(planGroups[plan].maxCore!, maxCore);
//       }
//       if (minDiskSize !== undefined && maxDiskSize !== undefined) {
//         planGroups[plan].minDiskSize = Math.min(
//           planGroups[plan].minDiskSize!,
//           minDiskSize
//         );
//         planGroups[plan].maxDiskSize = Math.max(
//           planGroups[plan].maxDiskSize!,
//           maxDiskSize
//         );
//         planGroups[plan].minDiskSizeUnit = minDiskSizeUnit!;
//         planGroups[plan].maxDiskSizeUnit = maxDiskSizeUnit!;
//       }
//       if (minMemory !== undefined && maxMemory !== undefined) {
//         planGroups[plan].minMemory = Math.min(
//           planGroups[plan].minMemory!,
//           minMemory
//         );
//         planGroups[plan].maxMemory = Math.max(
//           planGroups[plan].maxMemory!,
//           maxMemory
//         );
//         planGroups[plan].minMemoryUnit = minMemoryUnit!;
//         planGroups[plan].maxMemoryUnit = maxMemoryUnit!;
//       }
//       planGroups[plan].minNodeNumber = Math.min(
//         planGroups[plan].minNodeNumber,
//         minNodeNumber
//       );
//       planGroups[plan].maxNodeNumber = Math.max(
//         planGroups[plan].maxNodeNumber,
//         maxNodeNumber
//       );
//     }

//     if (backups.available && backups.available === true) {
//       planGroups[plan].backups.push(item.backup);
//     }

//     if (network) {
//       planGroups[plan].networks.push(network);
//     }

//     planGroups[plan].availability.push(item);
//   });

//   // Ensure backups array contains only unique values
//   Object.values(planGroups).forEach((group) => {
//     group.backups = Array.from(new Set(group.backups));
//     group.networks = Array.from(new Set(group.networks));
//   });

//   // Order the plan groups based on the order defined in the plans array
//   const orderedPlanGroups: Array<DBSGroupAvailability> = capabilities.plans
//     .map((plan) => planGroups[plan.name])
//     .filter((group) => group !== undefined);

//   return orderedPlanGroups;
// }

export function extractRegionsFromAvailabilities(
  availabilities: DBSAvailability[]
): string[] {
  const regionsSet: Set<string> = new Set();
  availabilities.forEach((availability) => regionsSet.add(availability.region));
  return Array.from(regionsSet);
}

export function filterAvailabilitiesByRegion(
  availabilities: DBSAvailability[],
  region: string
): DBSAvailability[] {
  return availabilities.filter(
    (availability) => availability.region === region
  );
}

export function getNodeTypesFromAvailability(
  availabilities: DBSAvailability[],
  capabilities: DBSCapabilities,
  engineVersion: string
): DBSNodeType[] {
  const nodeTypes: DBSNodeType[] = [];

  availabilities
    .filter((a) => a.version === engineVersion)
    .forEach((availability) => {
      const flavorName = availability.flavor;
      const flavor = capabilities.flavors.find(
        (flavor) => flavor.name === flavorName
      );

      if (flavor) {
        const nodeType: DBSNodeType = {
          flavor: flavor,
          availability: availability,
        };
        nodeTypes.push(nodeType);
      }
    });
  return nodeTypes;
}

export function getClusterTotalStorage(
  nodeType: DBSNodeType,
  additionalStorage: number | null | undefined
) {
  const {
    availability: {
      specifications: { storage },
    },
  } = nodeType;

  if (storage) {
    const { minimum } = storage;

    const clusterStorage = humanizeSize(
      binarySize(minimum.value, minimum.unit.slice()[0], 1000),
      { binaryBaseValue: 1000 }
    );
    if (additionalStorage) {
      const totalStorage =
        Number(clusterStorage.number) + Number(additionalStorage);
      return `${humanizeSize(binarySize(totalStorage, "G", 1000), {
        fractionDigits: 2,
        binaryBaseValue: 1000,
      }).join("B")} (${clusterStorage.join("B")})`;
    }
    return clusterStorage.join("B");
  }
  return "";
}

export const getDBSPriceAmout = (price: number, toFixed: number = 3) => {
  return (price / 10 ** 8).toFixed(toFixed);
};
