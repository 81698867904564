import { Socket } from "socket.io-client";
import { useAppDispatch, useAppSelector } from ".";
import { selectKuberoSocket, setKuberoSocket } from "../store/publicSlice";
import { useEffect } from "react";
import { io } from "socket.io-client";

export const WEBAPP_TERMINAL_BASE_URL =
  process.env.REACT_APP_MODE === "develop" ? "http://localhost:4000" : "";

const useKuberoSocket = () => {
  const socket = useAppSelector(selectKuberoSocket) as Socket | null;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (socket === null) {
      const socketInstance = io(WEBAPP_TERMINAL_BASE_URL, {
        path: "/terminal-socket",
        withCredentials: true,
        transports: ["websocket", "polling"],
      });

      dispatch(setKuberoSocket(socketInstance));
    }
  }, [dispatch, socket]);

  const handleClearSocket = () => {
    dispatch(setKuberoSocket(null));
  };

  return { socket, handleClearSocket };
};

export default useKuberoSocket;
