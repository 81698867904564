import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import {
  getDBSUsersAsync,
  getDatabaseServiceAsync,
  selectDatabaseService,
  selectDatabaseServiceLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
} from "../../../store/database/serviceSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  DatabaseAuthorisedIPsUrl,
  DatabaseBackupsDuplicateUrl,
  DatabaseBackupsUrl,
  DatabaseServicesUrl,
  DatabaseUpgradeNodeUrl,
  DatabaseUpgradeNumberOfNodesUrl,
  DatabaseUpgradePlanUrl,
  DatabaseUpgradeStorageUrl,
  DatabaseUsersUrl,
} from "../../../utils/urls";
import DatabaseRenameModal from "./../services/DatabaseRenameModal";
import {
  deleteDBSAsync,
  getDBSAvailabilityAsync,
  getDBSCapabilitiesAsync,
  getDatabaseServicesAsync,
  handleShowDatabaseRenameModal,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  selectDBSCapabilities,
  selectDBSCapabilitiesLoading,
  selectDatabaseServicesActionLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import { DatabaseService } from "../../../types/database";
import { convertTimeStringToUTC, getGmtOffsetHours } from "../../../utils/date";
import QuestionModal from "../../modals/QuestionModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { NotExist } from "../../layouts/NotData";
import { ReactComponent as VerifyIcon } from "./../../../assets/icons/verify.svg";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/error-warning.svg";
import { ReactComponent as PenIcon } from "./../../../assets/icons/pencil-square.svg";
import { binarySize, humanizeSize } from "../../../utils/file";
// import { CopyableInput } from "../../general/CopyableKey";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import DBSCertificate from "./DBSCertificate";
import {
  Alert,
  Button,
  Card,
  cn,
  Flex,
  Loading,
  SecureCopy,
  Select,
  Tag,
  Typography,
} from "djuno-design";
import CustomLink from "../../general/CustomLink";
import { TextField } from "@mui/material";
import DBSNodesLights from "./DBSNodesLights";

const GeneralInformationTab = () => {
  const service = useAppSelector(selectDatabaseService);
  const loading = useAppSelector(selectDatabaseServiceLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const capabilities = useAppSelector(selectDBSCapabilities);
  const capabilitiesLoading = useAppSelector(selectDBSCapabilitiesLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteService, setDeleteService] = useState<DatabaseService | null>(
    null
  );
  const [endpointService, setEndpointService] = useState<string | undefined>();
  const [maintenanceTime, setMaintenanceTime] = useState(
    service?.maintenanceTime || ""
  );
  const [showHandleMainTenanceButton, setShowHandleMainTenanceButton] =
    useState<boolean>(false);
  const [backupTime, setBackupTime] = useState(service?.backupTime || "");
  const [showHandleBackupTimeButton, setShowHandleBackupTimeButton] =
    useState<boolean>(false);

  const selectedEndpoint = useMemo(() => {
    return service?.endpoints.find((e) => e.component === endpointService);
  }, [endpointService, service]);

  const flavorData = useMemo(() => {
    return capabilities?.flavors?.find((f) => f.name === service?.flavor);
  }, [service, capabilities]);

  const availabilityData = useMemo(() => {
    return availability?.find((a) => a.flavor === service?.flavor);
  }, [service, availability]);

  useEffect(() => {
    if (availability === undefined || availability.length === 0) {
      dispatch(getDBSAvailabilityAsync());
    }
  }, [availability, dispatch]);

  useEffect(() => {
    if (capabilities === undefined) {
      dispatch(getDBSCapabilitiesAsync());
    }
  }, [capabilities, dispatch]);

  const handleChangeMaintenanceTime = (event: any) => {
    setMaintenanceTime(event.target.value);
    setShowHandleMainTenanceButton(true);
  };
  const handleChangeBackupTime = (event: any) => {
    setBackupTime(event.target.value);
    setShowHandleBackupTimeButton(true);
  };

  const handleActionMaintenanceTime = (isCancel: boolean) => {
    if (service) {
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: {
            maintenanceTime: isCancel
              ? service?.maintenanceTime
              : maintenanceTime,
          },
        })
      ).then((action) => {
        if (action.type === "db-services/update/fulfilled") {
          dispatch(getDatabaseServicesAsync({}));
          dispatch(getDatabaseServiceAsync({ id: service.id }));
          setShowHandleMainTenanceButton(false);
          setMaintenanceTime(service.maintenanceTime);
        }
      });
    }
  };

  const handleActionBackupTime = (isCancel: boolean) => {
    if (service) {
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: {
            backupTime: isCancel ? service?.backupTime : backupTime,
          },
        })
      ).then((action) => {
        if (action.type === "db-services/update/fulfilled") {
          dispatch(getDatabaseServicesAsync({}));
          dispatch(getDatabaseServiceAsync({ id: service.id }));
          setShowHandleBackupTimeButton(false);
          setBackupTime(service.maintenanceTime);
        }
      });
    }
  };

  useEffect(() => {
    if (service) {
      if (service.endpoints.length > 0) {
        setEndpointService(service.endpoints[0].component);
      }
      if (service.engine !== "m3aggregator") {
        dispatch(getDBSUsersAsync({ id: service.id, engine: service.engine }));
      }
    }
  }, [dispatch, service]);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  return (
    <>
      {!loading && service && service.ipRestrictions.length === 0 && (
        <div className="">
          <Alert
            uiType="warning"
            className="!text-xs mb-3"
            closable
            showIcon
            message="Your cluster is not fully configured: you must authorize IP
              addresses in order to use it."
          />
        </div>
      )}

      {service && !usersLoading && !loading && users.length === 0 && (
        <div className="mt-1">
          <Alert
            uiType="warning"
            className="!text-xs mb-3"
            closable
            showIcon
            message="Your cluster is not fully configured: you must create user in
              order to use it."
          />
        </div>
      )}

      {!loading && service && service.status !== "READY" && (
        <div className="mt-1">
          <Alert
            uiType="warning"
            className="!text-xs mb-3"
            closable
            showIcon
            message="Your cluster is not fully configured."
          />
        </div>
      )}
      {service && !loading && (
        <>
          <div>
            <Card title="Cluster overview">
              <div className="mt-2 grid gap-4 md:grid-cols-2">
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm !mb-0">
                        Name
                      </Typography.Title>
                      <Flex items="center" className="w-full gap-1">
                        <Typography.Text className="!text-sm">
                          {service?.description}
                        </Typography.Text>
                        <Button
                          uiType="light"
                          uiSize="small"
                          className="!px-2"
                          onClick={() =>
                            dispatch(handleShowDatabaseRenameModal(service))
                          }
                          disabled={service.status !== "READY"}
                        >
                          Edit
                          <PenIcon className="w-4 h-4" />
                        </Button>
                      </Flex>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="text-sm">
                        Cluster ID
                      </Typography.Title>
                      <Typography.Text className="!text-sm">
                        {service?.id}
                      </Typography.Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm">
                        Status
                      </Typography.Title>
                      <Tag
                        className="text-sm  inline-block"
                        color={
                          service.status === "CREATING"
                            ? "warning"
                            : service.status === "READY"
                            ? "success"
                            : "processing"
                        }
                      >
                        {service.status}
                      </Tag>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm">
                        Service
                      </Typography.Title>
                      <Typography.Text className="!text-sm">
                        {service?.engine}
                      </Typography.Text>
                      <Typography.Text className="!text-sm">
                        {" "}
                        {service?.version}
                      </Typography.Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm">
                        Service plan
                      </Typography.Title>
                      <Typography.Text className="!text-sm">
                        {service?.plan}
                      </Typography.Text>
                      <Typography.Text className="text-sm flex items-center gap-1 mt-1">
                        <CustomLink
                          className={cn(
                            "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                            {
                              "!cursor-not-allowed": service.status !== "READY",
                            }
                          )}
                          to={
                            service.status !== "READY"
                              ? "#"
                              : DatabaseUpgradePlanUrl(service.id)
                          }
                        >
                          Upgrade your plan
                        </CustomLink>
                      </Typography.Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm">
                        Nodes
                      </Typography.Title>
                      <DBSNodesLights nodes={service.nodes} />
                      <Typography.Text className="!text-sm flex items-center gap-1 mt-1">
                        <Link
                          className={cn(
                            "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                            {
                              "!cursor-not-allowed": service.status !== "READY",
                            }
                          )}
                          to={
                            service.status !== "READY"
                              ? "#"
                              : DatabaseUpgradeNumberOfNodesUrl(service.id)
                          }
                        >
                          Upgrade number of nodes
                        </Link>
                      </Typography.Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm">
                        Node template
                      </Typography.Title>
                      {availabilityData && flavorData && (
                        <Typography.Text className="!text-sm">
                          {flavorData.core > 0
                            ? `${flavorData.core} CPU /`
                            : ""}
                          {flavorData.memory > 0
                            ? `${flavorData.memory} GB RAM /`
                            : ""}
                          {availabilityData.specifications.storage?.minimum
                            .value ===
                          availabilityData.specifications.storage?.maximum.value
                            ? `${
                                availabilityData.specifications.storage?.minimum
                                  ? humanizeSize(
                                      binarySize(
                                        availabilityData.specifications.storage
                                          ?.minimum.value,
                                        availabilityData.specifications.storage?.minimum.unit.slice()[0],
                                        1000
                                      ),
                                      {
                                        binaryBaseValue: 1000,
                                      }
                                    ).join("B")
                                  : ""
                              } storage`
                            : `  From ${
                                availabilityData.specifications.storage?.minimum
                                  ? humanizeSize(
                                      binarySize(
                                        availabilityData.specifications.storage
                                          ?.minimum.value,
                                        availabilityData.specifications.storage?.minimum.unit.slice()[0],
                                        1000
                                      ),
                                      {
                                        binaryBaseValue: 1000,
                                      }
                                    ).join("B")
                                  : ""
                              } ${
                                availabilityData.specifications.storage?.maximum
                                  ? humanizeSize(
                                      binarySize(
                                        availabilityData.specifications.storage
                                          ?.maximum.value,
                                        availabilityData.specifications.storage?.maximum.unit.slice()[0],
                                        1000
                                      ),
                                      {
                                        binaryBaseValue: 1000,
                                      }
                                    ).join("B")
                                  : ""
                              } storage`}
                        </Typography.Text>
                      )}
                      {flavorData && flavorData?.core > 0 && (
                        <Typography.Text className="!text-sm flex items-center gap-1 mt-1">
                          <Link
                            className={cn(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseUpgradeNodeUrl(service.id)
                            }
                          >
                            Upgrade your node template
                          </Link>
                        </Typography.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm">
                        Storage
                      </Typography.Title>
                      <Typography.Text className="!text-sm">
                        {service.storage.size.value} {service.storage.size.unit}{" "}
                      </Typography.Text>
                      <Typography.Text className="!text-sm">
                        First generation
                      </Typography.Text>
                      <Typography.Text className="!text-sm">
                        remote drive
                      </Typography.Text>

                      {flavorData && flavorData?.core > 0 && (
                        <Typography.Text className="!text-sm flex items-center gap-1 mt-1">
                          <CustomLink
                            className={cn(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseUpgradeStorageUrl(service.id)
                            }
                          >
                            Edit aditional storage
                          </CustomLink>
                        </Typography.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm">
                        Datacenter
                      </Typography.Title>
                      <Typography.Text className="!text-sm">
                        {service.nodes[0].region}
                      </Typography.Text>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {service.endpoints.length > 0 && (
            <div className="mt-5">
              <Card title="Login information">
                <div className="mt-2 grid gap-4 md:grid-cols-2">
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full ">
                        <Typography.Title level={6} className="!text-sm">
                          Service
                        </Typography.Title>
                        <Select
                          label=""
                          value={endpointService}
                          onChange={(v) => setEndpointService(v)}
                          options={
                            service?.endpoints.map((s) => ({
                              label: s.component,
                              value: s.component,
                            })) || []
                          }
                          className="w-60"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Typography.Title level={6} className="!text-sm">
                          URI
                        </Typography.Title>
                        <SecureCopy
                          type="copy"
                          text={selectedEndpoint ? selectedEndpoint.uri : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Typography.Title level={6} className="!text-sm">
                          Host
                        </Typography.Title>
                        <Typography.Text className="!text-sm">
                          {selectedEndpoint?.domain}
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Typography.Title level={6} className="!text-sm">
                          Protocol
                        </Typography.Title>
                        <Typography.Text className="!text-sm">
                          {
                            service?.endpoints.find(
                              (e: any) => e.component === endpointService
                            )?.scheme
                          }
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Typography.Title level={6} className="!text-sm">
                          SSL mode
                        </Typography.Title>
                        <Typography.Text className="!text-sm">
                          Mandatory
                        </Typography.Text>
                      </div>
                    </div>
                  </div>

                  {selectedEndpoint?.port !== null && (
                    <div className="p-2">
                      <div className="flex justify-between flex-col md:flex-row">
                        <div className="mb-4 md:mb-0 w-full">
                          <Typography.Title level={6} className="!text-sm">
                            Port
                          </Typography.Title>
                          <Typography.Text className="!text-sm">
                            {selectedEndpoint?.port}
                          </Typography.Text>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedEndpoint && (
                    <div className="p-2">
                      <div className="flex justify-between flex-col md:flex-row">
                        <div className="mb-4 md:mb-0 w-full">
                          <Typography.Title level={6} className="!text-sm">
                            Path
                          </Typography.Title>
                          <Typography.Text className="!text-sm">
                            {selectedEndpoint?.path
                              ? selectedEndpoint?.path
                              : "-"}
                          </Typography.Text>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Typography.Title level={6} className="!text-sm">
                          Users
                        </Typography.Title>
                        <Tag className="text-sm inline-block" color="success">
                          {users ? `${users.length} user` : "No user set"}
                        </Tag>
                        <Typography.Text className="!text-sm flex items-center gap-1">
                          <CustomLink
                            className={cn(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseUsersUrl(service.id)
                            }
                          >
                            Manage users
                          </CustomLink>
                        </Typography.Text>
                      </div>
                    </div>
                  </div>

                  <DBSCertificate
                    title="CA certificate"
                    service={service}
                    validEngines={["mysql", "cassandra", "postgresql", "kafka"]}
                  />
                </div>
              </Card>
            </div>
          )}

          {/* {service.engine === "mysql" && (
            <div className="mt-5">
              <Card title="Service Integration">
                <div className="mt-2 grid gap-4 md:grid-cols-2">
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Text
                          className="text-sm  inline-block"
                          type="warning-alert"
                        >
                          none
                        
                        </Text>

                        <Text className="text-sm flex items-center gap-1">
                          <Link
                            className="text-sm  cursor-pointer text-sky-500 hover:text-sky-600"
                            to={DatabaseServicesUrl}
                          >
                            Manage integration
                          </Link>
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )} */}

          <div className="mt-5">
            <Card title="Configuration">
              <div className="mt-2 grid gap-4 md:grid-cols-2">
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm mb-2">
                        Maintenance time (UTC)
                      </Typography.Title>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Flex items="center" className="gap-1">
                          <TextField
                            type="time"
                            defaultValue={service.maintenanceTime}
                            inputProps={{ step: 1, className: "!p-2" }}
                            onChange={handleChangeMaintenanceTime}
                            InputProps={{
                              className: "dark:bg-slate-400 ",
                            }}
                          />
                          {showHandleMainTenanceButton && (
                            <Flex items="center" className="ml-1 gap-0.5">
                              <Button
                                uiSize="small"
                                uiType="icon"
                                className="!px-1"
                                onClick={() =>
                                  handleActionMaintenanceTime(false)
                                }
                              >
                                <VerifyIcon className="w-5 h-5" />
                              </Button>
                              <Button
                                uiSize="small"
                                uiType="icon"
                                className="!px-1"
                                onClick={() =>
                                  handleActionMaintenanceTime(true)
                                }
                              >
                                <ErrorIcon className="w-5 h-5" />
                              </Button>
                            </Flex>
                          )}
                        </Flex>
                      </LocalizationProvider>
                      <Typography.Text className="!text-xs mt-1">
                        Local time (GMT {getGmtOffsetHours()}
                        ):
                        {service &&
                          convertTimeStringToUTC(
                            service?.maintenanceTime
                          ).toLocaleTimeString()}
                      </Typography.Text>
                    </div>
                  </div>
                </div>
                {service.capabilities.backups && (
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Typography.Title level={6} className="!text-sm mb-2">
                          Backup time (UTC)
                        </Typography.Title>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Flex items="center" className="gap-1">
                            <TextField
                              type="time"
                              defaultValue={service.backupTime}
                              inputProps={{ step: 1, className: "!p-2" }}
                              onChange={handleChangeBackupTime}
                              InputProps={{
                                className: "dark:bg-slate-400 ",
                              }}
                            />
                            {showHandleBackupTimeButton && (
                              <Flex items="center" className="ml-1 gap-0.5">
                                <Button
                                  uiSize="small"
                                  uiType="icon"
                                  className="!px-1"
                                  onClick={() => handleActionBackupTime(false)}
                                >
                                  <VerifyIcon className="w-5 h-5" />
                                </Button>
                                <Button
                                  uiSize="small"
                                  uiType="icon"
                                  className="!px-1"
                                  onClick={() => handleActionBackupTime(true)}
                                >
                                  <ErrorIcon className="w-5 h-5" />
                                </Button>
                              </Flex>
                            )}
                          </Flex>
                        </LocalizationProvider>
                        <Typography.Text className="!text-xs mt-1">
                          Local time (GMT {getGmtOffsetHours()}
                          ):
                          {service &&
                            convertTimeStringToUTC(
                              service?.backupTime
                            ).toLocaleTimeString()}
                        </Typography.Text>
                        <Typography.Text className="!text-sm flex items-center gap-1">
                          <CustomLink
                            className={cn(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseBackupsUrl(service.id)
                            }
                          >
                            Manage backups
                          </CustomLink>
                        </Typography.Text>
                        <Typography.Text className="text-sm flex items-center gap-1">
                          <CustomLink
                            className={cn(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseBackupsDuplicateUrl(service.id)
                            }
                          >
                            Duplicate(Fork)
                          </CustomLink>
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                )}
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Typography.Title level={6} className="!text-sm">
                        Network used
                      </Typography.Title>

                      <Typography.Text className="!text-sm flex items-center gap-1">
                        {service?.networkType} network
                      </Typography.Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  {service.engine !== "m3aggregator" && (
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Typography.Title level={6} className="!text-sm">
                          Authorised IP
                        </Typography.Title>
                        {service.ipRestrictions.length > 0 ? (
                          service.ipRestrictions.map((p, index) => (
                            <div key={index}>
                              <Tag
                                className="text-sm inline-block mb-2"
                                color="success"
                              >
                                {p.ip}
                              </Tag>
                            </div>
                          ))
                        ) : (
                          <Tag
                            className="text-sm inline-block mb-2"
                            color="warning"
                          >
                            None
                          </Tag>
                        )}

                        <Typography.Text className="!text-sm flex items-center gap-1">
                          <Link
                            className={cn(
                              "!text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseAuthorisedIPsUrl(service.id)
                            }
                          >
                            Manage IP addresses
                          </Link>
                        </Typography.Text>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </div>

          <div className="mt-5">
            <Typography.Text className="!text-sm">
              All product names, logos, and brands are property of their
              respective owners. All company, product and service names used in
              this website are for identification purposes only. Use of these
              names, logos, and brands does not imply endorsement.
            </Typography.Text>
          </div>
          <DatabaseRenameModal />
          <QuestionModal
            isOpen={deleteService !== null}
            onClose={() => setDeleteService(null)}
            onConfirm={() => {
              deleteService &&
                dispatch(
                  deleteDBSAsync({
                    clusterId: deleteService.id,
                    engine: deleteService.engine,
                  })
                ).then((action) => {
                  if (action.type === "db-services/delete/fulfilled") {
                    dispatch(getDatabaseServicesAsync({}));
                    setDeleteService(null);
                    navigate(DatabaseServicesUrl);
                  }
                });
            }}
            loading={actionLoading}
            confirmButtonType="danger"
          />
        </>
      )}

      {/* empty service */}
      {service === null && !loading && (
        <div className="h-full flex items-center justify-center px-4">
          <div className="flex flex-col items-center">
            <div className="text-center space-y-1">
              <NotExist
                url={() => navigate(DatabaseServicesUrl)}
                text="The cluster does not exist"
              />
            </div>
          </div>
        </div>
      )}

      {loading && (
        <Flex items="center" justify="center" className="w-full !min-h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}
    </>
  );
};

export default GeneralInformationTab;
