import { useEffect, useState } from "react";
import { DeleteModal } from "./../../../../../components/modals/QuestionModal";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./../../../../../hooks";
import { InstanceVolumeBackup } from "./../../../../../types/instance";
import { useSearch } from "./../../../../../providers/SearchProvider";
import { InstancesCreateVolumeFromBackupUrl } from "./../../../../../utils/urls";
import HighlighterText from "./../../../../general/HighlighterText";
import { Helmet } from "react-helmet";
import {
  getInstancesVolumeAsync,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
} from "./../../../../../store/instances/instancesVolumesSlice";
import {
  deleteInstancesVolumeBackupAsync,
  getInstanceVolumeBackupListAsync,
  handleShowVolumeBackupModal,
  restoreInstancesVolumeBackupAsync,
  selectInstancesVolumeBackupsList,
  selectInstancesVolumeBackupsListLoading,
} from "./../../../../../store/instances/instancesVolumeSlice";
import { formatTimestamp } from "./../../../../../utils/date";
import InstanceVolumeBackupModal from "./InstanceVolumeBackupModal";
import { ReactComponent as RefreshIcon } from "./../../../../../assets/icons/arrow-path.svg";
import { ReactComponent as PlusIcon } from "./../../../../../assets/icons/plus.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../../../assets/icons/more.svg";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Tag,
  Typography,
} from "djuno-design";

const InstanceVolumeBackupTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [deleteVolumeBackup, setDeleteVolumeBackup] = useState<string | null>(
    null
  );
  const volume = useAppSelector(selectInstancesSelectedVolume);
  const volumeLoading = useAppSelector(selectInstancesSelectedVolumeLoading);
  const volumeBackupsList = useAppSelector(selectInstancesVolumeBackupsList);
  const volumeBackupsListLoading = useAppSelector(
    selectInstancesVolumeBackupsListLoading
  );

  const [filteredVolumeBackups, setFilteredVolumeBackups] = useState<
    InstanceVolumeBackup[]
  >([]);

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (volume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, volume]);

  useEffect(() => {
    if (volume && volume.id) {
      dispatch(
        getInstanceVolumeBackupListAsync({
          volumeId: volume?.id,
          regionName: volume?.region,
        })
      );
    }
  }, [dispatch, volume]);

  useEffect(() => {
    const lookedUpApiKeys = volumeBackupsList.filter((key) =>
      key.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredVolumeBackups(lookedUpApiKeys);
  }, [dispatch, searchValue, volumeBackupsList]);

  return (
    <div className="">
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Volume backups</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="flex flex-col gap-3">
        <div className="flex transition-all duration-500">
          <div className="ml-auto flex items-center justify-end w-full">
            <Button
              uiType="primary"
              onClick={() =>
                volume && dispatch(handleShowVolumeBackupModal({ volume }))
              }
              className="group"
            >
              Create a volume backup
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <div className="mt-6">
          <SimpleTable
            loading={volumeBackupsListLoading || volumeLoading}
            containerClassName="min-h-[240px]"
          >
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH lable="Name" />
                <SimpleTable.TH lable="Region" />
                <SimpleTable.TH lable="Volume" />
                <SimpleTable.TH lable="Creation date" />
                <SimpleTable.TH lable="Size" />
                <SimpleTable.TH lable="Status" />
                <SimpleTable.TH lable="" />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredVolumeBackups.map((volumeBackup: any, index: any) => (
                <SimpleTable.Row key={index} withoutHoverStyle>
                  <SimpleTable.TD className="w-36">
                    <div className="flex flex-col gap-1">
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={volumeBackup.name}
                        className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                      />
                    </div>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-sm">
                      {volumeBackup.region}
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-sm whitespace-nowrap">
                      {volumeBackup.id}
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-sm whitespace-nowrap">
                      {
                        formatTimestamp(
                          volumeBackup.creationDate,
                          "MMM DD hh:mm:ss A"
                        ).datetime
                      }
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-sm whitespace-nowrap">
                      {volumeBackup.size} GiB
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Flex>
                      <Tag
                        className="text-sm whitespace-nowrap"
                        color={
                          volumeBackup.status === "ok"
                            ? "success"
                            : volumeBackup.status === "deleting"
                            ? "error"
                            : "processing"
                        }
                      >
                        {volumeBackup.status}
                      </Tag>
                    </Flex>
                  </SimpleTable.TD>
                  <SimpleTable.TD className="w-10">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        anchor="bottom end"
                        itemsClassName="!w-49"
                        menu={[
                          {
                            key: "restore",
                            label: (
                              <div className="flex items-center gap-1">
                                <RefreshIcon className="w-4" />
                                Restore a volume
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              volume &&
                                dispatch(
                                  restoreInstancesVolumeBackupAsync({
                                    volumeId: volume.id,
                                    regionName: volume?.region,
                                    volumeBackupId: volumeBackup?.id,
                                    data: { volumeId: volume?.id },
                                  })
                                ).then((action) => {
                                  if (
                                    action.type ===
                                    "instances/volume/backup/restore/fulfilled"
                                  ) {
                                    dispatch(
                                      getInstanceVolumeBackupListAsync({
                                        volumeId: volume?.id,
                                        regionName: volume?.region,
                                      })
                                    );
                                  }
                                });
                            },
                            disabled:
                              volumeBackup?.status === "restoring" ||
                              volumeBackup?.status === "creating" ||
                              volumeBackup?.status === "deleting",
                          },

                          {
                            key: "create",
                            label: (
                              <div className="flex items-center gap-1">
                                <PlusIcon className="w-4" />
                                Create a volume
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              volume &&
                                navigate(
                                  InstancesCreateVolumeFromBackupUrl(
                                    volume.id,
                                    volumeBackup?.id
                                  )
                                );
                            },
                            disabled:
                              volumeBackup?.status === "restoring" ||
                              volumeBackup?.status === "creating" ||
                              volumeBackup?.status === "deleting",
                          },

                          // {
                          //   type: "divider",
                          // },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteVolumeBackup(volumeBackup.id);
                            },
                            disabled:
                              volumeBackup?.status === "restoring" ||
                              volumeBackup?.status === "creating" ||
                              volumeBackup?.status === "deleting",
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              ))}
              {volumeBackupsList.length === 0 && (
                <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                  <SimpleTable.TD colSpan={7} className="!border-0">
                    <EmptyState text="You have not created any Volume Backup yet" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
      </div>
      <InstanceVolumeBackupModal />
      <DeleteModal
        title="Delete a Volume Backup"
        isOpen={deleteVolumeBackup !== null}
        onClose={() => setDeleteVolumeBackup(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={volumeBackupsListLoading}
        onConfirm={() => {
          deleteVolumeBackup &&
            volume &&
            dispatch(
              deleteInstancesVolumeBackupAsync({
                volumeId: volume.id,
                regionName: volume.region,
                volumeBackupId: deleteVolumeBackup,
              })
            ).then((action) => {
              if (action.type === "instances/volume/backup/delete/fulfilled") {
                dispatch(
                  getInstancesVolumeAsync({
                    id: volume?.id,
                  })
                );
                dispatch(
                  getInstanceVolumeBackupListAsync({
                    volumeId: volume?.id,
                    regionName: volume?.region,
                  })
                );
              }
              setDeleteVolumeBackup(null);
            });
        }}
        confirmString={
          volumeBackupsList.find((v) => v.id === deleteVolumeBackup)?.name
        }
      />
    </div>
  );
};

export default InstanceVolumeBackupTab;
