import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { Enum, IThunkRejectValue, RootState } from "../../types";
import { WebApp, WebAppsState } from "../../types/web-app";
import {
  deleteWebAppApi,
  getWebAppsApi,
  resumeWebAppApi,
  suspendWebAppApi,
} from "../../apis/webAppsAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

//   {
//     Id: 1,
//     LastDeployed: "2024-02-28T18:03:39.753584",
//     Name: "test-name",
//     Region: { title: "Oregon (US West)", value: "oregon" },
//     RunTime: "docker",
//     Status: 2,
//     Type: 1,
//     Plan: { id: 1, title: "Free", ram: "512 MB", price: "0", cpu: "0.1" },
//     DockerCommand: null,
//     PreDeployCommand: null,
//     ExternalImage: {
//       ImageHost: "docker.io",
//       ImageName: "nginx",
//       ImageRef: "latest",
//       ImageRepository: "library",
//       ImageURL: "docker.io/library/nginx:latest",
//       OwnerId: "usr-budirqf93em7sijmvtb0",
//       RegistryCredentialId: null,
//     },
//     URL: "https://mysql-ffln.onrender.com",
//     HealthCheckPath: null,
//   },
// ];
export const WebAppsRefreshStatus = [0, 1];

export const WebAppsStatusEnums: Enum[] = [
  {
    Text: "NOTSTARTED",
    Value: 0,
  },
  {
    Text: "INPROGRESS",
    Value: 1,
  },
  {
    Text: "RUNNING",
    Value: 2,
  },
  {
    Text: "ERROR_CREATION",
    Value: 3,
  },
  {
    Text: "Waiting",
    Value: 4,
  },
];

export const WebAppsUpdatingMessage =
  "We are updating your service. Please wait...";

const initialState: WebAppsState = {
  webApps: [],
  loading: false,
  actionLoading: false,
  webApp: null,

  statusTypes: WebAppsStatusEnums,
  statusTypesLoading: false,

  plans: [],
  plansLoading: false,
};

export const getWebAppsAsync = createAsyncThunk<
  { webApps: WebApp[] },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "web-apps",
  async (
    { withoutLoading },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getWebAppsAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getWebAppsApi();
      const webApps = response.data.Result;

      return fulfillWithValue({ webApps });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteWebAppAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("web-apps/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteWebAppApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const suspendWebAppAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("web-apps/suspend", async ({ id }, { rejectWithValue }) => {
  try {
    await suspendWebAppApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const resumeWebAppAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("web-apps/resume", async ({ id }, { rejectWithValue }) => {
  try {
    await resumeWebAppApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const webAppsSlice = createSlice({
  name: "webApps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWebAppsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getWebAppsAsync.fulfilled, (state, action) => {
        state.webApps = action.payload.webApps;
        state.loading = false;
      })
      .addCase(getWebAppsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWebAppAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWebAppAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteWebAppAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(suspendWebAppAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(suspendWebAppAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(suspendWebAppAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(resumeWebAppAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(resumeWebAppAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(resumeWebAppAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWebApps = (state: RootState) => state.webApps.webApps;

export const selectWebAppsLoading = (state: RootState) => state.webApps.loading;

export const selectWebAppsActionLoading = (state: RootState) =>
  state.webApps.actionLoading;

export const selectSelectedWebApp = (state: RootState) => state.webApps.webApp;

export const selectWebAppsStatusTypes = (state: RootState) =>
  state.webApps.statusTypes;
export const selectWebAppsStatusTypesLoading = (state: RootState) =>
  state.webApps.statusTypesLoading;

// export const {} = webAppsSlice.actions;
export default webAppsSlice.reducer;
