import { cn } from "djuno-design";
import { DatabaseServiceNode } from "../../../types/database";

const DBSNodesLights: React.FC<{ nodes: Array<DatabaseServiceNode> }> = ({
  nodes,
}) => {
  return (
    <div className="flex items-center gap-0.5">
      {nodes.map((node, j) => (
        <div
          key={j}
          className={cn("w-3 aspect-square rounded-full", {
            "bg-success": node.status === "READY",
            "bg-error": node.status === "DELETING",
            "bg-warning": node.status === "CREATING",
            "bg-secondary-200 dark:bg-secondary-500": node.status === "PENDING",
            // "bg-secondary-200 dark:bg-secondary-500": node.status !== "READY",
          })}
        />
      ))}
    </div>
  );
};

export default DBSNodesLights;
