import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getIpfsMetricsAsync,
  selectIpfsMetrics,
  selectIpfsMetricsLoading,
  selectIpfsStatus,
} from "../../../store/ipfs/ipfsPublicSlice";
import IpfsBanner from "./IpfsBanner";
import IpfsReportedUsageWidget from "./ReportedUsageWidget";
import { Flex, Loading, Select, Typography } from "djuno-design";
import { metricsTimesOptions } from "../../web-apps/single-page/WebAppMetricsTab";
import {
  KuberoMetricChart,
  kuberoMetricChartName,
  kuberoMetricChartYName,
} from "../../general/charts/KuberoMetricChart";
import { IpfsNotActiveTab } from "../../layouts/NotData";

const IpfsOverviewTab = () => {
  const ipfsStatus = useAppSelector(selectIpfsStatus);

  const ipfsMetrics = useAppSelector(selectIpfsMetrics);
  const ipfsMetricsLoading = useAppSelector(selectIpfsMetricsLoading);

  const dispatch = useAppDispatch();

  const [metricTime, setMetricTime] = useState<string>("0");

  useEffect(() => {
    dispatch(getIpfsMetricsAsync({ time: Number(metricTime) }));
  }, [dispatch, metricTime]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    interval = setInterval(() => {
      if (ipfsStatus)
        dispatch(
          getIpfsMetricsAsync({
            withoutLoading: true,
            time: Number(metricTime),
          })
        );
    }, 10000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, ipfsStatus, metricTime]);

  if (!ipfsStatus) {
    return <IpfsNotActiveTab />;
  }

  return (
    <div className="flex flex-col gap-8">
      <IpfsBanner />

      <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4">
        <IpfsReportedUsageWidget />
      </div>
      <div className="flex justify-end">
        <Select
          options={metricsTimesOptions}
          value={metricTime.toString()}
          onChange={(v) => setMetricTime(v || "0")}
          buttonClassName="!border-slate-200 dark:!border-secondary-700 !pr-7"
          optionsClassName=" !max-h-none"
        />
      </div>

      {ipfsMetricsLoading && (
        <Flex items="center" justify="center" className="w-full h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}

      {!ipfsMetricsLoading &&
        ipfsMetrics &&
        Object.keys(ipfsMetrics).length > 0 && (
          <Flex direction="col" className="w-full gap-14">
            {Object.keys(ipfsMetrics).map((key, index) => {
              return (
                <div className="h-[200px]" key={index}>
                  <Flex justify="center" className="w-full mt-5">
                    <Typography.Text className="!font-semibold !text-xs">
                      {kuberoMetricChartName(key)}
                    </Typography.Text>
                  </Flex>
                  <KuberoMetricChart
                    yName={kuberoMetricChartYName(key)}
                    data={ipfsMetrics[key as keyof typeof ipfsMetrics]}
                  />
                </div>
              );
            })}
          </Flex>
        )}
    </div>
  );
};

export default IpfsOverviewTab;
