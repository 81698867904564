import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as RefreshIcon } from "./../../assets/icons/arrow-path.svg";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../../components/modals/QuestionModal";
import HighlighterText from "../../components/general/HighlighterText";
import { useSearch } from "../../providers/SearchProvider";
import { Helmet } from "react-helmet";
import { WebApp } from "../../types/web-app";
import {
  deleteWebAppAsync,
  getWebAppsAsync,
  selectWebApps,
  selectWebAppsActionLoading,
  selectWebAppsLoading,
  WebAppsRefreshStatus,
} from "../../store/web-app/webAppsSlice";
import { timeAgo } from "../../utils/date";
import { WebAppCreateUrl, WebAppUrl } from "../../utils/urls";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  Loading,
  PresetColorNames,
  SimpleTable,
  Tag,
  Typography,
} from "djuno-design";
import useServiceType from "../../hooks/useServiceType";

const WebAppsPage = () => {
  const webApps = useAppSelector(selectWebApps);
  const webAppsLoading = useAppSelector(selectWebAppsLoading);
  const webAppsActionLoading = useAppSelector(selectWebAppsActionLoading);

  const { serviceType } = useServiceType(
    process.env.REACT_APP_WEB_APPS_SERVICE_TYPE
  );

  const [filteredWebApps, setFilteredWebApps] = useState<WebApp[]>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteWebApp, setDeleteWebApp] = useState<WebApp | null>(null);
  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getWebAppsAsync({}));
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = webApps?.filter((webApp) =>
      webApp.ServiceName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredWebApps(lookedUpFiles);
  }, [dispatch, searchValue, webApps]);

  //refresh the list
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime = 8000;

    const shouldSetInterval = webApps.some((webApp) =>
      WebAppsRefreshStatus.includes(webApp.ServiceStatus)
    );

    if (shouldSetInterval) {
      interval = setInterval(() => {
        dispatch(getWebAppsAsync({ withoutLoading: true }));
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, webApps]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta name="description" content="" />
      </Helmet>

      <div className="p-6">
        <Flex items="center" justify="between">
          <Typography.Title level={5}>
            {serviceType?.ServiceName}
          </Typography.Title>
          <Flex items="center" className="gap-2">
            <Button
              uiSize="medium"
              uiType="simple"
              onClick={() => dispatch(getWebAppsAsync({}))}
              className="group"
              tooltip={{ content: "Refresh" }}
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
            <Button
              uiType="primary"
              onClick={() => navigate(WebAppCreateUrl)}
              className="group"
            >
              Create Web App
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </Flex>
        </Flex>

        <div className="mt-5 w-full">
          <SimpleTable
            loading={webAppsLoading}
            containerClassName="min-h-[240px]"
          >
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH>Service Name</SimpleTable.TH>
                {/* <SimpleTable.TH>Type</SimpleTable.TH> */}
                <SimpleTable.TH>Runtime</SimpleTable.TH>
                {/* <SimpleTable.TH>Region</SimpleTable.TH> */}
                <SimpleTable.TH>Last Deployed</SimpleTable.TH>
                <SimpleTable.TH>Status</SimpleTable.TH>
                <SimpleTable.TH />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredWebApps.map((webApp, i) => (
                <SimpleTable.Row
                  key={i}
                  onClick={() => {
                    navigate(WebAppUrl(webApp.Id.toString()));
                  }}
                >
                  <SimpleTable.TD>
                    <Flex
                      items="center"
                      justify="between"
                      className="flex items-center flex-row justify-between space-x-3"
                    >
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={webApp.ServiceName}
                        className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                      />
                    </Flex>
                  </SimpleTable.TD>

                  {/* <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {webApp.ServiceTypeId}{" "}
                    </Typography.Text>
                  </SimpleTable.TD> */}

                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {webApp.Runtime}{" "}
                    </Typography.Text>
                  </SimpleTable.TD>

                  {/* <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {webApp.Region}
                    </Typography.Text>
                  </SimpleTable.TD> */}

                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {timeAgo(webApp.LastDeployed)}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD>
                    <WebAppStatusTag
                      ServiceStatus={webApp.ServiceStatus}
                      IsSuspended={webApp.IsSuspended}
                    />
                  </SimpleTable.TD>

                  <SimpleTable.TD className="w-32 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-end gap-1 px-4">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  Get details
                                </div>
                              ),
                              onClick: () => {
                                navigate(WebAppUrl(webApp.Id.toString()));
                              },
                              disabled: webAppsLoading,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteWebApp(webApp);
                              },
                              disabled: webAppsLoading,
                            },
                          ]}
                        >
                          <Button
                            uiType="icon"
                            uiSize="small"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              ))}

              {webApps.length === 0 && (
                <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                  <SimpleTable.TD colSpan={7} className="!border-0">
                    <EmptyState text="You have not created any web app yet" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>

        <DeleteModal
          title="Delete Web App"
          isOpen={deleteWebApp !== null}
          onClose={() => setDeleteWebApp(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deleteWebApp &&
              dispatch(deleteWebAppAsync({ id: deleteWebApp.Id })).then(
                (action) => {
                  if (action.type === "web-apps/delete/fulfilled") {
                    dispatch(getWebAppsAsync({}));
                  } else {
                  }
                  setDeleteWebApp(null);
                }
              );
          }}
          loading={webAppsActionLoading}
          confirmString={deleteWebApp?.ServiceName}
        />
      </div>
    </>
  );
};

export const WebAppStatusTag: React.FC<{
  ServiceStatus?: number;
  IsSuspended?: boolean;
}> = ({ ServiceStatus, IsSuspended }) => {
  const tagData:
    | { tagColor?: PresetColorNames; tagText: React.ReactNode }
    | undefined = useMemo(() => {
    if (IsSuspended) {
      return {
        tagColor: "warning",
        tagText: "Suspended",
      };
    }
    if (ServiceStatus === 0) {
      return {
        tagColor: undefined,
        tagText: "Not Started",
      };
    } else if (ServiceStatus === 1) {
      return {
        tagColor: "processing",
        tagText: (
          <Flex items="center" className="gap-1">
            <Loading uiSize={9} borderSize={0.9} />
            <Typography.Text size="xs" uiType="transparent">
              {ServiceStatus === 1 ? "Inprogress" : "Waiting"}
            </Typography.Text>
          </Flex>
        ),
      };
    } else if (ServiceStatus === 2) {
      return {
        tagColor: "success",
        tagText: "Running",
      };
    } else if (ServiceStatus === 3) {
      return {
        tagColor: "error",
        tagText: "Error Creation",
      };
    } else if (ServiceStatus === 4) {
      return {
        tagColor: "warning",
        tagText: "Waiting",
      };
    } else {
      return undefined;
    }
  }, [IsSuspended, ServiceStatus]);

  if (!tagData) return null;
  return (
    <Tag className="!text-xs" color={tagData.tagColor}>
      {tagData.tagText}
    </Tag>
  );
};
export default WebAppsPage;
