import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as DockerColorIcon } from "./../../../assets/icons/socials/docker-color.svg";
import {
  getWebAppPreviewsAsync,
  selectWebAppOverview,
  selectWebAppOverviewLoading,
  selectWebAppPreviews,
  selectWebAppPreviewsLoading,
} from "../../../store/web-app/webAppSlice";
import { timeAgo } from "../../../utils/date";
import { Card, EmptyState, Flex, Loading, Typography } from "djuno-design";

const WebAppPreviewTab = () => {
  const webAppOverview = useAppSelector(selectWebAppOverview);
  const webAppOverviewLoading = useAppSelector(selectWebAppOverviewLoading);

  const previews = useAppSelector(selectWebAppPreviews);
  const previewsLoading = useAppSelector(selectWebAppPreviewsLoading);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   if (webApp) {
  //     dispatch(getWebAppPreviewsAsync({ webAppId: webApp.Id.toString() }));
  //   }
  // }, [dispatch, webApp]);

  return (
    <Card
      title="Service Previews"
      description={
        <div className="col-span-1 text-sm font-normal font-sans mt-2">
          <Typography.Text size="sm">
            Inspect and review functional and visual changes for the code in
            your pull requests before merging them. Use Render’s API to create
            previews of your service.{" "}
            <Typography.Link className="!text-sm">
              Read the docs
            </Typography.Link>
          </Typography.Text>
        </div>
      }
    >
      <ul className="mt-2 border dark:border-dark-2 rounded-lg bg-white dark:bg-dark-3 w-full">
        {previewsLoading && (
          <Flex
            justify="center"
            items="center"
            className="min-h-[200px] w-full"
          >
            <Loading borderSize={2} />
          </Flex>
        )}
        {!previewsLoading && previews.length === 0 && (
          <EmptyState
            className="!py-5"
            text="Previews created via the API will be listed here."
          />
        )}
        {!previewsLoading &&
          previews.length > 0 &&
          previews.map((preview, index) => (
            <li
              key={index}
              className="p-4 border-b last:border-b-0 dark:border-dark-2"
            >
              <Flex items="center" justify="between">
                <Flex items="center" className="flex-1 gap-4">
                  <span>
                    {preview.Type === "image" && (
                      <DockerColorIcon className="w-4 aspect-square" />
                    )}
                  </span>
                  <Flex direction="col" className="gap-2">
                    <Flex items="center" className="gap-3">
                      <Typography.Text size="sm">
                        {preview.CommitId}
                      </Typography.Text>
                    </Flex>

                    <Flex items="center" className="gap-3">
                      <Typography.Text size="sm" className="font-light">
                        {webAppOverview?.Production.Spec.Name}
                        {" Preview "}
                        {preview.CommitId.substring(0, 7)}
                        {" created "}
                        {timeAgo(preview.CreatedAt)}
                        {" by "}
                        {preview.By}
                      </Typography.Text>
                    </Flex>

                    <Typography.Link className="!text-xs">
                      {preview.Link}
                    </Typography.Link>
                  </Flex>
                </Flex>
                <div className="flex items-center"></div>
              </Flex>
            </li>
          ))}
      </ul>
    </Card>
  );
};

export default WebAppPreviewTab;
