import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
// import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getDBSCurrentQuerriesAsync,
  selectDBSCurrentQueries,
  selectDBSCurrentQueriesLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { DBSCurrentQuery } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Switcher,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";

const DBSCurrentQueriesTab = () => {
  const service = useAppSelector(selectDatabaseService);

  const currentQueries = useAppSelector(selectDBSCurrentQueries);
  const currentQueriesLoading = useAppSelector(selectDBSCurrentQueriesLoading);
  const [filteredQueries, setFilteredQueries] = useState<
    Array<DBSCurrentQuery>
  >([]);

  const [showIdlConnections, setShowIdlConnections] = useState(true);
  const [showActiveConnections, setShowActiveConnections] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().currentQueries;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSCurrentQuerriesAsync({ id: service.id, engine: service.engine })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (service && autoRefresh) {
      interval = setInterval(() => {
        dispatch(
          getDBSCurrentQuerriesAsync({
            id: service.id,
            engine: service.engine,
            withoutLoading: true,
          })
        );
      }, 10000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, service, autoRefresh]);

  useEffect(() => {
    const lookedUp = currentQueries.filter((q) =>
      q.query?.toLowerCase().includes(searchValue.toLowerCase())
    );

    if (showIdlConnections) {
      lookedUp.filter(
        (q) =>
          q.state === "IDLE" ||
          q.state === "IDLE_IN_TRANSACTION" ||
          q.state === "IDLE_IN_TRANSACTION_ABORTED"
      );
    }

    if (showActiveConnections) {
      lookedUp.filter((q) => q.state === "ACTIVE");
    }
    setFilteredQueries(lookedUp);
  }, [
    currentQueries,
    dispatch,
    searchValue,
    showActiveConnections,
    showIdlConnections,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database - queries in progress
        </title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center gap-7">
            <div className="flex flex-col gap-1">
              <Typography.Text className="!text-xs !font-medium">
                Show idle connections
              </Typography.Text>
              <Switcher
                value={showIdlConnections}
                onChange={setShowIdlConnections}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Typography.Text className="!text-xs !font-medium">
                Show active connections
              </Typography.Text>
              <Switcher
                value={showActiveConnections}
                onChange={setShowActiveConnections}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Typography.Text className="!text-xs !font-medium">
                Authomatically refresh page
              </Typography.Text>
              <Switcher value={autoRefresh} onChange={setAutoRefresh} />
            </div>
          </div>
          <div className="mt-5 w-full">
            <SimpleTable
              loading={currentQueriesLoading}
              containerClassName="min-h-[240px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Query" />
                  <SimpleTable.TH lable="PID" />
                  <SimpleTable.TH lable="Duration" />
                  <SimpleTable.TH lable="Database" />
                  <SimpleTable.TH lable="Client address" />
                  <SimpleTable.TH lable="Application name" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>

              <SimpleTable.Body>
                {filteredQueries.map((query, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={query.query}
                          className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                        />
                      </div>
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {query.pid}
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {query.queryDuration}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {query.databaseName}
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {query.clientHostname}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {query.applicationName}
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="w-20 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-end gap-1">
                        <div className="w-8 flex justify-center items-center">
                          <Dropdown
                            anchor="bottom end"
                            itemsClassName="!w-48"
                            menu={
                              [
                                // {
                                //   key: "1",
                                //   label: (
                                //     <div className="flex items-center gap-1">
                                //       <RefreshIcon className="w-4" />
                                //       Reset the password
                                //     </div>
                                //   ),
                                //   onClick: (_, close) => {
                                //     close();
                                //     setDBSUserId(user.id);
                                //   },
                                //   disabled:
                                //     loading ||
                                //     isAnyUserDeleting ||
                                //     user.status === "PENDING",
                                // },
                                // {
                                //   type: "divider",
                                // },
                                // {
                                //   key: "end",
                                //   label: (
                                //     <div className="flex items-center gap-1">
                                //       <ArchiveIcon className="w-4" />
                                //       Delete
                                //     </div>
                                //   ),
                                //   danger: true,
                                //   onClick: (_, close) => {
                                //     close();
                                //     setDeleteUser(user.id);
                                //   },
                                //   disabled:
                                //     loading ||
                                //     isAnyUserDeleting ||
                                //     user.status === "PENDING",
                                // },
                              ]
                            }
                          >
                            <Button
                              uiSize="small"
                              uiType="icon"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        </div>
                        {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}

                {currentQueries.length === 0 && (
                  <SimpleTable.Row
                    withoutHoverStyle={true}
                    className="h-[200px]"
                  >
                    <SimpleTable.TD colSpan={10} className="!border-0">
                      <EmptyState text="No queries have been run" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          {/* <QuestionModal
            title="Reset password"
            description="Are you sure you want to reset the password for this user?"
            isOpen={DBSUserId !== null}
            onClose={() => setDBSUserId(null)}
            onConfirm={() => {
              DBSUserId &&
                service &&
                dispatch(
                  resetDBSUsersAsync({
                    id: service.id,
                    engine: service.engine,
                    userId: DBSUserId,
                    data: users,
                  })
                ).then((action) => {
                  if (action.type === "service/users/reset/fulfilled") {
                    dispatch(getDatabaseServicesAsync());
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                  } else {
                  }
                  setDBSUserId(null);
                });
            }}
            loading={actionLoading}
            confirmButtonType="danger"
          />
          <QuestionModal
            isOpen={deleteUser !== null}
            onClose={() => setDeleteUser(null)}
            onConfirm={() => {
              deleteUser &&
                service &&
                dispatch(
                  deleteDBSUserAsync({
                    id: service.id,
                    engine: service.engine,
                    userId: deleteUser,
                  })
                ).then((action) => {
                  if (action.type === "service/user/delete/fulfilled") {
                    dispatch(getDatabaseServicesAsync());
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                    dispatch(
                      getDBSUsersAsync({
                        id: service.id,
                        engine: service.engine,
                      })
                    );
                  } else {
                  }
                  setDeleteUser(null);
                });
            }}
            loading={actionLoading}
            confirmButtonType="danger"
          /> */}
        </>
      )}
    </>
  );
};

export default DBSCurrentQueriesTab;
