import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getWebAppMetricsAsync,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppsMetrics,
  selectWebAppsMetricsLoading,
} from "../../../store/web-app/webAppSlice";
import { Alert, Flex, Loading, Select, Typography } from "djuno-design";
import { useEffect, useState } from "react";
import WebAppsBanner from "./WebAppsBanner";
import { WebAppsMetricsWithRule } from "../../../types/web-app";
import {
  KuberoMetricChart,
  kuberoMetricChartName,
  kuberoMetricChartYName,
} from "../../general/charts/KuberoMetricChart";
import { WebAppsRefreshStatus } from "../../../store/web-app/webAppsSlice";

export const metricsTimesOptions = [
  {
    label: "2 Hours",
    value: "0",
  },
  {
    label: "24 Hours",
    value: "1",
  },
  {
    label: "7 Days",
    value: "2",
  },
];

const WebAppMetricsTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  // return (
  //   <div className="flex flex-col gap-10">
  //     <Card
  //       title="Usage"
  //       description="The historical usage for this service (up to 31 days)."
  //     >
  //       <div className="flex flex-col gap-5">
  //         <Typography.Text>chart section</Typography.Text>
  //         <Alert>
  //           <Typography.Text size="sm" className="text-center">
  //             Total usage: <span className="font-semibold">1.63 hours</span>{" "}
  //             (month-to-date)
  //           </Typography.Text>
  //         </Alert>
  //         <Alert>
  //           <Typography.Text size="sm" className="text-center">
  //             Average usage:{" "}
  //             <span className="font-semibold">0.09 hours/day</span>
  //           </Typography.Text>
  //         </Alert>
  //         <Alert uiType="info">
  //           <Typography.Text size="sm">
  //             You have used 0% of your monthly quota across all of your free
  //             instances.{" "}
  //             <Link
  //               to={`/settings/billing#free-usage`}
  //               className="text-sm text-primary-300 hover:text-primary-400"
  //             >
  //               View usage
  //             </Link>
  //           </Typography.Text>
  //         </Alert>
  //       </div>
  //     </Card>
  //     <BandWidth />
  //   </div>
  // );
  const webAppsMetrics = useAppSelector(selectWebAppsMetrics);
  const webAppsMetricsLoading = useAppSelector(selectWebAppsMetricsLoading);

  const dispatch = useAppDispatch();

  const [metricTime, setMetricTime] = useState<string>("0");

  useEffect(() => {
    if (webApp) {
      dispatch(
        getWebAppMetricsAsync({
          webAppId: webApp.Id.toString(),
          time: Number(metricTime),
          withoutLoading: WebAppsRefreshStatus.includes(webApp.ServiceStatus),
        })
      );
    }
  }, [dispatch, webApp, metricTime]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    interval = setInterval(() => {
      if (webApp && !WebAppsRefreshStatus.includes(webApp.ServiceStatus)) {
        dispatch(
          getWebAppMetricsAsync({
            webAppId: webApp?.Id.toString(),
            withoutLoading: true,
            time: Number(metricTime),
          })
        );
      }
    }, 10000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, webApp, metricTime]);

  const webAppsMetricsWithRule = webAppsMetrics as WebAppsMetricsWithRule;

  return (
    <div className="flex flex-col gap-8">
      <WebAppsBanner />

      {/* <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-5">
        <WebAppsReportedUsageWidget />
       
      </div> */}
      <div className="max-lg:order-2 max-lg:grow lg:mx-0 mx-2"></div>
      <div>
        {webAppsMetricsWithRule &&
          webAppsMetricsWithRule.Rule?.map((r) => {
            const displayName =
              r.Name.includes("not ready") && r.Alerting === false
                ? r.Name.replace("not ready", "is ready")
                : r.Name;

            const summary = r.Alert?.Annotations?.Summary || "";
            const modifiedSummary = summary.replace(
              /environment-.*?-production\/?/g, // Regex to match environment-****-production
              ""
            );

            return (
              <Alert
                uiType={
                  r.Alerting === true
                    ? "warning"
                    : displayName === "ready"
                    ? "success"
                    : "success"
                }
                className="!text-[10px] mb-1 !w-full"
                showIcon
                message={
                  r.Alerting === true ? (
                    <div className="flex flex-col">
                      <Typography.Text className="!text-sm">
                        {displayName}
                      </Typography.Text>
                      <Typography.Text className="!text-xs">
                        {/* {r.Alert.Annotations.Summary} */}
                        {modifiedSummary}
                      </Typography.Text>
                    </div>
                  ) : (
                    displayName
                  )
                }
              />
            );
          })}
      </div>
      <div className="flex justify-end">
        <Select
          options={metricsTimesOptions}
          value={metricTime.toString()}
          onChange={(v) => setMetricTime(v || "0")}
          buttonClassName="!border-slate-200 dark:!border-secondary-700 !pr-7"
          optionsClassName=" !max-h-none"
        />
      </div>

      {webAppsMetricsLoading && (
        <Flex items="center" justify="center" className="w-full h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}

      {!webAppsMetricsLoading &&
        webAppsMetrics &&
        Object.keys(webAppsMetrics).length > 0 && (
          <Flex direction="col" className="w-full gap-14">
            {Object.keys(webAppsMetrics).map((key) => {
              const data = webAppsMetrics[key as keyof typeof webAppsMetrics];
              if (key === "Rule") return null;
              return (
                <div className="h-[200px]" key={key}>
                  <Flex justify="center" className="w-full mt-5">
                    <Typography.Text className="!font-semibold !text-xs">
                      {kuberoMetricChartName(key)}
                    </Typography.Text>
                  </Flex>
                  <KuberoMetricChart
                    data={data}
                    yName={kuberoMetricChartYName(key)}
                  />
                </div>
              );
            })}
          </Flex>
        )}
    </div>
  );
};

// const BandWidth = () => {
//   const webApp = useAppSelector(selectWebApp);
//   const webAppLoading = useAppSelector(selectWebAppLoading);

//   return (
//     <Card title="Bandwidth">
//       <Typography.Text size="sm" className="mt-3">
//         Service <span className="font-semibold">{webApp?.ServiceName}</span> has
//         used zero bandwidth in the last 24 hours.
//       </Typography.Text>
//     </Card>
//   );
// };
export default WebAppMetricsTab;
