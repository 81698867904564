import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../hooks";
import { Instance } from "./../../types/instance";
import {
  deleteInstanceAsync,
  getInstancesAsync,
  InstanceRefreshStatus,
  selectInstances,
  selectInstancesActionLoading,
  selectInstancesLoading,
} from "./../../store/instances/instancesSlice";
import { useNavigate } from "react-router-dom";
import { useSearch } from "./../../providers/SearchProvider";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../assets/icons/bars-3-bottom-left.svg";
// import { ReactComponent as EditIcon } from "./../../assets/icons/pencil-square.svg";
import { ReactComponent as RefreshIcon } from "./../../assets/icons/arrow-path.svg";
import {
  InstanceOverviewUrl,
  InstancesCreateUrl,
  InstancesUrl,
} from "./../../utils/urls";
import { DeleteModal } from "./../modals/QuestionModal";
import {
  getInstancesVolumesAsync,
  selectInstancesVolumes,
  selectInstancesVolumesLoading,
} from "./../../store/instances/instancesVolumesSlice";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Skeleton,
  Tag,
  Typography,
} from "djuno-design";
import HighlighterText from "./../general/HighlighterText";
import { useTour } from "./../../providers/TourProvider";

const InstancesTab = () => {
  const [deleteInstance, setDeleteInstance] = useState<Instance>();

  const [filteredInstances, setFilteredInstances] = useState<Instance[]>([]);

  const instances = useAppSelector(selectInstances);
  const instancesLoading = useAppSelector(selectInstancesLoading);
  const instancesActionLoading = useAppSelector(selectInstancesActionLoading);

  const volumes = useAppSelector(selectInstancesVolumes);
  const volumesLoading = useAppSelector(selectInstancesVolumesLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { run } = useTour("instances");

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getInstancesAsync({ withoutLoading: false }));
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = instances?.filter((instance) =>
      instance.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredInstances(lookedUpFiles);
  }, [dispatch, searchValue, instances]);

  useEffect(() => {
    if (volumes.length === 0) {
      dispatch(getInstancesVolumesAsync({}));
    }
  }, [dispatch, volumes.length]);

  //refresh the list
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime =
      Number(process.env.REACT_APP_NODES_REFRESH_INTERVAL) || 20000;

    const shouldSetInterval = instances.some((instance) =>
      InstanceRefreshStatus.includes(instance?.status)
    );

    if (shouldSetInterval) {
      interval = setInterval(() => {
        dispatch(getInstancesAsync({ withoutLoading: true }));
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, instances]);

  const handleDeleteInstance = async () => {
    if (deleteInstance) {
      dispatch(deleteInstanceAsync({ id: deleteInstance.id })).then(
        (action) => {
          if (action.type === "instances/delete/fulfilled") {
            dispatch(getInstancesAsync({}));
            navigate(InstancesUrl);
            setDeleteInstance(undefined);
          }
        }
      );
    }
  };

  return (
    <>
      <div className="flex items-center justify-between instances-header">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          {/* <Text className="font-medium text-lg">Instances</Text> */}
        </div>
        <div className="flex items-center gap-2">
          <Button
            onClick={() => {
              run();
            }}
          >
            Start Tour
          </Button>

          <Button
            uiType="light"
            onClick={() => dispatch(getInstancesAsync({}))}
            className="refresh-button group"
            tooltip={{ content: "Refresh" }}
          >
            <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>

          <Button
            uiType="primary"
            onClick={() => navigate(InstancesCreateUrl)}
            className="create-instance-button group"
          >
            Create an instance
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <div className="mt-5 w-full instance-table">
        <SimpleTable
          loading={instancesLoading}
          containerClassName="min-h-[240px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name/ID" />
              <SimpleTable.TH lable="Location" />
              <SimpleTable.TH lable="Template" />
              <SimpleTable.TH lable="Image" />
              <SimpleTable.TH lable="Public IPs" />
              <SimpleTable.TH lable="Private IPs" />
              <SimpleTable.TH lable="Volumes" />
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredInstances.map((instance, i) => (
              <SimpleTable.Row
                key={i}
                onClick={() => navigate(InstanceOverviewUrl(instance.id))}
              >
                <SimpleTable.TD>
                  <div className="flex flex-col gap-1">
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={instance.name}
                      className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                    />
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={instance.id}
                      className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate !text-xs"
                    />
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {instance.region}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {instance.planCode?.split(".")[0]}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {instance?.image?.name}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {
                      instance.ipAddresses.filter(
                        (ip) => ip.version === 4 && ip.type === "public"
                      )[0]?.ip
                    }
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {
                      instance.ipAddresses.filter(
                        (ip) => ip.version === 4 && ip.type === "private"
                      )[0]?.ip
                    }
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {volumesLoading && (
                      <Skeleton style={{ width: 85, height: 24 }} />
                    )}
                    {volumes && !volumesLoading
                      ? volumes
                          .filter(
                            (volume) => volume?.attachedTo[0] === instance.id
                          )
                          ?.map((v, j) => (
                            <Tag className="mr-1" key={j}>
                              {v.name}
                            </Tag>
                          ))
                      : " "}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Tag
                    className="!text-xs"
                    color={instance.status === "ACTIVE" ? "success" : "warning"}
                  >
                    {instance.status}
                  </Tag>
                </SimpleTable.TD>

                <SimpleTable.TD className="w-20 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        anchor="bottom end"
                        itemsClassName="!p-0"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <Flex
                                items="center"
                                className="gap-1 whitespace-nowrap"
                              >
                                <DetailsIcon className="w-4" />
                                General Information
                              </Flex>
                            ),
                            onClick: () =>
                              navigate(InstanceOverviewUrl(instance.id)),
                            disabled: instancesLoading,
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteInstance(instance);
                            },
                            disabled: instancesLoading,
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                    <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {instances.length === 0 && (
              <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                <SimpleTable.TD colSpan={9} className="!border-0">
                  <EmptyState text="You have not created any instance yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <DeleteModal
        isOpen={!!deleteInstance}
        title="Delete instance"
        onClose={() => setDeleteInstance(undefined)}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={instancesLoading || instancesActionLoading}
        onConfirm={handleDeleteInstance}
        confirmString={deleteInstance?.name}
      />
    </>
  );
};

export default InstancesTab;
