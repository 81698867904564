import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { NotificationsState, Notification } from "../../types/notifications";
import { getNotificationsApi } from "../../apis/notificationsAPI";

const initialState: NotificationsState = {
  notifications: [],
  loading: false,
};

export const getNotificationsAsync = createAsyncThunk<
  { notifications: Notification[] },
  undefined,
  IThunkRejectValue
>("notifications", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getNotificationsApi();
    const notifications = response.data.Result;

    return fulfillWithValue({ notifications });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const addNotification = (notification: Notification) => {
  return {
    type: "notifications/addNotification",
    payload: notification,
  };
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationsAsync.fulfilled, (state, action) => {
        const { notifications } = action.payload;
        state.notifications = notifications;
        state.loading = false;
      })
      .addCase(getNotificationsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectNotifications = (state: RootState) =>
  state.notifications.notifications;
export const selectNotificationsLoading = (state: RootState) =>
  state.notifications.loading;

// export const {  } = notificationsSlice.actions;
export default notificationsSlice.reducer;
