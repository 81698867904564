import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectS3Logs,
  getS3LogsAsync,
  selectLoading,
} from "../../../store/s3/logsSlice";
import { S3NotActiveTab } from "../../layouts/NotData";
import { timeAgo } from "../../../utils/date";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";
import {
  cn,
  EmptyState,
  Flex,
  JsonViewer,
  Loading,
  Typography,
} from "djuno-design";

const S3LogsTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const logs = useAppSelector(selectS3Logs);
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // if (logs.length === 0) {
    dispatch(getS3LogsAsync());
    // }
  }, [dispatch]);

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500">
        <Typography.Title
          level={5}
          className="whitespace-nowrap font-medium mt-1.5 !mb-0"
        >
          Activitys
        </Typography.Title>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2"></div>
      </div>
      <div className="mt-5 w-full bg-white dark:bg-dark-3 border dark:border-dark-2 rounded-lg p-6 text-slate-800 dark:text-slate-200">
        {logs.length > 0 && !loading && (
          <ol className="relative border-s border-gray-200 dark:border-gray-700">
            {logs.map((log, i) => (
              <LogItem
                key={i}
                value={log.data}
                action={log.action}
                entity={log.entity}
                type={log.status === "SUCCESS" ? "success" : "warning"}
                time={`${log.created_at} (${timeAgo(log.created_at)})`}
              />
            ))}
          </ol>
        )}
        {loading && (
          <Flex
            items="center"
            justify="center"
            className="w-full !min-h-[2000px]"
          >
            <Loading borderSize={2} />
          </Flex>
        )}
        {!loading && logs.length === 0 && <EmptyState />}
      </div>
    </>
  );
};

const LogItem = ({
  type,
  value,
  time,
  action,
  entity,
}: {
  type?: "info" | "warning" | "success";
  value: any;
  time?: string;
  action?: string;
  entity?: string;
}) => {
  return (
    <li className="mb-10 ms-6 last:mb-0 relative">
      <span
        className={cn(
          "absolute flex items-center justify-center w-2 h-2  rounded-full -left-7 top-1 ring-4  ",
          {
            "bg-primary-300 ring-primary-50 dark:ring-primary-500/10 dark:bg-primary-400":
              type === undefined || type === "info",
            "bg-orange-400 ring-orange-50 dark:ring-orange-500/10 dark:bg-orange-400":
              type === "warning",
            "bg-green-500 ring-green-50 dark:ring-green-500/10 dark:bg-green-500":
              type === "success",
          }
        )}
      />
      <div className="flex flex-col gap-1">
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-1">
            <Typography.Title level={6} className="!text-sm !mb-0">
              Action:
            </Typography.Title>
            <Typography.Text className="!text-sm">{action}</Typography.Text>
          </div>
          <Typography.Text
            uiType="secondary"
            className="block !text-xs font-normal leading-none"
          >
            {time}
          </Typography.Text>
        </div>
        <div className="flex items-center gap-1">
          <Typography.Title level={6} className="!text-sm !mb-0">
            Entity:
          </Typography.Title>
          <Typography.Text className="!text-sm">{entity}</Typography.Text>
        </div>
        {value !== "" && (
          <div className="flex items-start gap-1">
            <Typography.Title level={6} className="!text-sm !mb-0">
              Data:
            </Typography.Title>
            <JsonViewer value={JSON.parse(value)} collapsed={0} />
          </div>
        )}
      </div>
    </li>
  );
};
export default S3LogsTab;
