import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";

import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
// import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";

import { useNavigate } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getDatabaseServicesAsync,
  selectDatabaseServicesActionLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import {
  getDatabaseServiceAsync,
  handleShowIPsEditorModal,
  selectDatabaseService,
  selectDatabaseServiceLoading,
} from "../../../store/database/serviceSlice";
import IpsCreateModal from "./IpsCreateModal";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { IpRestriction } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";

const AuthorisedIpsTab = () => {
  const [deleteIPAddress, setDeleteIPAddress] = useState<IpRestriction | null>(
    null
  );
  const [filteredIpRestriction, setFilteredIpRestriction] = useState<
    IpRestriction[]
  >([]);
  const service = useAppSelector(selectDatabaseService);
  const loading = useAppSelector(selectDatabaseServiceLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().allowedIps;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        const lookedUpFiles = service?.ipRestrictions?.filter((ip) =>
          ip.ip.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredIpRestriction(lookedUpFiles);
      }
    }
  }, [dispatch, navigate, searchValue, service]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Typography.Title level={5} className="!mb-0">
                IPs authorised to access the database
              </Typography.Title>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service) {
                    dispatch(getDatabaseServicesAsync({}));
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                  }
                }}
                disabled={!service}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                uiType="primary"
                onClick={() => dispatch(handleShowIPsEditorModal({}))}
                className="group"
              >
                Add an IP address or IP block (CIDR)
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>

          <div className="mt-5 w-full">
            <SimpleTable loading={loading} containerClassName="min-h-[240px]">
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="IP/mask" />
                  <SimpleTable.TH lable="Description" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {service &&
                  filteredIpRestriction.map((ip, i) => (
                    <SimpleTable.Row key={i}>
                      <SimpleTable.TD>
                        <div className="flex items-center flex-row justify-between space-x-3">
                          <HighlighterText
                            searchValue={searchValue}
                            textToHighlight={ip.ip}
                            className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                          />
                        </div>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {ip.description}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD className="w-20 gap-1">
                        <div className="h-full w-full inline-flex items-center justify-end gap-1">
                          <div className="w-8 flex justify-center items-center">
                            <Dropdown
                              itemsClassName="!w-48"
                              anchor="bottom end"
                              menu={[
                                {
                                  key: "1",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <EditIcon className="w-4"></EditIcon>
                                      Edit IP address
                                    </div>
                                  ),
                                  onClick: (_, close) => {
                                    close();
                                    dispatch(handleShowIPsEditorModal({ ip }));
                                  },
                                  disabled: loading,
                                },

                                {
                                  type: "divider",
                                },
                                {
                                  key: "end",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <ArchiveIcon className="w-4" />
                                      Delete IP address
                                    </div>
                                  ),
                                  danger: true,
                                  onClick: (_, close) => {
                                    close();
                                    setDeleteIPAddress(ip);
                                  },
                                  disabled: loading,
                                },
                              ]}
                            >
                              <Button
                                uiType="icon"
                                uiSize="small"
                                className="!px-2"
                              >
                                <MoreIcon className="w-4 h-4" />
                              </Button>
                            </Dropdown>
                          </div>
                          {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                        </div>
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  ))}

                {service?.ipRestrictions.length === 0 && (
                  <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                    <SimpleTable.TD colSpan={3} className="!border-0">
                      <EmptyState text="You have not created any IP yet" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <IpsCreateModal />

          <DeleteModal
            title="Delete an IP Address"
            isOpen={deleteIPAddress !== null}
            onClose={() => setDeleteIPAddress(null)}
            description=""
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteIPAddress &&
                service &&
                dispatch(
                  updateDBSAsync({
                    id: service.id,
                    engine: service?.engine,
                    data: {
                      ipRestrictions: service.ipRestrictions
                        .map((restriction: any) => ({
                          ip: restriction.ip,
                          description: restriction.description,
                        }))
                        .filter((ip) => ip.ip !== deleteIPAddress.ip),
                    },
                  })
                ).then((action) => {
                  if (action.type === "db-services/update/fulfilled") {
                    dispatch(getDatabaseServicesAsync({}));
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                  } else {
                  }
                  setDeleteIPAddress(null);
                });
            }}
            loading={actionLoading}
            confirmString={deleteIPAddress?.ip}
          />
        </>
      )}
    </>
  );
};

export default AuthorisedIpsTab;
