import { useState } from "react";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close.svg";
import {
  Typography,
  Button,
  getLocalStorage,
  setLocalStorage,
} from "djuno-design";
const WalletApiBanner = () => {
  const docsUrl = process.env.REACT_APP_DOCS_URL || "";
  const localStorageName = "wallet-api-banner";
  const showWeb3Banner = getLocalStorage<boolean>(localStorageName, true);
  const [show, setShow] = useState<boolean>(showWeb3Banner);
  const closeBanner = () => {
    setLocalStorage<boolean>(localStorageName, false);
    setShow(false);
  };

  if (!show) return null;
  return (
    <div className="relative w-full bg-gray-50 dark:bg-dark-3 border border-slate-300 dark:border-dark-2 p-6 rounded-lg flex flex-col">
      <CloseIcon
        onClick={closeBanner}
        className="absolute right-3 top-3 text-slate-500 dark:text-slate-300 w-5 hover:scale-110 duration-300 cursor-pointer"
      />
      <div className="flex items-center w-full justify-between">
        <Typography.Text className="text-sm">
          Get started with Wallet API
        </Typography.Text>
      </div>
      <div className="flex items-center gap-2 mt-5 flex-wrap">
        <a
          href={docsUrl + "/en/articles/10108727-wallet-api"}
          target="_blank"
          rel="noreferrer"
        >
          <Button>Get Started</Button>
        </a>
        <a
          href={
            docsUrl + "/en/articles/10108745-wallet-api-reference#h_0716ed8505"
          }
          target="_blank"
          rel="noreferrer"
        >
          <Button>Wallets</Button>
        </a>
        <a
          href={
            docsUrl + "/en/articles/10108745-wallet-api-reference#h_2720c8280e"
          }
          target="_blank"
          rel="noreferrer"
        >
          <Button>Transfer</Button>
        </a>
        <a
          href={
            docsUrl + "/en/articles/10108745-wallet-api-reference#h_ba7fd83afd"
          }
          target="_blank"
          rel="noreferrer"
        >
          <Button>Sign</Button>
        </a>
      </div>
    </div>
  );
};

export default WalletApiBanner;
