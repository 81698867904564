import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { ReactComponent as ArrowsPointingInIcon } from "./../../../assets/icons/arrows-pointing-in.svg";
import { ReactComponent as ArrowsPointingOutIcon } from "./../../../assets/icons/arrows-pointing-out.svg";
import {
  ITerminalInitOnlyOptions,
  ITerminalOptions,
  Terminal,
} from "@xterm/xterm";
import { FitAddon } from "@xterm/addon-fit";
import "@xterm/xterm/css/xterm.css";
import { Button, cn, Flex, sleep, Typography } from "djuno-design";
import axios from "axios";
import {
  getWebAppOverviewAsync,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppOverview,
  selectWebAppOverviewLoading,
} from "../../../store/web-app/webAppSlice";
import useKuberoSocket, {
  WEBAPP_TERMINAL_BASE_URL,
} from "../../../hooks/useKuberoSocket";
import { useNavigate } from "react-router-dom";
import { WebAppUrl } from "../../../utils/urls";

const EXIT_COMMANDS = ["\u0001exit\r\n", "\u0001\r\n\u001b[?2004l\rexit\r\n"];

const termOptions: ITerminalOptions & ITerminalInitOnlyOptions = {
  cols: 110,
  rows: 40,
  cursorBlink: true,
  allowTransparency: true,
};

const WebAppShellTab = () => {
  //0-> not connected , 1 -> connecting , 2 -> connected , 3 -> disconnected
  const [connectionStatus, setConnectionStatus] = useState<number>(0);
  const [maximize, setMaximize] = useState<boolean>(false);

  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const webAppOverview = useAppSelector(selectWebAppOverview);
  const webAppOverviewLoading = useAppSelector(selectWebAppOverviewLoading);

  const { socket } = useKuberoSocket();

  const terminalRef = useRef<HTMLDivElement>(null);
  const term = useRef<Terminal | null>();

  const navigate = useNavigate();

  const isActiveWebApp = useMemo(() => {
    if (webApp) {
      if (webApp.IsSuspended) return false;
      return ![0, 1, 4].includes(webApp.ServiceStatus);
    }
    return false;
  }, [webApp]);

  const pipelineName = useMemo(
    () => webAppOverview && `${webAppOverview?.Production?.Spec?.Pipeline}`,
    [webAppOverview]
  );

  const appName = useMemo(
    () => webAppOverview && `${webAppOverview?.Production?.Spec?.Name}`,
    [webAppOverview]
  );

  const terminalActive = useMemo(() => {
    return (
      !webAppOverviewLoading &&
      (connectionStatus === 1 || connectionStatus === 2)
    );
  }, [connectionStatus, webAppOverviewLoading]);

  const createTerminal = () => {
    if (!terminalRef.current || !socket || !term.current) return;

    // Dispose of the previous terminal instance if it exists
    if (term.current) {
      term.current.dispose();
      term.current = null;
    }

    // Initialize a new terminal instance
    term.current = new Terminal(termOptions);
    const fitAddon = new FitAddon();
    term.current.loadAddon(fitAddon);

    term.current.open(terminalRef.current);
    fitAddon.fit();
    term.current.focus();

    socket.on("terminal", (data: string) => {
      if (EXIT_COMMANDS.includes(data)) {
        if (webApp) navigate(WebAppUrl(webApp.Id.toString()));
      } else {
        term.current?.write(data);
      }
    });

    socket.on("consoleresponse", (data: string) => {
      if (EXIT_COMMANDS.includes(data)) {
        if (webApp) navigate(WebAppUrl(webApp.Id.toString()));
      } else {
        term.current?.write(data);
      }
    });

    term.current.onData((data) => {
      socket.emit("terminal", { pipeline: pipelineName, app: appName, data });
    });
  };

  const socketJoin = () => {
    if (!socket) return;
    socket.emit("join", { pipeline: pipelineName, app: appName });
  };

  const executeInContainer = async () => {
    try {
      await axios.post(
        `${WEBAPP_TERMINAL_BASE_URL}/terminal-bridge/api/console/exec`,
        {
          pipeline: pipelineName,
          app: appName,
        }
      );
      setConnectionStatus(2);
    } catch (error) {
      setConnectionStatus(3);
      console.error("Error executing in container:", error);
    }
  };

  const handleConnect = async () => {
    let interval: NodeJS.Timeout | undefined;
    setConnectionStatus(1);

    const connect = () => {
      if (!term.current) {
        term.current = new Terminal(termOptions);
      }
      createTerminal();
      socketJoin();
      executeInContainer();
    };

    const checker = async () => {
      if (terminalRef.current) {
        connect();
        if (interval) clearTimeout(interval);
      } else {
        interval = setTimeout(checker, 500);
      }
    };
    checker();
  };

  const socketLeave = useCallback(() => {
    if (!socket || !term.current) return;
    console.log("socketLeave", term.current);
    socket.emit("terminal", {
      pipeline: pipelineName,
      app: appName,
      data: "exit\r\nexit\r\nexit\r\nexit\r\nexit\r\n",
    });
    // wait a bit for the exit to be processed
    setTimeout(() => {
      socket.emit("leave", { pipeline: pipelineName, app: appName });
      // term.current?.write("exit\r\n");
      // term.current?.dispose();

      // Dispose of the terminal instance and clear the DOM
      term.current?.dispose();
      term.current = null;
      if (terminalRef.current) {
        terminalRef.current.innerHTML = ""; // Clear terminal div content
      }
      setConnectionStatus(0);
    }, 1000);
  }, [appName, pipelineName, socket]);

  useEffect(() => {
    return () => {
      if (term.current) {
        term.current.dispose();
        term.current = null;
      }
      if (socket) {
        socketLeave();
      }
    };
  }, [socket, socketLeave]);

  return (
    <Flex direction="col" className="gap-3 w-full">
      <Flex justify="end" className="w-full">
        {!maximize && (
          <Flex items="center" className="max-lg:order-4 ml-2 gap-2">
            <TerminalStatus status={connectionStatus} />
            <Button onClick={() => setMaximize(true)}>
              <ArrowsPointingOutIcon className="w-4 aspect-square" />
              Maximize
            </Button>
          </Flex>
        )}
      </Flex>
      <div
        className={cn(
          "flex flex-col w-full bg-gray-100 dark:bg-dark-3 min-h-[300px]",
          {
            "rounded-tl-xl rounded-tr-xl rounded-xl pb-1": !maximize,
            "z-[2147483004] fixed top-0 left-0 h-screen overflow-y-auto":
              maximize,
          }
        )}
      >
        {maximize && (
          <header className="py-6 px-10 sticky top-0 z-10  bg-gray-100 dark:bg-dark-3">
            <div className="flex items-center justify-end gap-2">
              <TerminalStatus status={connectionStatus} />
              <Button onClick={() => setMaximize(false)}>
                <ArrowsPointingInIcon className="w-4 aspect-square" />
                Minimize
              </Button>
            </div>
          </header>
        )}

        <Flex
          direction="col"
          className={cn("flex-1 inset-0 items-center justify-center")}
        >
          {!terminalActive && (
            <div className="h-full flex items-center justify-center px-4">
              <Button
                disabled={pipelineName === null || !isActiveWebApp}
                onClick={handleConnect}
              >
                Connect
              </Button>
            </div>
          )}
          {terminalActive && (
            <div
              id="terminal"
              ref={terminalRef}
              className={cn(
                "transition-all duration-300 text-slate-100 bg-dark-1 w-full overflow-x-auto",
                {
                  "h-full": !maximize,
                }
              )}
            />
          )}
        </Flex>
      </div>
    </Flex>
  );
};

const TerminalStatus: React.FC<{ status: number }> = ({ status }) => {
  return (
    <Button
      uiType="icon"
      className="!px-2 flex items-center justify-center gap-1 w-28"
    >
      <div
        className={cn("w-3 h-3 rounded-full", {
          "bg-gray-300 dark:bg-slate-500": status === 0,
          "bg-primary-400 animate-pulse": status === 1,
          "bg-green-700": status === 2,
          "bg-red-500": status === 3,
        })}
      />

      <Typography.Text size="xs">
        {status === 0 && "Not connected"}
        {status === 1 && "Connecting"}
        {status === 2 && "Connected"}
        {status === 3 && "Disconnected"}
      </Typography.Text>
    </Button>
  );
};
export default WebAppShellTab;
