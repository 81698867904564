import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";

import {
  getWebAppAsync,
  getWebAppOverviewAsync,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppOverviewLoading,
  selectWebAppUpdateLoading,
  updateWebAppAsync,
} from "../../../store/web-app/webAppSlice";
import { Control, Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppInstanceTypeSchema } from "../../../utils/validations";
import { RadioGroup } from "@headlessui/react";
import {
  AnimatedFormError,
  Button,
  Card,
  cn,
  Flex,
  Loading,
  Typography,
} from "djuno-design";
import {
  selectPlans,
  selectPlansLoading,
} from "../../../store/billing/billingSlice";
import QuestionModal from "../../modals/QuestionModal";
import toast from "react-hot-toast";
import { ToastClasses } from "../../modals/alerts";

const WebAppPlanTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const updateLoading = useAppSelector(selectWebAppUpdateLoading);
  const overviewLoading = useAppSelector(selectWebAppOverviewLoading);

  const dispatch = useAppDispatch();

  const [nextPlan, setNextPlan] = useState<number | null>();

  const {
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(WebAppInstanceTypeSchema),
  });

  const planId = watch("PlanId");

  useEffect(() => {
    if (webApp && webApp.planId) {
      setValue("PlanId", webApp.planId);
    }
  }, [dispatch, setValue, webApp]);

  const handleSubmitForm = () => {
    if (
      webApp &&
      (!updateLoading || !webAppLoading || !overviewLoading) &&
      nextPlan
    )
      dispatch(
        updateWebAppAsync({
          webAppId: webApp.Id.toString(),
          data: {
            PlanId: nextPlan,
            registryId: webApp.RegistryId,
          },
        })
      ).then((action) => {
        if (action.type === "web-app/update/fulfilled") {
          setNextPlan(null);
          toast.success("The Plan successfully changed", {
            className: ToastClasses,
          });
          dispatch(
            getWebAppAsync({
              webAppId: webApp.Id.toString(),
              withoutLoading: true,
            })
          );
          dispatch(getWebAppOverviewAsync({ webAppId: webApp.Id.toString() }));
        }
      });
  };

  const isActiveWebApp = useMemo(() => {
    if (!webApp) return false;
    return ![0, 1].includes(webApp.ServiceStatus);
  }, [webApp]);

  const isPlanChanged = useMemo(() => {
    return webApp?.planId !== planId;
  }, [webApp?.planId, planId]);

  return (
    <div className="flex flex-col gap-6">
      {/* <form onSubmit={handleSubmit(handleSubmitForm)}> */}
      <Card title="Pick an Instance Type">
        {webAppLoading && (
          <Flex items="center" justify="center" className="min-h-[200px]">
            <Loading borderSize={2} />
          </Flex>
        )}
        {/* suspended={webApp?.IsSuspended} */}
        <WebAppPlansGroup control={control}>
          <AnimatedFormError error={errors.PlanId?.message} />
          <div className="flex items-center justify-start gap-2 mt-4 mr-0  ">
            <Button
              uiType="primary"
              disabled={!isPlanChanged || !isActiveWebApp}
              type="submit"
              loading={updateLoading || webAppLoading}
              onClick={() => setNextPlan(planId)}
            >
              Save Changes
            </Button>
          </div>
        </WebAppPlansGroup>
      </Card>
      {/* </form> */}
      <QuestionModal
        isOpen={!!nextPlan}
        title="Change instance type"
        description="Are you sure you want to change the web app instance type?"
        onConfirm={handleSubmitForm}
        onClose={() => setNextPlan(null)}
        confirmButtonText="Yes, Change it"
        loading={updateLoading || webAppLoading}
      />
    </div>
  );
};

export const WebAppPlansGroup: React.FC<
  PropsWithChildren<{
    control: Control<any>;
    suspended?: boolean;
  }>
> = ({ control, children, suspended }) => {
  const webAppPlans = useAppSelector(selectPlans);
  const webAppPlansLoading = useAppSelector(selectPlansLoading);

  if (webAppPlansLoading)
    return (
      <Flex items="center" justify="center" className="min-h-[200px]">
        <Loading borderSize={2} />
      </Flex>
    );
  return (
    <Controller
      name="PlanId"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup
          value={value || null}
          onChange={onChange}
          disabled={suspended}
        >
          <div className="mt-6">
            <div className="block md:grid grid-cols-3 gap-10 mb-8 md:mb-6">
              <div className="mb-2 pr-0 md:pr-6">
                <Typography.Text size="sm" className="font-medium">
                  For hobby projects
                </Typography.Text>
              </div>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 col-span-2 pr-8">
                <div className="col-span-1 lg:col-span-1">
                  {webAppPlans.length > 0 && (
                    <RadioGroup.Option value={webAppPlans[0].Id}>
                      {({ checked, disabled }) => (
                        <WebAppInstanceCard
                          title={webAppPlans[0].Name}
                          description={webAppPlans[0].PlanDescription || "{}"}
                          price={webAppPlans[0].Price || 0}
                          selected={checked}
                          disabled={disabled}
                        />
                      )}
                    </RadioGroup.Option>
                  )}
                </div>
                <div className="flex space-x-1.5"></div>
              </div>
            </div>
            <div className="block md:grid grid-cols-3 gap-10">
              <Flex direction="col" className="mb-6 pr-8 md:pr-6">
                <Typography.Text size="sm" className="font-medium mb-1">
                  For professional use
                </Typography.Text>
                <Typography.Text size="sm" className=" mb-4" uiType="secondary">
                  For more power, we recommend using one of our paid instance
                  types. All paid instances support:
                </Typography.Text>
              </Flex>
              <div className="col-span-2 pr-8">
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                  {webAppPlans.slice(1).map((instance, i) => (
                    <RadioGroup.Option key={i} value={instance.Id}>
                      {({ checked, disabled }) => (
                        <WebAppInstanceCard
                          title={instance.Name}
                          description={instance.PlanDescription || "{}"}
                          price={instance.Price || 0}
                          selected={checked}
                          disabled={disabled}
                        />
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
                {children}
              </div>
            </div>
          </div>
        </RadioGroup>
      )}
    />
  );
};

export const WebAppInstanceCard: React.FC<{
  title: string;
  description: string;
  price: number;
  selected?: boolean;
  disabled?: boolean;
}> = ({ title, description, price, selected, disabled }) => {
  const { CPU, DISK, RAM } = JSON.parse(description);
  return (
    <div
      className={cn(
        "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer",
        {
          "border-primary-400 dark:border-primary-400": selected,
          "!cursor-not-allowed": disabled,
        }
      )}
    >
      <div className="flex flex-col w-full gap-2">
        <div className="flex items-center justify-between">
          <Typography.Text size="sm" className="text-base font-medium">
            {title}
          </Typography.Text>
          <Typography.Text size="sm" uiType="secondary">
            {RAM} (RAM)
          </Typography.Text>
        </div>
        <div className="flex items-center justify-between">
          <Typography.Text
            size="sm"
            className="font-semibold flex items-center gap-1"
          >
            ${price}{" "}
            <Typography.Text
              size="sm"
              className="font-normal"
              uiType="secondary"
            >
              / month
            </Typography.Text>
          </Typography.Text>
          <Typography.Text size="sm" uiType="secondary">
            {CPU} CPU
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
export default WebAppPlanTab;
