import {
  Controller,
  UseFormReturn,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { WebAppCreateFormData, WebAppSecretFile } from "../../../types/web-app";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
// import { ReactComponent as ArrowDownIcon } from "./../../../assets/icons/arrow-down.svg";
import { useCallback, useEffect, useRef } from "react";
import { WebAppSecretFileSchema } from "../../../utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import ImportFromEnvFileModal from "../../settings/env-variables/ImportFromEnvFileModal";
import { handleShowImportFromEnvFileModal } from "../../../store/settings/env-variables/envVariablesSlice";
import { WebAppPlansGroup } from "../single-page/WebAppPlanTab";
import {
  Flex,
  Input,
  Modal,
  Textarea,
  Button,
  Card,
  Typography,
  AnimatedFormError,
} from "djuno-design";
import {
  getWebAppCheckNameAsync,
  handleChangeWebAppIsValidName,
  selectWebAppIsValidName,
  selectWebAppNameValidationLoading,
} from "../../../store/web-app/webAppCreateSlice";

const CreateFormStep: React.FC<{
  form: UseFormReturn<WebAppCreateFormData>;
}> = ({ form }) => {
  const nameValidationLoading = useAppSelector(
    selectWebAppNameValidationLoading
  );
  const isValidName = useAppSelector(selectWebAppIsValidName);

  const {
    register,
    formState: { errors },
    control,
  } = form;

  const {
    fields: ENVsFields,
    append: ENVappend,
    remove: ENVremove,
  } = useFieldArray({
    control,
    name: "EvironmentVariables",
  });

  // const handleGenerateEnvVal = (index: number) => {
  //   setValue(`EvironmentVariables.${index}.Value`, uuid(20));
  // };

  // const {
  //   fields: secretFileFields,
  //   append: secretFileAppend,
  //   remove: secretFileRemove,
  // } = useFieldArray({
  //   control,
  //   name: "SecretFiles",
  // });

  // const [advanced, setAdvanced] = useState<boolean>(false);
  // const [secretFileModal, setSecretFileModal] = useState<boolean>(false);
  // const hasDisk = useAppSelector(selectWebAppHasDisk);

  const dispatch = useAppDispatch();

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleChangeWebAppName = useCallback(
    (value: string) => {
      dispatch(handleChangeWebAppIsValidName(null));
      timeout.current && clearTimeout(timeout.current);
      if (value && value !== "") {
        timeout.current = setTimeout(
          () =>
            dispatch(getWebAppCheckNameAsync({ name: value })).then(
              (action) => {
                if (action.type === "web-apps/check-name/fulfilled") {
                  if (
                    (action?.payload as { isValidName: boolean }).isValidName
                  ) {
                    // setValue("AppName", value);
                  } else {
                  }
                } else {
                }
              }
            ),
          1000
        );
      } else {
      }
    },
    [dispatch]
  );

  return (
    <div className="flex my-5 mb-20 w-full">
      <div className="flex flex-col space-y-12 md:space-y-16 w-full">
        <div className="grid grid-cols-3 gap-x-10">
          <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
            <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
              Name
            </Typography.Text>
            <Typography.Text
              uiType="secondary"
              className="!text-xs md:!text-sm "
            >
              A unique name for your web service.
            </Typography.Text>
          </Flex>
          <div className="col-span-3 md:col-span-2">
            <Controller
              control={control}
              name="AppName"
              render={({ field: { value, onChange } }) => (
                <Input
                  onChange={(e) => {
                    onChange(e);
                    handleChangeWebAppName(e.target.value);
                  }}
                  value={value}
                  placeholder="example-app-name"
                  error={
                    isValidName === null || isValidName === true
                      ? errors.AppName?.message
                      : "Service name is already taken, try a unique name for your service"
                  }
                  loading={nameValidationLoading}
                />
              )}
            />
          </div>
        </div>
        {/* <div className="grid grid-cols-3 gap-x-10">
          <div className="col-span-1 text-sm">
            <Typography.Text className="!font-medium">Region</Typography.Text>
            <Typography.Text uiType="secondary" size="sm">
              The <Typography.Link className="!text-sm">region</Typography.Link>{" "}
              where your web service runs. Services must be in the same region
              to communicate privately and you currently have services running
              in <span className="font-medium">Oregon</span>
            </Typography.Text>
          </div>
          <div className="col-span-2">
            <Controller
              control={control}
              name="Origin"
              render={({ field: { onChange, value } }) => (
                <Select
                  options={[
                    ...webAppRegions.map((r) => ({
                      label: <div>{r.title}</div>,
                      value: r.value,
                    })),
                  ]}
                  value={value?.toString()}
                  onChange={onChange}
                  error={form.formState.errors.Origin?.message}
                />
              )}
            />
          </div>
        </div> */}
        {/* Instance Type */}
        <div className="mb-6">
          <Card title="Instance Type">
            <WebAppPlansGroup control={control}>
              <AnimatedFormError error={errors.PlanId?.message} />
            </WebAppPlansGroup>
          </Card>
        </div>

        <div className="grid grid-cols-3 gap-x-10">
          <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
            <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
              Port{" "}
              <Typography.Text
                className="!text-xs !font-normal"
                uiType="secondary"
              >
                Optional
              </Typography.Text>
            </Typography.Text>
          </Flex>
          <div className="col-span-3 md:col-span-2">
            <Controller
              control={control}
              name="Port"
              render={({ field: { value, onChange } }) => (
                <Input
                  onChange={onChange}
                  value={value !== null ? value : 80}
                  type="number"
                  error={errors.Port?.message}
                />
              )}
            />
          </div>
        </div>

        {/* environment variables */}
        <div className="grid grid-cols-3 gap-x-10">
          <div className="col-span-3 md:col-span-1 text-sm">
            <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
              Environment Variables{" "}
              <Typography.Text
                className="!text-xs !font-normal"
                uiType="secondary"
              >
                Optional
              </Typography.Text>
            </Typography.Text>
            <Typography.Text uiType="secondary" size="sm">
              Set environment-specific config and secrets (such as API keys),
              then read those values from your code.{" "}
              <Typography.Link className="!text-sm">Learn more</Typography.Link>
              .
            </Typography.Text>
          </div>
          <div className="col-span-3 md:col-span-2">
            <div className="flex flex-col w-full">
              {ENVsFields.map((_, index) => (
                <div key={index} className="grid gap-3 grid-cols-12 pb-2">
                  <div className="col-span-4">
                    <Input
                      label=""
                      {...register(
                        `EvironmentVariables.${index}.Name` as const
                      )}
                      error={
                        errors.EvironmentVariables &&
                        errors.EvironmentVariables[index] &&
                        errors.EvironmentVariables[index]?.Name &&
                        errors.EvironmentVariables[index]?.Name?.message
                      }
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-span-7">
                    <div className="flex items-center gap-2">
                      <div className="flex-1">
                        <Input
                          label=""
                          {...register(
                            `EvironmentVariables.${index}.Value` as const
                          )}
                          error={
                            errors.EvironmentVariables &&
                            errors.EvironmentVariables[index] &&
                            errors.EvironmentVariables[index]?.Value &&
                            errors.EvironmentVariables[index]?.Value?.message
                          }
                          placeholder="Value"
                          className="flex-1"
                        />
                      </div>
                      {/* {!getValues(`EvironmentVariables.${index}.Value`) && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleGenerateEnvVal(index);
                          }}
                        >
                          generate
                        </Button>
                      )} */}
                    </div>
                  </div>
                  <div className="col-span-1 flex items-start justify-center">
                    <Button
                      uiType="icon"
                      className="!px-2 group"
                      disabled={false}
                      onClick={(e) => {
                        e.preventDefault();
                        ENVremove(index);
                      }}
                    >
                      <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 group-hover:text-red-500 group-hover:dark:text-red-400" />
                    </Button>
                  </div>
                </div>
              ))}
              <AnimatedFormError
                error={errors.EvironmentVariables?.root?.message}
              />
              <div className="flex justify-start gap-2 mt-2">
                <Button
                  uiType="light"
                  onClick={(e) => {
                    e.preventDefault();
                    ENVappend({
                      Name: "",
                      Value: "",
                    });
                  }}
                >
                  <PlusIcon className="w-4" />
                  <Typography.Text
                    size="sm"
                    uiType="transparent"
                    className="hidden md:block"
                  >
                    Add Environment Variable
                  </Typography.Text>
                  <Typography.Text
                    size="sm"
                    uiType="transparent"
                    className="md:hidden"
                  >
                    Add Variable
                  </Typography.Text>
                </Button>
                <Button
                  uiType="light"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(handleShowImportFromEnvFileModal());
                  }}
                >
                  <Typography.Text size="sm" uiType="transparent">
                    Add from .env
                  </Typography.Text>
                </Button>
                <ImportFromEnvFileModal callback={ENVappend} />
              </div>
            </div>
          </div>
        </div>

        {/* advanced */}
        {/* <div className="flex flex-col gap-3">
          <Button
            onClick={(e) => {
              e.preventDefault();
              setAdvanced((prev) => !prev);
            }}
          >
            Advanced
            <ArrowDownIcon
              className={cn("w-4 transition-transform duration-300", {
                "-rotate-180": advanced,
              })}
            />
          </Button>
          <AnimatePresence>
            {advanced && (
              <Card>
                <div className="grid grid-cols-3 gap-x-10 my-6">
                  <div className="col-span-1 text-sm font-normal font-sans">
                    <Typography.Text>
                      Store plaintext files containing secret data (such as a
                      <span className="!text-primary-300 !ml-1">.env</span> file
                      or a private key)
                    </Typography.Text>
                    <Typography.Text className="!mt-4">
                      Read these files during builds by absolute path at{" "}
                      <span className="!text-primary-300 !ml-1">{`/etc/secrets/<filename>`}</span>
                      .
                    </Typography.Text>
                  </div>
                  <div className="col-span-2">
                    <div className="flex flex-col w-full">
                      {secretFileFields.map((_, index) => (
                        <div
                          key={index}
                          className="grid gap-3 grid-cols-12 pb-2"
                        >
                          <div className="col-span-4">
                            <Input
                              label=""
                              {...register(
                                `SecretFiles.${index}.Filename` as const
                              )}
                              readOnly
                              error={
                                errors.SecretFiles &&
                                errors.SecretFiles[index] &&
                                errors.SecretFiles[index]?.Filename &&
                                errors.SecretFiles[index]?.Filename?.message
                              }
                              placeholder="file name"
                            />
                          </div>
                          <div className="col-span-7">
                            <div className="flex items-center gap-2">
                              <div className="flex-1">
                                <Input
                                  label=""
                                  {...register(
                                    `SecretFiles.${index}.FileContents` as const
                                  )}
                                  readOnly
                                  error={
                                    errors.SecretFiles &&
                                    errors.SecretFiles[index] &&
                                    errors.SecretFiles[index]?.FileContents &&
                                    errors.SecretFiles[index]?.FileContents
                                      ?.message
                                  }
                                  placeholder="file contents"
                                  className="flex-1"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 flex items-start justify-center">
                            <Button
                              uiType="light" //TODO: icon
                              onClick={(e) => {
                                e.preventDefault();
                                secretFileRemove(index);
                              }}
                              disabled={false}
                            >
                              <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                            </Button>
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-start gap-2 mt-2">
                        <Button
                          uiType="light"
                          onClick={(e) => {
                            e.preventDefault();
                            setSecretFileModal(true);
                          }}
                        >
                          <PlusIcon className="w-4" />
                          Add Secret File
                        </Button>
                      </div>
                      <SecretFileModal
                        isOpen={secretFileModal}
                        onClose={() => setSecretFileModal(false)}
                        callback={secretFileAppend}
                      />
                    </div>
                  </div>
                </div>

                <hr className="dark:border-slate-300/20" />

                <div className="disk-container my-6">
                  <div className="antialiased font-sans font-normal text-sm leading-6 space-y-4 max-w-lg">
                    <Typography.Text className="">
                      Create a disk to store application data that needs to
                      persist across deploys. You can mount it on any absolute
                      path and read and write to it using standard filesystem
                      primitives. Disks are charged at $0.25/GB per month.
                    </Typography.Text>
                    <Typography.Text size="sm">
                      Adding a disk prevents zero downtime deploys.{" "}
                      <Typography.Link className="!text-sm">
                        Learn more.
                      </Typography.Link>
                    </Typography.Text>
                  </div>
                  <div className="new-disk-container mt-2">
                    {!hasDisk && (
                      <Button
                        onClick={() =>
                          dispatch(handleChangeWebAppHasDisk(true))
                        }
                      >
                        Add Disk
                      </Button>
                    )}
                    {hasDisk && (
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-5 md:gap-10 mt-10">
                          <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                            <div className="antialiased font-sans font-normal text-sm leading-6 md:w-1/2">
                              <Typography.Text className="">
                                Name
                              </Typography.Text>
                              <Typography.Text
                                uiType="secondary"
                                className="!text-xs"
                              >
                                An identifying name for this disk. Used only for
                                display.
                              </Typography.Text>
                            </div>
                            <div className="flex-1">
                              <Input
                                {...register("DiskName")}
                                error={errors.DiskName?.message}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                            <div className="antialiased font-sans font-normal text-sm leading-6 md:w-1/2">
                              <Typography.Text>Mount Path</Typography.Text>
                              <Typography.Text
                                uiType="secondary"
                                className="!text-xs"
                              >
                                The absolute path to mount this disk, like
                                /var/lib/data. Can not be the root (/)
                                directory.
                              </Typography.Text>
                            </div>
                            <div className="flex-1">
                              <Input
                                {...register("DiskMountPath")}
                                error={errors.DiskMountPath?.message}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                            <div className="antialiased font-sans font-normal text-sm leading-6 md:w-1/2">
                              <Typography.Text className="">
                                Size (GB)
                              </Typography.Text>
                              <Typography.Text
                                uiType="secondary"
                                className="!text-xs"
                              >
                                You can increase the size later, but you can't
                                decrease it. Pick the lowest value that works
                                for your app.
                              </Typography.Text>
                            </div>
                            <div className="flex-1">
                              <Input
                                {...register("DiskSize")}
                                // type="number" //TODO
                                error={errors.DiskSize?.message}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <Button
                            uiType="danger"
                            onClick={() =>
                              dispatch(handleChangeWebAppHasDisk(false))
                            }
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <hr className="dark:border-slate-300/20" />

                <div className="grid grid-cols-3 gap-x-10 mt-6">
                  <div className="col-span-1 text-sm">
                    <Typography.Text className="!font-medium">
                      Health Check Path
                    </Typography.Text>
                    <Typography.Text uiType="secondary" size="sm">
                      If you're running a server, enter the path where your
                      server will always return a{" "}
                      <span className="text-primary-300 ml-1">200 OK</span>{" "}
                      response. We use it to monitor your app and for{" "}
                      <Typography.Link className="!text-sm">
                        zero downtime deploys
                      </Typography.Link>
                      .
                    </Typography.Text>
                  </div>
                  <div className="col-span-2">
                    <Input
                      {...register("HealthCheckPath")}
                      placeholder="/healthz"
                      error={errors.HealthCheckPath?.message}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-x-10 mt-6">
                  <div className="col-span-1 text-sm">
                    <Typography.Text className="!font-medium">
                      Docker Command
                    </Typography.Text>
                    <Typography.Text uiType="secondary">
                      Add an optional command to override the Docker{" "}
                      <span className="text-primary-300 ml-1">CMD</span> for
                      this service. This will also override the{" "}
                      <span className="text-primary-300 ml-1">ENTRYPOINT</span>
                      if defined in your Dockerfile. Examples:{" "}
                      <span className="text-primary-300 ml-1">
                        ./start.sh --type=worker
                      </span>{" "}
                      or{" "}
                      <span className="text-primary-300 ml-1">
                        ./bin/bash -c cd /some/dir && ./start.sh
                      </span>
                    </Typography.Text>
                  </div>
                  <div className="col-span-2">
                    <Input
                      {...register("DockerCommand")}
                      placeholder=""
                      error={errors.DockerCommand?.message}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-x-10 mt-6">
                  <div className="col-span-1 text-sm">
                    <Typography.Text className="!font-medium">
                      Pre-Deploy Command
                    </Typography.Text>
                    <Typography.Text uiType="secondary">
                      This command runs before starting your service. It is
                      typically used for tasks like running a database migration
                      or uploading assets to a CDN.{" "}
                    </Typography.Text>
                  </div>
                  <div className="col-span-2">
                    <Input
                      {...register("PreDeployCommand")}
                      placeholder=""
                      error={errors.PreDeployCommand?.message}
                    />
                  </div>
                </div>
              </Card>
            )}
          </AnimatePresence>
        </div> */}
      </div>
    </div>
  );
};

export const SecretFileModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  callback?: any;
  selectedFile?: WebAppSecretFile;
}> = ({ isOpen, onClose, callback, selectedFile }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(WebAppSecretFileSchema),
  });

  const handleAddSecret = (data: any) => {
    if (callback) {
      callback(data);
      onClose();
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setValue("Filename", selectedFile.Filename || "");
      setValue("FileContents", selectedFile.FileContents || "");
    } else {
      setValue("Filename", "");
      setValue("FileContents", "");
    }
  }, [selectedFile, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      title="Secret File"
      contentClassName="max-w-xl"
    >
      <form onSubmit={handleSubmit(handleAddSecret)} className="w-full">
        <Flex direction="col" className="gap-4 mt-5">
          <Input
            label="Filename"
            placeholder="file.txt"
            {...register("Filename")}
            error={errors.Filename?.message}
          />
          <Textarea
            label="File Contents"
            placeholder=""
            {...register("FileContents")}
            error={errors.FileContents?.message}
            rows={10}
          />
          <Flex justify="end" className="w-full">
            <Button uiType="primary" type="submit">
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export default CreateFormStep;
