import jwtAxios, { jwtEnvAxios } from ".";
const GET_CREDENTIALS_API_URL = `/registries`;
const GET_REGISTERIES_TYPES_API_URL = `/enums/registry-type`;
const ADD_CREDENTIAL_API_URL = `/registries`;
const REMOVE_CREDENTIAL_API_URL = (id: number) => `/registries/${id}`;

export function getCredentialsApi() {
  return jwtEnvAxios().get(GET_CREDENTIALS_API_URL);
}

export function getRegisteriesTypesApi() {
  return jwtAxios.get(GET_REGISTERIES_TYPES_API_URL);
}

export function addCredentailApi(data: any) {
  return jwtEnvAxios().post(ADD_CREDENTIAL_API_URL, data);
}

export function removeCredentialApi(id: number) {
  return jwtEnvAxios().delete(REMOVE_CREDENTIAL_API_URL(id));
}
