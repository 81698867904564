import { useEffect } from "react";

const useConfigIntercomeSetting = (vertical_padding: number = 70) => {
  useEffect(() => {
    if (window.intercomSettings) {
      window.intercomSettings = {
        ...window.intercomSettings,
        vertical_padding,
      };
    }

    return () => {
      if (window.intercomSettings) {
        window.intercomSettings = {
          ...window.intercomSettings,
          vertical_padding: 20,
        };
      }
    };
  }, [vertical_padding]);
  return null;
};

export default useConfigIntercomeSetting;
