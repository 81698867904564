import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getBillingInformationAsync,
  handleShowBillingInformationModal,
  selectBillingInformation,
  selectBillingInformationActionLoading,
  selectBillingInformationLoading,
  selectShowBillingInformationModal,
  updateBillingInformationAsync,
} from "../../../store/billing/billingSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { BillingInformationSchema } from "../../../utils/validations";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getCountrieAsync,
  selectCountries,
  selectCountriesLoading,
} from "../../../store/publicSlice";
import { getCountryFlag } from "../../../utils";
import axios from "axios";
import {
  Button,
  Typography,
  Select,
  SelectOption,
  Modal,
  Input,
} from "djuno-design";

const BillingInformationModal = () => {
  const isOpen = useAppSelector(selectShowBillingInformationModal);
  const dispatch = useAppDispatch();

  const billingInformation = useAppSelector(selectBillingInformation);
  const billingInformationLoading = useAppSelector(
    selectBillingInformationLoading
  );
  const billingInformationActionLoading = useAppSelector(
    selectBillingInformationActionLoading
  );

  const countries = useAppSelector(selectCountries);
  const countriesLoading = useAppSelector(selectCountriesLoading);

  const [statesLoading, setStatesLoading] = useState(false);
  const [countryStates, setCountryStates] = useState<Array<{ name: string }>>(
    []
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(BillingInformationSchema()),
  });

  const selectedCountryId = watch("CountryId");
  // const selectedState = watch("State");

  useEffect(() => {
    if (billingInformation && isOpen) {
      setValue("CountryId", billingInformation.CountryId);
      setValue("State", billingInformation.State);
      setValue("City", billingInformation.City);
      setValue("ZipCode", billingInformation.ZipCode);
      setValue("AddressLine1", billingInformation.AddressLine1);
      setValue("AddressLine2", billingInformation.AddressLine2);
      setValue("VatNumber", billingInformation.VATNumber);
    }
  }, [billingInformation, isOpen, setValue]);

  useEffect(() => {
    if (countries.length === 0 && isOpen) {
      dispatch(getCountrieAsync());
    }
  }, [countries.length, dispatch, isOpen]);

  const handleSubmitForm = (data: any) => {
    if (!billingInformationLoading && !statesLoading) {
      dispatch(updateBillingInformationAsync({ data })).then((action) => {
        if (action.type === "billing/update-informations/fulfilled") {
          dispatch(getBillingInformationAsync());
          dispatch(handleShowBillingInformationModal(false));
        }
      });
    }
  };

  const handleClose = useCallback(() => {
    dispatch(handleShowBillingInformationModal(false));
    reset();
  }, [dispatch, reset]);

  //------------------- universal section -------------------
  //https://www.universal-tutorial.com/rest-apis/free-rest-api-for-country-state-city

  // const [universalLoading, setuniversalLoading] = useState(false);
  // const [authToken, setAuthToken] = useState<string>();

  // const [citiesLoading, setCitiesLoading] = useState(false);
  // const [cities, setCities] = useState<Array<{ city_name: string }>>([]);

  // const getApiToken = useCallback(async () => {
  //   try {
  //     setuniversalLoading(true);
  //     const res = await axios.get(
  //       `https://www.universal-tutorial.com/api/getaccesstoken`,
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           "api-token":
  //             "VNAza51kTGYthfaihvXw9BTaxfEugvInf1IQWzQNP6uW_B1QCFQL9s8Bk0-0GIqWElU",
  //           "user-email": "ansar@djuno.io",
  //         },
  //       }
  //     );
  //     setuniversalLoading(false);
  //     setAuthToken(res.data.auth_token);
  //   } catch (e) {
  //     setuniversalLoading(false);
  //     handleClose();
  //   }
  // }, [handleClose]);

  //get auth-token
  // useEffect(() => {
  //   if (isOpen) {
  //     getApiToken();
  //   }
  // }, [getApiToken, isOpen]);

  const selectedCountry = useMemo(() => {
    return countries.find((c) => c.Id === Number(selectedCountryId));
  }, [countries, selectedCountryId]);

  //get states of country
  useEffect(() => {
    const getStates = async (country_name: string) => {
      try {
        setStatesLoading(true);
        // const res = await axios.get(
        //   `https://www.universal-tutorial.com/api/states/${name}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${authToken}`,
        //       Accept: "application/json",
        //     },
        //   }
        // );
        // const states = res.data as Array<{ state_name: string }>;

        const res = await axios.post(
          `https://countriesnow.space/api/v0.1/countries/states`,
          { country: country_name },
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        const states = res.data.data.states as Array<{ name: string }>;

        if (states.length === 0) {
          if (selectedCountry) {
            setCountryStates([{ name: selectedCountry.Name }]);
            setValue("State", selectedCountry.Name);
          }
        } else {
          setCountryStates(states);
          setValue("State", states[0].name);
        }
        setStatesLoading(false);
      } catch (e) {
        setStatesLoading(false);
        setCountryStates([]);
        setValue("State", country_name);
      }
    };

    if (selectedCountry) {
      if (isOpen) getStates(selectedCountry.Name);
    } else {
      setCountryStates([]);
      setValue("State", "");
    }
  }, [billingInformation, isOpen, selectedCountry, setValue]);

  //get cities of state
  // useEffect(() => {
  //   const getCities = async (name: string) => {
  //     try {
  //       setCitiesLoading(true);
  //       const res = await axios.get(
  //         `https://www.universal-tutorial.com/api/cities/${name}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //             Accept: "application/json",
  //           },
  //         }
  //       );

  //       const cities = res.data as Array<{ city_name: string }>;
  //       setCitiesLoading(false);
  //       setCities(cities);
  //       setValue("City", cities[0].city_name);
  //     } catch (e) {
  //       setCitiesLoading(false);
  //       setCities([]);
  //     }
  //   };

  //   if (selectedState && authToken) {
  //     getCities(`Appenzell-Ausser_Rhoden`);
  //   }
  // }, [authToken, selectedState, setValue]);

  //------------------- end universal section -------------------

  const countryOprions = useMemo(() => {
    if (countries.length !== 0) {
      const options: SelectOption[] = countries.map((country) => ({
        label: (
          <div className="flex items-center gap-1">
            <img
              alt={country.Name}
              src={getCountryFlag(country.Iso3)}
              className="w-4 h-4"
            />
            <Typography.Text className="text-sm">
              {country.Name}
            </Typography.Text>
          </div>
        ),
        value: country.Id.toString(),
        extraData: { iso3: country.Iso3 },
      }));
      return options;
    } else {
      return [];
    }
  }, [countries]);

  return (
    <Modal
      isOpen={isOpen}
      title={"Update billing information"}
      contentClassName="max-w-lg"
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="col-span-2 mt-5">
          <Input
            label="Address 1"
            {...register("AddressLine1")}
            error={errors.AddressLine1?.message}
            required
          />
        </div>
        <div className="col-span-2 mt-5">
          <Input
            label="Address 2"
            {...register("AddressLine2")}
            error={errors.AddressLine2?.message}
          />
        </div>
        <div className="grid grid-cols-2 gap-5 mt-5">
          <div className="col-span-1">
            <Controller
              name="CountryId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  label="Country"
                  options={countryOprions}
                  value={value?.toString()}
                  onChange={onChange}
                  loading={countriesLoading}
                  emptyString="Select a country"
                  error={errors.CountryId?.message}
                  required
                  optionsClassName="!max-h-[200px]"
                />
              )}
            />
          </div>
          <div className="col-span-1">
            {/* <Input
              label="State"
              inputProps={{ ...register("State") }}
              error={errors.State?.message}
            /> */}

            <Controller
              name="State"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  label="State"
                  options={countryStates.map((s) => ({
                    label: s.name,
                    value: s.name,
                  }))}
                  value={value?.toString()}
                  onChange={onChange}
                  loading={countriesLoading || statesLoading}
                  emptyString="Select an state"
                  error={errors.State?.message}
                  required
                  optionsClassName="!max-h-[200px]"
                />
              )}
            />
          </div>
          <div className="col-span-1">
            <Input
              label="City"
              {...register("City")}
              error={errors.City?.message}
              required
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Zipcode"
              {...register("ZipCode")}
              error={errors.ZipCode?.message}
              required
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Vat Number"
              {...register("VatNumber")}
              error={errors.VatNumber?.message}
            />
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <Button
            uiType="primary"
            type="submit"
            loading={
              billingInformationLoading || billingInformationActionLoading
              // || universalLoading
            }
          >
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default BillingInformationModal;
