import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectRpc,
  selectLoading,
  selectRpcMetrics,
  selectRpcMetricsLoading,
  getRpcMetricsAsync,
} from "../../../store/rpc/rpcSlice";
import { Flex, Loading, Typography } from "djuno-design";
import {
  KuberoMetricChart,
  kuberoMetricChartName,
  kuberoMetricChartYName,
} from "../../general/charts/KuberoMetricChart";

const RpcEndpointMetricsTab = () => {
  const rpc = useAppSelector(selectRpc);
  const loading = useAppSelector(selectLoading);

  const rpcMetrics = useAppSelector(selectRpcMetrics);
  const rpcMetricsLoading = useAppSelector(selectRpcMetricsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (rpc) dispatch(getRpcMetricsAsync({ id: rpc.Id }));
  }, [rpc, dispatch]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (rpc) {
      interval = setInterval(() => {
        dispatch(getRpcMetricsAsync({ id: rpc.Id, withoutLoading: true }));
      }, 10000);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, rpc]);

  return (
    <>
      <div className="flex transition-all duration-500">
        <div className="text-slate-800 dark:text-slate-100"></div>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2"></div>
      </div>
      {(rpcMetricsLoading || loading) && (
        <Flex items="center" justify="center" className="!min-h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}
      {!rpcMetricsLoading &&
        rpcMetrics &&
        Object.keys(rpcMetrics).length > 0 && (
          <div className="flex flex-col w-full gap-14">
            {Object.keys(rpcMetrics).map((key, index) => {
              return (
                <div className="h-[200px]" key={index}>
                  <div className="w-full flex justify-center mt-5">
                    <Typography.Text
                      size="xs"
                      uiType="secondary"
                      className="font-semibold"
                    >
                      {kuberoMetricChartName(key)}
                    </Typography.Text>
                  </div>
                  <KuberoMetricChart
                    yName={kuberoMetricChartYName(key)}
                    data={rpcMetrics[key as keyof typeof rpcMetrics]}
                  />
                </div>
              );
            })}
          </div>
        )}
    </>
  );
};

export default RpcEndpointMetricsTab;
