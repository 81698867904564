import WebBanner from "../../components/website/WebBanner";
import {
  Button,
  Divider,
  Flex,
  Input,
  Loading,
  Textarea,
  Typography,
} from "djuno-design";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactUsSchema } from "../../utils/validations";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaGithubSquare } from "react-icons/fa";
import { FaDocker } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState } from "react";
import SectionTitle from "../../components/website/SectionTitle";
import { Axios } from "../../apis";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import { Helmet } from "react-helmet";

const ContactUsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ContactUsSchema),
  });

  const onSubmit = async (data: any) => {
    if (!loading) {
      const userData = {
        email: data.email,
        message: data.message,
        fullname: data.fullname,
      };

      try {
        setLoading(true);
        await Axios.post("/contacts", userData);
        reset();
        toast.success(
          "Thank you for your communication. Email sent successfully",
          {
            className: ToastClasses,
          }
        );
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  //google map
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
  });
  const center = useMemo(() => ({ lat: 47.1678737, lng: 8.7267147 }), []);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME || ""}</title>
        <meta name="description" content="" />
      </Helmet>
      <WebBanner
        title="Contact Us"
        breadcrumb={[{ name: "Home", link: "/" }, { name: "Contact Us" }]}
      />
      <div className="flex flex-col w-full max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1320px] mx-auto h-full px-8 mt-14">
        <div className="flex flex-col lg:flex-row  mt-15 pt-15 gap-5">
          <div className="flex flex-col lg:w-1/3 md:w-full mb-8 ">
            <SectionTitle>
              <Typography.Text size="3xl" className="">
                Get In Touch
              </Typography.Text>
            </SectionTitle>
            <div className=" tracking-wide space-y-7 mt-[38px]">
              <Typography.Paragraph uiType="secondary">
                We appreciate your interest in Djuno low code development
                platform.
              </Typography.Paragraph>
              <Typography.Paragraph uiType="secondary">
                You’ve got a business opportunity and need development services?
                Or you are looking for more information and want to try our
                products; let’s get this conversation started. Tell us a bit
                about yourself and we’ll get in touch as soon as possible.
              </Typography.Paragraph>
            </div>
          </div>

          <div className="lg:w-2/3 md:w-full">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <Input
                    placeholder="fullname"
                    {...register("fullname")}
                    error={errors.fullname?.message}
                    uiSize="large"
                  />
                </div>
                <div>
                  <Input
                    placeholder="email"
                    {...register("email")}
                    error={errors.email?.message}
                    uiSize="large"
                  />
                </div>
                <div className="md:col-span-2">
                  <Textarea
                    placeholder="message"
                    {...register("message")}
                    rows={7}
                    error={errors.message?.message}
                    className="!p-2"
                  />
                </div>
              </div>
              <Flex justify="start" className="!mt-5">
                <Button uiType="primary" type="submit" loading={loading}>
                  SEND
                </Button>
              </Flex>
            </form>
          </div>
        </div>
      </div>

      <Divider className="w-full mx-auto max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1320px] " />

      {/* Google Maps */}
      <div className="flex flex-col w-full max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1320px] mx-auto h-full px-8 mb-14 relative">
        <div className=" w-full relative">
          <div className="w-full h-[500px] rounded-lg overflow-hidden shadow-lg z-10 mt-12">
            {!isLoaded ? (
              //   <h1 className="flex justify-center items-center h-full text-4xl font-bold text-[#6f8ba4]">
              //     Loading...
              //   </h1>
              <Flex items="center" justify="center" className="mt-8">
                <Loading borderSize={2} />
              </Flex>
            ) : (
              <GoogleMap
                mapContainerClassName="w-full h-full"
                center={center}
                zoom={10}
              >
                <Marker position={{ lat: 47.1678737, lng: 8.7267147 }} />
              </GoogleMap>
            )}
          </div>
          <div className="relative z-20 w-4/5 bg-primary-500 shadow-lg rounded-tl-[25px] rounded-tr-[3px] rounded-br-[25px] rounded-bl-[25px] mt-[-90px] ml-[10%] px-8 py-8">
            <div className="flex flex-wrap">
              <div className="lg:w-2/3 md:w-1/2 w-full mb-4">
                <div className="flex items-start text-white space-x-2 ml-0 md:ml-12 lg:ml-12">
                  <i className="text-lg">
                    <FaLocationArrow />
                  </i>
                  <div className="flex flex-col ">
                    <Typography.Text className="!text-sm !text-white !leading-7">
                      Djuno GmbH <br />
                      Chaltenbodenstrasse 6F Schindellegi 8834 Schwyz
                      <br />
                      Switzerland
                    </Typography.Text>
                  </div>
                </div>
                <div className="flex items-start text-white space-x-2 ml-0 md:ml-12 lg:ml-12 mt-3">
                  <i className="text-lg ">
                    <FaLocationArrow />
                  </i>
                  <div className="flex flex-col ">
                    <Typography.Text className="!text-sm !text-white !leading-7">
                      Djuno Ltd <br />
                      Lytchett House Wareham Road, 13 Freeland Park, BH16 6FA
                      Poole,
                      <br />
                      England
                    </Typography.Text>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/2 w-full ">
                <div className=" flex items-start text-white space-x-2 ml-0 md:ml-12 lg:ml-12 mb-2">
                  <i className="text-lg">
                    <FaEnvelope />
                  </i>

                  <Typography.Link
                    href="mailto:info@djuno.io"
                    className="!text-white !text-sm"
                  >
                    info@djuno.io
                  </Typography.Link>
                </div>
                <div className=" flex items-start text-white space-x-2 ml-0 md:ml-12 lg:ml-12 mb-2">
                  <i className="text-lg">
                    <FaEnvelope />
                  </i>

                  <Typography.Link
                    href="mailto:support@djuno.io"
                    className="!text-white !text-sm"
                  >
                    support@djuno.io
                  </Typography.Link>
                </div>
                <div className="flex items-start text-white space-x-2 ml-0 md:ml-12 lg:ml-12">
                  <i className="text-lg">
                    <BsFillTelephoneFill />
                  </i>
                  <Typography.Text className="!text-white !text-sm">
                    +41 795663797
                  </Typography.Text>
                </div>
              </div>
            </div>
            <ul className="flex justify-center space-x-4 mt-6 text-2xl">
              <li>
                <Typography.Link
                  href="https://twitter.com/bluefug"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="!text-white"
                >
                  <FaTwitterSquare />
                </Typography.Link>
              </li>
              <li>
                <Typography.Link
                  href="https://www.linkedin.com/in/jasonenglish/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="!text-white"
                >
                  <FaLinkedin />
                </Typography.Link>
              </li>
              <li>
                <Typography.Link
                  href="https://github.com/Djuno-Ltd"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="!text-white"
                >
                  <FaGithubSquare />
                </Typography.Link>
              </li>
              <li>
                <Typography.Link
                  href="https://hub.docker.com/u/djunoltd"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="!text-white"
                >
                  <FaDocker />
                </Typography.Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
