import { Button, Modal } from "djuno-design";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  handleSetWebAppCredentialId,
  handleSetWebAppImageQuery,
  handleSetWebAppImageValue,
  handleSetWebAppTagQuery,
  handleSetWebAppTagValue,
  selectWebAppImageCredentialId,
  selectWebAppImageQuery,
  selectWebAppImageValue,
  selectWebAppTagQuery,
} from "../../../../store/web-app/webAppCreateSlice";
import {
  getWebAppAsync,
  handleHideWebAppUpdateImageModal,
  selectWebApp,
  selectWebAppShowUpdateImageModal,
  selectWebAppUpdateLoading,
  updateWebAppAsync,
} from "../../../../store/web-app/webAppSlice";
import { DeployingImageFrom } from "../../create-page-steps/SelectDeployingSourceStep";
import { useCallback } from "react";

const WebAppImageEditorModal = () => {
  const webApp = useAppSelector(selectWebApp);

  const isOpen = useAppSelector(selectWebAppShowUpdateImageModal);

  const selectedCredentialId = useAppSelector(selectWebAppImageCredentialId);

  const imageValue = useAppSelector(selectWebAppImageValue);
  const imageQuery = useAppSelector(selectWebAppImageQuery);
  const tagQuery = useAppSelector(selectWebAppTagQuery);

  const updateLoading = useAppSelector(selectWebAppUpdateLoading);

  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(handleHideWebAppUpdateImageModal());
    dispatch(handleSetWebAppImageValue(""));
    dispatch(handleSetWebAppTagValue(""));
    dispatch(handleSetWebAppImageQuery(""));
    dispatch(handleSetWebAppTagQuery(""));
    dispatch(handleSetWebAppCredentialId(null));
  };

  // const isNotActive = useMemo(() => {
  //   return webApp?.ServiceStatus !== 2;
  // }, [webApp?.ServiceStatus]);

  const handleUpdateImage = useCallback(() => {
    if (webApp && !updateLoading) {
      dispatch(
        updateWebAppAsync({
          webAppId: webApp.Id.toString(),
          data: {
            ImageName: imageValue,
            ImageTag: tagQuery,
            registryId: selectedCredentialId,
          },
        })
      ).then((action) => {
        if (action.type === "web-app/update/fulfilled") {
          dispatch(handleHideWebAppUpdateImageModal());
          dispatch(
            getWebAppAsync({
              webAppId: webApp.Id.toString(),
              withoutLoading: true,
            })
          );
        }
      });
    }
  }, [
    dispatch,
    imageValue,
    selectedCredentialId,
    tagQuery,
    updateLoading,
    webApp,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      title="Update Image"
      contentClassName="!max-w-3xl"
      onClose={handleCloseModal}
    >
      <DeployingImageFrom />
      {/* {validationResponse && (
        <div className="mt-4 text-sm">
          <WebAppImageValidationMessage {...validationResponse} />
        </div>
      )} */}
      <div className="mt-4 flex justify-end gap-2">
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleCloseModal();
          }}
          uiType="light"
        >
          Cancel
        </Button>
        <Button
          loading={updateLoading}
          uiType="primary"
          disabled={
            imageValue === "" || imageValue !== imageQuery || tagQuery === ""
          }
          type="submit"
          onClick={handleUpdateImage}
        >
          Save Changes
        </Button>
      </div>
    </Modal>
  );
};

export default WebAppImageEditorModal;
