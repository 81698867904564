import { Flex, Typography } from "djuno-design";
import Container from "../layouts/Container";
import SectionTitle from "./SectionTitle";
import AnimatedLinkBox from "./AnimatedLinkBox";

import productImage from "./../../assets/images/undraw_building_websites_i78t.svg";
import { AnimatedFadeInBox } from "./AnimatedFadeInBox";

const docsUrl = process.env.REACT_APP_DOCS_URL || "";

const productsItems = [
  {
    title: "Fully managed RPC endpoints",
    link: docsUrl + "/en/articles/10108217-rpc-endpoints",
  },
  {
    title: "Workflow studio",
    link: docsUrl + "/en/articles/10108417-workflow-studio",
  },
  {
    title: "Databases",
    link: docsUrl + "/en/articles/10108729-databases",
  },
  {
    title: "Managed compute instances",
    link: docsUrl + "/en/articles/10108734-instances",
  },
  {
    title: "Fully managed object storage",
    link: docsUrl + "/en/articles/10108413-object-storage",
  },
  {
    title: "Blockchain event streams",
    link: docsUrl + "/en/articles/10108385-block-events",
  },
  {
    title: "Fully managed IPFS nodes",
    link: docsUrl + "/en/articles/10108735-managed-ipfs",
  },
  {
    title: "Web3 authentication",
    link: docsUrl + "/en/articles/10108332-web3-0-auth-service",
  },
];

const ProductsBox = () => {
  return (
    <Container id="products">
      <div className="grid grid-cols-12 md:gap-8 mt-32">
        <div className="col-span-12 lg:col-span-8">
          <Flex direction="col" className="gap-4">
            <SectionTitle>
              <Typography.Text size="2xl" className="">
                Products
              </Typography.Text>
            </SectionTitle>

            <Typography.Text
              size="base"
              uiType="secondary"
              className="tracking-wide"
            >
              Djuno offers a variety of products; the largest cloud offers
              search engine. Freely browse VM prices from 25+ providers, as well
              as enterprise grade decentralized storage compatible with S3 are
              some of them.
            </Typography.Text>

            <div className="grid grid-cols-12 gap-y-7 md:gap-8">
              {productsItems.map((item, index) => (
                <AnimatedLinkBox key={index} to={item.link} target="_blank">
                  <Typography.Text size="sm">{item.title}</Typography.Text>
                </AnimatedLinkBox>
              ))}
            </div>
          </Flex>
        </div>
        <div className="col-span-12 lg:col-span-4 self-center">
          <AnimatedFadeInBox>
            <img src={productImage} className="block mx-auto" alt="product" />
          </AnimatedFadeInBox>
        </div>
      </div>
    </Container>
  );
};

export default ProductsBox;
