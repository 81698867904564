import { Flex, Typography } from "djuno-design";
import Container from "../layouts/Container";
import SectionTitle from "./SectionTitle";
import AnimatedLinkBox from "./AnimatedLinkBox";

import serviceImage from "./../../assets/images/use-cases.png";
import { AnimatedFadeInBox } from "./AnimatedFadeInBox";

const docsUrl = process.env.REACT_APP_DOCS_URL || "";
const useCasesItems = [
  {
    title: "Developers",
    link:
      docsUrl + "/en/articles/10126134-djuno-developer-platform-for-developer",
  },
  {
    title: "Life sciences",
    link:
      docsUrl +
      "/en/articles/10126153-life-sciences-on-djuno-development-platform",
  },
  {
    title: "CTOs",
    link: docsUrl + "/en/articles/10126141-djuno-developer-platform-for-ctos",
  },
  {
    title: "Financial Services",
    link:
      docsUrl +
      "/en/articles/10126155-financial-services-on-djuno-development-platform",
  },
  {
    title: "DevOps",
    link: docsUrl + "/en/articles/10126142-djuno-developer-platform-for-devops",
  },
  {
    title: "Digital transformation",
    link:
      docsUrl +
      "/en/articles/10126150-digital-transformation-with-djuno-development-platform",
  },
  {
    title: "Startups",
    link:
      docsUrl + "/en/articles/10126144-djuno-developer-platform-for-startups",
  },
  {
    title: "Enterprises",
    link:
      docsUrl +
      "/en/articles/10126147-djuno-developer-platform-for-enterprises",
  },
];

const UseCasesBox = () => {
  return (
    <Container id="use-cases">
      <div className="grid grid-cols-12 md:gap-8 mt-32">
        <div className="col-span-12 lg:col-span-4 self-center">
          <AnimatedFadeInBox>
            <img src={serviceImage} className="block mx-auto" alt="product" />
          </AnimatedFadeInBox>
        </div>
        <div className="col-span-12 lg:col-span-8">
          <Flex direction="col" className="gap-4">
            <SectionTitle>
              <Typography.Text size="2xl" className="">
                Use Cases
              </Typography.Text>
            </SectionTitle>

            <Typography.Text
              size="base"
              uiType="secondary"
              className="tracking-wide"
            >
              Djuno can help enterprises reduce costs, automate and optimise
              their infrastructure, and integrate Web3 technologies.
            </Typography.Text>

            <div className="grid grid-cols-12 gap-y-7 md:gap-8">
              {useCasesItems.map((item, index) => (
                <AnimatedLinkBox key={index} to={item.link} target="_blank">
                  <Typography.Text size="sm">{item.title}</Typography.Text>
                </AnimatedLinkBox>
              ))}
            </div>
          </Flex>
        </div>
      </div>
    </Container>
  );
};

export default UseCasesBox;
