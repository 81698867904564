import { useNavigate } from "react-router-dom";
import { ReactComponent as BellAlertIcon } from "./../../assets/icons/bell-alert.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { BlockEventNotificationsUrl } from "../../utils/urls";
import { useEffect, useState } from "react";
import { BlockEventCreateLevels } from "../../types/block-events";
import SelectTempleteStep from "../../components/block-events/notifications/create-page-steps/SelectTempleteStep";
import CreateExpressionStep from "../../components/block-events/notifications/create-page-steps/CreateExpressionStep";
import SelectDestinationStep from "../../components/block-events/notifications/create-page-steps/SelectDestinationStep";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectExpressionHasValidate,
  selectExpressionValue,
  selectNotificationName,
  selectSelectedChain,
  selectSelectedNetwork,
  selectSelectedTemplate,
  selectSelectedDestinations,
  createBlockEventAsync,
  selectCreateBlockEventLoading,
  handleClearBlockEventCreationSlice,
} from "../../store/block-event/blockEventCreateSlice";
import { Button, Steps, Typography } from "djuno-design";
import useConfigIntercomeSetting from "../../hooks/useConfigIntercomeSetting";

const BlockEventCreatePage = () => {
  useConfigIntercomeSetting();
  const navigate = useNavigate();
  const [step, setStep] = useState<BlockEventCreateLevels>("SelectTemplete");

  const notificationName = useAppSelector(selectNotificationName);
  const selectedChain = useAppSelector(selectSelectedChain);
  const selectedNetwork = useAppSelector(selectSelectedNetwork);
  const selectedTemplate = useAppSelector(selectSelectedTemplate);
  const expressionValue = useAppSelector(selectExpressionValue);
  const expressionHasValidate = useAppSelector(selectExpressionHasValidate);
  const destinations = useAppSelector(selectSelectedDestinations);
  const createLoading = useAppSelector(selectCreateBlockEventLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(handleClearBlockEventCreationSlice());
    };
  }, [dispatch]);

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="dark:text-slate-100 flex items-center gap-1">
            <BellAlertIcon className="w-6 h-6" />
            <Typography.Text className="font-medium">
              Create Event
            </Typography.Text>
          </div>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(BlockEventNotificationsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <Steps
        step={step}
        steps={[
          {
            label: "Select Templete",
            value: "SelectTemplete",
            callback: () => setStep("SelectTemplete"),
          },
          {
            label: "Create Expression",
            value: "CreateExpression",
            callback: () => setStep("CreateExpression"),
          },
          { label: "Select Destination", value: "SelectDestination" },
        ]}
        className="px-6"
      />
      <div className="mt-5 w-full px-6">
        <div className="mx-auto flex-1 flex justify-start pb-14 w-full">
          {step === "SelectTemplete" && <SelectTempleteStep />}
          {step === "CreateExpression" && <CreateExpressionStep />}
          {step === "SelectDestination" && <SelectDestinationStep />}
        </div>
      </div>
      <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80">
        {step === "SelectTemplete" && (
          <div className="flex justify-end w-full">
            <Button
              uiType="primary"
              disabled={
                !!!notificationName ||
                !!!selectedChain ||
                !!!selectedNetwork ||
                !!!selectedTemplate
              }
              onClick={() => setStep("CreateExpression")}
              className="group  w-[110px]"
            >
              Continue
              <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
            </Button>
          </div>
        )}
        {step === "CreateExpression" && (
          <div className="flex items-center justify-between w-full">
            <Button
              uiType="light"
              onClick={() => {
                setStep("SelectTemplete");
              }}
            >
              back
            </Button>
            <Button
              uiType="primary"
              disabled={
                !!!expressionValue || !expressionHasValidate || !selectedNetwork
              }
              onClick={() => setStep("SelectDestination")}
              className="group  w-[110px]"
            >
              Continue
              <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
            </Button>
          </div>
        )}
        {step === "SelectDestination" && (
          <div className="flex items-center justify-between w-full">
            <Button
              uiType="light"
              onClick={() => {
                setStep("CreateExpression");
              }}
            >
              back
            </Button>
            <Button
              uiType="primary"
              disabled={destinations.length === 0}
              onClick={() => {
                dispatch(createBlockEventAsync()).then((action) => {
                  if (action.type === "block-events/create/fulfilled") {
                    navigate(BlockEventNotificationsUrl);
                  }
                });
              }}
              className="group  w-[110px]"
              loading={createLoading}
            >
              Deploy
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default BlockEventCreatePage;
