import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import {
  AuthUser,
  AuthUserState,
  ForgotPasswordApiType,
  Generate2FaResponse,
  LoginApiType,
  LoginUser,
  RegisterApiType,
  ResetPasswordApiType,
} from "../../types/auth";
import {
  changePasswordApi,
  disable2faApi,
  enable2faApi,
  forgotPasswordApi,
  generateOTPApi,
  getMeApi,
  getRecoveryStringApi,
  loginApi,
  reGenerateRecoveryStringApi,
  registerApi,
  resendVerificationEmailApi,
  resetPasswordApi,
  updateProfileApi,
  updateProfileAvatarApi,
  validate2faApi,
  verificationEmailApi,
} from "../../apis/authAPI";
import { Environment } from "../../types/environment";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import { handleSetEnvironments } from "../environments/environmentsSlice";
import { CustomErrorToast } from "../../components/general/Toast";
import { getExtractErrors } from "../../apis";
import { getLocalStorage, setLocalStorage } from "djuno-design";

const initialState: AuthUserState = {
  isAuthenticate: !!getLocalStorage<boolean>("isAuthenticate", false),
  login: getLocalStorage<LoginUser | null>("login", null),
  user: getLocalStorage<AuthUser | null>("user", null),
  onStageEnv: getLocalStorage<Environment | null>("env", null),
  loginLoading: false,
  userLoading: false,
  emailVerificationLoading: false,
  profileUpdateLoading: false,
  showChangePassModal: false,
  changePassLoading: false,
  changeAvatarLoading: false,
  confettiStatus: false,

  //2FA
  showEnable2faModal: false,
  showRecoveryModal: false,
  recoveryString: null,
  recoveryLoading: false,
  canCloseRecoveryModal: false,
  canRegenerateRecovery: true,
  generateOTPloading: false,
  enable2FAloading: false,
  disable2FAloading: false,
  validate2FAloading: false,
};

export const useAsyncLogout = true;

export const loginAsync = createAsyncThunk<
  { login: LoginUser },
  LoginApiType,
  IThunkRejectValue
>("auth/login", async (data, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await loginApi(data);
    const login = response.data.Result;

    return fulfillWithValue({ login });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const registerAsync = createAsyncThunk<
  { register: LoginUser },
  { token: string | null; data: RegisterApiType },
  IThunkRejectValue
>(
  "auth/register",
  async ({ token, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await registerApi(token, data);
      const register = response.data.Result;

      return fulfillWithValue({ register });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getMeAsync = createAsyncThunk<
  { user: AuthUser; envName: any },
  { envName?: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "auth/me",
  async (
    { envName, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getMeAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getMeApi();
      const user = response.data.Result;

      dispatch(handleSetEnvironments({ environments: user.Environments }));

      return fulfillWithValue({ user, envName });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const verificationEmailAsync = createAsyncThunk<
  any,
  { token: string },
  IThunkRejectValue
>("auth/verification-email", async ({ token }, { rejectWithValue }) => {
  try {
    const res = await verificationEmailApi(token);
    return res.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const resendVerificationEmailAsync = createAsyncThunk<
  any,
  undefined,
  IThunkRejectValue
>("auth/resend-verification-email", async (_, { rejectWithValue }) => {
  try {
    const res = await resendVerificationEmailApi();
    return res.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const forgotPasswordAsync = createAsyncThunk<
  { message: string },
  ForgotPasswordApiType,
  IThunkRejectValue
>(
  "auth/forgot-password",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await forgotPasswordApi(data);
      return fulfillWithValue({ message: response.data.data });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const resetPasswordAsync = createAsyncThunk<
  { message: string },
  ResetPasswordApiType,
  IThunkRejectValue
>(
  "auth/reset-password",
  async (
    { password, passwordConfirmation, token },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await resetPasswordApi(
        password,
        passwordConfirmation,
        token
      );
      return fulfillWithValue({ message: response.data.data });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateProfileAsync = createAsyncThunk<
  any,
  { FullName: string },
  IThunkRejectValue
>(
  "auth/update-profile",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await updateProfileApi(data);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const changePasswordAsync = createAsyncThunk<
  any,
  {
    password: string;
    newPassword: string;
    newPasswordConfirmation: string;
  },
  IThunkRejectValue
>(
  "auth/change-password",
  async (
    { password, newPassword, newPasswordConfirmation },
    { rejectWithValue }
  ) => {
    try {
      const response = await changePasswordApi(
        password,
        newPassword,
        newPasswordConfirmation
      );
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateProfileAvatarAsync = createAsyncThunk<
  any,
  { img: File },
  IThunkRejectValue
>(
  "auth/update-profile-avatar",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await updateProfileAvatarApi(data);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const generateOTPAsync = createAsyncThunk<
  Generate2FaResponse,
  undefined,
  IThunkRejectValue
>("auth/generate-otp", async (_, { rejectWithValue }) => {
  try {
    const response = await generateOTPApi();
    return response.data.Result;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const enableTwoFactorAsync = createAsyncThunk<
  any,
  { code: string },
  IThunkRejectValue
>("auth/enable-2fa", async ({ code }, { rejectWithValue }) => {
  try {
    const response = await enable2faApi({ AuthenticationCode: code });
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const disableTwoFactorAsync = createAsyncThunk<
  any,
  undefined,
  IThunkRejectValue
>("auth/disable-2fa", async (_, { rejectWithValue }) => {
  try {
    const response = await disable2faApi();
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const validateTwoFactorAsync = createAsyncThunk<
  { login: LoginUser },
  { AuthenticationCode: string; Email: string; Password: string },
  IThunkRejectValue
>("auth/validate-2fa", async (data, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await validate2faApi(data);
    const login = response.data.Result;
    return fulfillWithValue({ login });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getRecoveryStringAsync = createAsyncThunk<
  { codes: string[] },
  undefined,
  IThunkRejectValue
>("auth/get-recovery", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getRecoveryStringApi();
    const codes = response.data.Result;
    return fulfillWithValue({ codes });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const reGenerateRecoveryStringAsync = createAsyncThunk<
  { codes: string[] },
  undefined,
  IThunkRejectValue
>(
  "auth/regenerate-recovery",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await reGenerateRecoveryStringApi();
      const codes = response.data.Result;
      return fulfillWithValue({ codes });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    syncState: (state) => {
      state.user = getLocalStorage("user", null);
      state.login = getLocalStorage("login", null);
      state.isAuthenticate = getLocalStorage("isAuthenticate", false);
      state.onStageEnv = getLocalStorage("env", null);
    },
    logout: (state) => {
      setLocalStorage("login", null);
      setLocalStorage("user", null);
      setLocalStorage("isAuthenticate", false);
      // setLocalStorage("env", null);
      state.user = null;
      state.login = null;
      state.isAuthenticate = false;
      state.onStageEnv = null;

      if (window && window.Intercom) {
        window.Intercom("shutdown");
      }
    },
    handleSelectEnv: (state, action: PayloadAction<{ env: Environment }>) => {
      state.onStageEnv = action.payload.env;
      setLocalStorage("env", action.payload.env);
    },
    handleShowChangePassEditor: (state) => {
      state.showChangePassModal = true;
    },
    handleHideChangePassEditor: (state) => {
      state.showChangePassModal = false;
    },
    handleShowEnable2fa: (state) => {
      state.showEnable2faModal = true;
    },
    handleHideEnable2fa: (state) => {
      state.showEnable2faModal = false;
    },
    handleShowRecoveryModal: (
      state,
      action: PayloadAction<{
        recoveryString?: string[] | null;
        canCloseRecoveryModal?: boolean;
        canRegenerateRecovery?: boolean;
      }>
    ) => {
      state.showRecoveryModal = true;
      if (typeof action.payload.recoveryString !== "undefined") {
        state.recoveryString = action.payload.recoveryString;
      }
      if (typeof action.payload.canCloseRecoveryModal !== "undefined") {
        state.canCloseRecoveryModal = action.payload.canCloseRecoveryModal;
      }
      if (typeof action.payload.canRegenerateRecovery !== "undefined") {
        state.canRegenerateRecovery = action.payload.canRegenerateRecovery;
      }
    },
    handleHideRecoveryModal: (state) => {
      state.showRecoveryModal = false;
      state.recoveryString = null;
      state.canRegenerateRecovery = true;
    },
    handleChangeCanCloseRecoveryModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.canCloseRecoveryModal = action.payload;
    },
    handleSetConfettiStatus: (state, action: PayloadAction<boolean>) => {
      state.confettiStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // login case
      .addCase(loginAsync.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        const { login } = action.payload;

        if (!login.TwoFactorEnabled) {
          state.isAuthenticate = true;
          state.login = login;

          setLocalStorage("login", login);
          setLocalStorage("isAuthenticate", true);
        }

        state.loginLoading = false;
      })
      .addCase(loginAsync.rejected, (state, { payload }) => {
        state.loginLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //register
      .addCase(registerAsync.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        const { register } = action.payload;

        state.isAuthenticate = true;
        state.login = register;

        setLocalStorage("login", register);
        setLocalStorage("isAuthenticate", true);

        state.loginLoading = false;
      })
      .addCase(registerAsync.rejected, (state, { payload }) => {
        // setLocalStorage("isAuthenticate", true); //TODO
        state.loginLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //get me
      .addCase(getMeAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.userLoading = true;
      })
      .addCase(getMeAsync.fulfilled, (state, action) => {
        const { user, envName } = action.payload;
        state.user = user;
        setLocalStorage("user", user);

        if (envName) {
          const selectedEnv = user.Environments.find((e) => e.Name === envName);
          if (selectedEnv) {
            state.onStageEnv = selectedEnv;
            setLocalStorage("env", selectedEnv);
          } else {
            state.onStageEnv = user.Environments[0];
            setLocalStorage("env", user.Environments[0]);
          }
        } else {
          const prevOnStageEnv = getLocalStorage<Environment | null>(
            "env",
            null
          );
          if (
            prevOnStageEnv &&
            user.Environments.find((on) => on.Id === prevOnStageEnv.Id)
          ) {
            const env = user.Environments.find(
              (on) => on.Id === prevOnStageEnv.Id
            ) as Environment;
            state.onStageEnv = env;
            setLocalStorage("env", env);
          } else {
            state.onStageEnv = user.Environments[0];
            setLocalStorage("env", user.Environments[0]);
          }
        }
        state.userLoading = false;
      })
      .addCase(getMeAsync.rejected, (state, { payload }) => {
        state.userLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })
      // .addCase(ssoLoginAsync.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(ssoLoginAsync.fulfilled, (state, action) => {
      //   state.isAuthenticate = true;
      //   // state.user = action.payload.user
      //   // setLocalStorage('user', action.payload.user)
      //   setLocalStorage("isAuthenticate", true);
      //   state.loading = false;
      // })
      // .addCase(ssoLoginAsync.rejected, (state) => {
      //   state.loading = false;
      // })

      // .addCase(logoutAsync.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(logoutAsync.fulfilled, (state, action) => {
      //   state.isAuthenticate = true;
      //   // state.user = action.payload
      //   // localStorage.removeItem('user')
      //   localStorage.removeItem("isAuthenticate");
      //   state.loading = false;
      //   window.location.href = "/login";
      // })
      // .addCase(logoutAsync.rejected, (state, { payload }) => {
      //   state.loading = false;
      //   message.error((payload as { message: string })?.message);
      // })

      //forgot case
      .addCase(forgotPasswordAsync.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(forgotPasswordAsync.fulfilled, (state, action) => {
        state.loginLoading = false;
      })
      .addCase(forgotPasswordAsync.rejected, (state, { payload }) => {
        state.loginLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //reset password case
      .addCase(resetPasswordAsync.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.loginLoading = false;
      })
      .addCase(resetPasswordAsync.rejected, (state, { payload }) => {
        state.loginLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //change password case
      .addCase(changePasswordAsync.pending, (state) => {
        state.changePassLoading = true;
      })
      .addCase(changePasswordAsync.fulfilled, (state, action) => {
        state.changePassLoading = false;
      })
      .addCase(changePasswordAsync.rejected, (state, { payload }) => {
        state.changePassLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //verification email
      .addCase(verificationEmailAsync.pending, (state) => {
        state.emailVerificationLoading = true;
      })
      .addCase(verificationEmailAsync.fulfilled, (state, action) => {
        state.emailVerificationLoading = false;
      })
      .addCase(verificationEmailAsync.rejected, (state, { payload }) => {
        state.emailVerificationLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //resend verification email
      .addCase(resendVerificationEmailAsync.pending, (state) => {
        state.emailVerificationLoading = true;
      })
      .addCase(resendVerificationEmailAsync.fulfilled, (state, action) => {
        state.emailVerificationLoading = false;
        toast.success("Verification email sent successfully", {
          className: ToastClasses,
        });
      })
      .addCase(resendVerificationEmailAsync.rejected, (state, { payload }) => {
        state.emailVerificationLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //update profile
      .addCase(updateProfileAsync.pending, (state) => {
        state.profileUpdateLoading = true;
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.profileUpdateLoading = false;
        toast.success("Profile update successfully", {
          className: ToastClasses,
        });
      })
      .addCase(updateProfileAsync.rejected, (state, { payload }) => {
        state.profileUpdateLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //change password case
      .addCase(updateProfileAvatarAsync.pending, (state) => {
        state.changeAvatarLoading = true;
      })
      .addCase(updateProfileAvatarAsync.fulfilled, (state, action) => {
        state.changeAvatarLoading = false;
      })
      .addCase(updateProfileAvatarAsync.rejected, (state, { payload }) => {
        state.changeAvatarLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //generate otp
      .addCase(generateOTPAsync.pending, (state) => {
        state.generateOTPloading = true;
      })
      .addCase(generateOTPAsync.fulfilled, (state, action) => {
        state.generateOTPloading = false;
      })
      .addCase(generateOTPAsync.rejected, (state, { payload }) => {
        state.generateOTPloading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //enable 2fa
      .addCase(enableTwoFactorAsync.pending, (state) => {
        state.enable2FAloading = true;
      })
      .addCase(enableTwoFactorAsync.fulfilled, (state, action) => {
        state.enable2FAloading = false;
      })
      .addCase(enableTwoFactorAsync.rejected, (state, { payload }) => {
        state.enable2FAloading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //generate otp
      .addCase(getRecoveryStringAsync.pending, (state) => {
        state.recoveryLoading = true;
      })
      .addCase(getRecoveryStringAsync.fulfilled, (state, action) => {
        state.recoveryLoading = false;
        state.recoveryString = action.payload.codes;
      })
      .addCase(getRecoveryStringAsync.rejected, (state, { payload }) => {
        state.recoveryLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //generate otp
      .addCase(reGenerateRecoveryStringAsync.pending, (state) => {
        state.recoveryLoading = true;
      })
      .addCase(reGenerateRecoveryStringAsync.fulfilled, (state, action) => {
        state.recoveryLoading = false;
        state.recoveryString = action.payload.codes;
      })
      .addCase(reGenerateRecoveryStringAsync.rejected, (state, { payload }) => {
        state.recoveryLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //disable 2fa
      .addCase(disableTwoFactorAsync.pending, (state) => {
        state.disable2FAloading = true;
      })
      .addCase(disableTwoFactorAsync.fulfilled, (state, action) => {
        state.disable2FAloading = false;
      })
      .addCase(disableTwoFactorAsync.rejected, (state, { payload }) => {
        state.disable2FAloading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      })

      //validate 2fa
      .addCase(validateTwoFactorAsync.pending, (state) => {
        state.validate2FAloading = true;
      })
      .addCase(validateTwoFactorAsync.fulfilled, (state, action) => {
        const { login } = action.payload;
        state.isAuthenticate = true;
        state.login = login;

        setLocalStorage("login", login);
        setLocalStorage("isAuthenticate", true);

        state.validate2FAloading = false;
      })
      .addCase(validateTwoFactorAsync.rejected, (state, { payload }) => {
        state.validate2FAloading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectAuth = (state: RootState) => state.auth;
export const selectIsAuthenticate = (state: RootState) =>
  state.auth.isAuthenticate;

export const selectUser = (state: RootState) => state.auth.user;
export const selectUserLoading = (state: RootState) => state.auth.userLoading;

export const selectLogin = (state: RootState) => state.auth.login;
export const selectLoginLoading = (state: RootState) => state.auth.loginLoading;

export const selectOnStageEnv = (state: RootState) => state.auth.onStageEnv;

export const selectEmailVerificationLoading = (state: RootState) =>
  state.auth.emailVerificationLoading;

export const selectProfileUpdateLoading = (state: RootState) =>
  state.auth.profileUpdateLoading;

export const selectShowChangePassEditor = (state: RootState) =>
  state.auth.showChangePassModal;
export const selectChangePassLoading = (state: RootState) =>
  state.auth.changePassLoading;

export const selectShowEnable2fa = (state: RootState) =>
  state.auth.showEnable2faModal;

export const selectShowRecoveryModal = (state: RootState) =>
  state.auth.showRecoveryModal;
export const selectRecoveryString = (state: RootState) =>
  state.auth.recoveryString;
export const selectRecoveryLoading = (state: RootState) =>
  state.auth.recoveryLoading;
export const selectCanRegenerateRecoveryString = (state: RootState) =>
  state.auth.canRegenerateRecovery;

export const selectCanCloseRecoveryModal = (state: RootState) =>
  state.auth.canCloseRecoveryModal;

export const selectGenerateOTPloading = (state: RootState) =>
  state.auth.generateOTPloading;
export const selectEnable2FAloading = (state: RootState) =>
  state.auth.enable2FAloading;
export const selectDisable2FAloading = (state: RootState) =>
  state.auth.disable2FAloading;
export const selectValidate2FAloading = (state: RootState) =>
  state.auth.validate2FAloading;

export const selectConfettiStatus = (state: RootState) =>
  state.auth.confettiStatus;

export const {
  syncState,
  logout,
  handleSelectEnv,
  handleShowChangePassEditor,
  handleHideChangePassEditor,
  handleShowEnable2fa,
  handleHideEnable2fa,
  handleShowRecoveryModal,
  handleHideRecoveryModal,
  handleChangeCanCloseRecoveryModal,
  handleSetConfettiStatus,
} = authSlice.actions;
export default authSlice.reducer;
