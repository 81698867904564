import { useMemo } from "react";
import { formatTimestamp } from "../../../utils/date";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GraphChartCustomTooltipProps } from "../../../types/s3";
import { round } from "../../../utils";
import { EmptyLineChart } from "../../layouts/NotData";
import { Flex, Typography } from "djuno-design";
import { MetricChartData } from "../../../types";

export const KuberoMetricChart: React.FC<{
  data: Array<MetricChartData>;
  chartName?: string;
  yName?: string;
}> = ({ data, chartName, yName }) => {
  const combineLineDataMemo = useMemo(() => {
    return () => combineKuberoGraphLineData(data);
  }, [data]);

  const renderXAxis = () => {
    return (
      <XAxis
        dataKey="name"
        tick={{ fontSize: 10 }}
        // interval={Math.ceil(combineLineDataMemo().length / 2)}
        // domain={[-1, 1]}
      />
    );
  };

  const renderYAxis = () => (
    <YAxis
      tick={{ fontSize: 10 }}
      label={{
        value: yName,
        style: { textAnchor: "middle", fontSize: 12, fontWeight: 600 },
        angle: -90,
        position: "left",
        offset: -10,
      }}
      // tickFormatter={(value) =>
      //   humanizeSize(value, { fractionDigits: 1 }).join("B")
      // }
    />
  );

  const renderChart = () => (
    <AreaChart
      data={combineLineDataMemo()}
      margin={{ top: 2, right: 10, bottom: 0, left: 10 }}
    >
      <defs>
        {data &&
          data.map((d, i) => {
            const _key = i;
            return (
              <linearGradient
                key={_key}
                id={`area-color-${_key}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor="rgba(31,143,255,0.1)"
                  stopOpacity="rgba(31,143,255,0.1)"
                />
                <stop offset="95%" stopColor="#eee" stopOpacity={0} />
              </linearGradient>
            );
          })}
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      {renderXAxis()}
      {renderYAxis()}
      <Tooltip content={GraphCustomTooltip} />
      {/* <Legend verticalAlign="bottom" hanging={200} /> */}
      {data &&
        data.map((d, i) => {
          const _key = i;
          return (
            <Area
              key={`area-${_key}`}
              dataKey={d.Name ? d.Name + "_" + i : "value"}
              type="monotone"
              fill={`url(#area-color-${_key})`}
              strokeWidth={2}
              stroke={chartColor(i)}
              strokeOpacity={1}
            />
          );
        })}
    </AreaChart>
  );

  if (data && data.length === 0)
    return <EmptyLineChart className="bg-primary-50/50 dark:bg-dark-3/80" />;
  return <ResponsiveContainer>{renderChart()}</ResponsiveContainer>;
};

export const combineKuberoGraphLineData = (
  data: MetricChartData[] | null,
  dateFormat: string = "DD/MM/YYYY HH:mm"
) => {
  let combinedData: Record<string, any>[] = [];

  if (
    data !== null &&
    data !== undefined &&
    data.length > 0 &&
    typeof data[0].Data !== "undefined"
  ) {
    const basicMetric = data[0];
    basicMetric.Data.forEach(([timestamp, value], i) => {
      const time = formatTimestamp(timestamp / 1000, dateFormat, {
        isUTC: false,
      }).datetime;

      const entry = {
        name: time,
        timestamp,
        [basicMetric.Name ? basicMetric.Name + "_0" : "value"]: value,
      };
      combinedData.push(entry);
    });

    if (data.length > 1) {
      const extraMetrics = data.slice(1);
      for (let i = 0; i < extraMetrics.length; i++) {
        const metric = extraMetrics[i];
        combinedData = combinedData.map((cd, j) => ({
          ...cd,
          [metric.Name ? metric.Name + "_" + (i + 1) : "value"]: metric.Data[j]
            ? metric.Data[j][1]
            : 0,
        }));
      }
    }
  }
  // Iterate over the values array of each line data
  return combinedData;
};

const GraphCustomTooltip = ({
  active,
  payload,
}: GraphChartCustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white/70 dark:bg-dark-3/80 text-xs p-2 border dark:border-dark-2 rounded-md max-h-40 overflow-y-auto">
        <Flex direction="col">
          <Typography.Text className="text-center" size="xs" uiType="secondary">
            {
              formatTimestamp(
                payload[0].payload.timestamp / 1000,
                "DD/MM/YYYY HH:mm",
                { isUTC: false }
              ).datetime
            }
          </Typography.Text>
          {payload.map((item, i) => (
            <Flex items="center" className="gap-1" key={i}>
              <span
                className="w-2 h-2 rounded-full"
                style={{ backgroundColor: chartColor(i) }}
              />
              <Typography.Text size="xs" className="label">{`${
                item.dataKey.split("_")[0]
              } : ${round(item.payload[item.dataKey], 4)}`}</Typography.Text>
            </Flex>
          ))}
        </Flex>
      </div>
    );
  }
};

export const kuberoMetricChartName = (label: string) => {
  if (/^[A-Z]+$/.test(label)) {
    return label; // Return the label as-is if it's fully uppercase
  }
  return label.match(/([A-Z][a-z]*|[A-Z]+(?=[A-Z]|$))/g)?.join(" ") || "";
};

export const kuberoMetricChartYName = (label: string) => {
  switch (label) {
    case "Traffic":
      return "KB";

    case "ThroughputRate":
      return "Req/sec";

    case "ThroughputRequest":
      return "Req";

    case "ResponsetTime":
      return "miliseconds";

    case "CPU":
      return "millicores";

    case "Memory":
      return "MiB";

    default:
      return "";
  }
};

const chartColor = (i: number) => {
  const colors = [
    "rgb(0 ,116 ,229)",
    "rgb(249 ,115 ,22)",
    "rgb(126 ,34 ,206)",
    "rgb(148 ,11 ,85)",
    "rgb(99 ,110 ,23)",
  ];
  const colorsLen = colors.length;

  if (i <= colorsLen - 1) {
    return colors[i];
  } else {
    return colors[i - colorsLen];
  }
};
