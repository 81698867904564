import { jwtEnvAxios } from ".";
import { WebAppEnvironment, WebAppSecretFile } from "../types/web-app";

//app-urls
// const GET_WEB_APP_LOGS_API_URL = (id: string) => `/web-apps/${id}/logs`;
const GET_WEB_APP_DISK_API_URL = (id: string) => `/web-apps/${id}/disk`;
const SAVE_WEB_APP_DISK_API_URL = (id: string) => `/web-apps/${id}/disk`;
const DELETE_WEB_APP_DISK_API_URL = (id: string) => `/web-apps/${id}/disk`;
const GET_WEB_APP_ENVS_API_URL = (id: string) => `/web-apps/${id}/env`;
const SAVE_WEB_APP_ENVS_API_URL = (id: string) => `/web-apps/${id}/env`;
const GET_WEB_APP_SECRET_FILES_API_URL = (id: string) =>
  `/web-apps/${id}/secret-files`;
const SAVE_WEB_APP_SECRET_FILES_API_URL = (id: string) =>
  `/web-apps/${id}/secret-files`;
const GET_WEB_APP_PREVIEWS_API_URL = (id: string) => `/web-apps/${id}/previews`;
const GET_WEB_APP_SHARED_API_URL = (id: string) => `/web-apps/${id}/shared`;
const CREATE_WEB_APP_SHARING_API_URL = (id: string) =>
  `/web-apps/${id}/sharing`;
const DELETE_WEB_APP_SHARING_API_URL = (id: string, sharedId: number) =>
  `/web-apps/${id}/sharing/${sharedId}`;
const UPDATE_WEB_APP_DOCKER_COMMAND_API_URL = (id: string) =>
  `/web-apps/${id}/update-docker-command`;
const UPDATE_WEB_APP_PREDEPLOY_COMMAND_API_URL = (id: string) =>
  `/web-apps/${id}/update-pre-deploy-command`;
const UPDATE_WEB_APP_HEALTH_CHECK_PATH_API_URL = (id: string) =>
  `/web-apps/${id}/update-health-check-path`;
const GET_WEB_APP_METRICS_API_URL = (id: string, time: number) =>
  `/managed-services/${id}/metrics?time=${time}`;
const GET_WEB_APP_Logs_API_URL = (id: string) => `/managed-services/${id}/logs`;

//web-apps
export function getWebAppsApi() {
  return jwtEnvAxios().get(`/managed-services`);
}

export function createWebAppsApi(data: any) {
  return jwtEnvAxios().post(`/managed-services`, data);
}

// export function updateWeb3AuthEndpointApi(
//   id: number,
//   data: Web3AuthEndpointApiData
// ) {
//   return jwtEnvAxios().put(UPDATE_WEB3_AUTH_API_URL(id), data);
// }

export function deleteWebAppApi(id: number) {
  return jwtEnvAxios().delete(`/managed-services/${id}`);
}

export function suspendWebAppApi(id: number) {
  return jwtEnvAxios().put(`/managed-services/${id}/suspend`);
}

export function resumeWebAppApi(id: number) {
  return jwtEnvAxios().put(`/managed-services/${id}/resume`);
}

export function getWebAppImagesApi(query?: string, credentialId?: string) {
  return jwtEnvAxios().get(
    `/managed-services/images?query=${query}${
      credentialId ? `&registryId=${credentialId}` : ""
    }`
  );
}
export function getWebAppTagsApi(image: string, credentialId?: string) {
  return jwtEnvAxios().get(
    `/managed-services/tags?image=${image}${
      credentialId ? `&registryId=${credentialId}` : ""
    }`
  );
}

export function getWebAppCheckNameApi(name: string) {
  return jwtEnvAxios().get(`/managed-services/exist-name?name=${name}`);
}

//web-app
export function getWebAppApi(id: string) {
  return jwtEnvAxios().get(`/managed-services/${id}`);
}

export function getWebAppOverviewApi(id: string) {
  return jwtEnvAxios().get(`/managed-services/${id}/overview`);
}

export function updateWebAppApi(id: string, data: any) {
  return jwtEnvAxios().put(`/managed-services/${id}`, data);
}

export function restartWebAppApi(id: string, data: any) {
  return jwtEnvAxios().post(`/managed-services/${id}/restart`, data);
}

// export function getWebAppLogsApi(id: string) {
//   return jwtEnvAxios().get(GET_WEB_APP_LOGS_API_URL(id));
// }

export function getWebAppDiskApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_DISK_API_URL(id));
}

export function saveWebAppDiskApi(id: string, data: any) {
  return jwtEnvAxios().post(SAVE_WEB_APP_DISK_API_URL(id), data);
}

export function deleteWebAppDiskApi(id: string) {
  return jwtEnvAxios().delete(DELETE_WEB_APP_DISK_API_URL(id));
}

export function getWebAppEnvironmentsApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_ENVS_API_URL(id));
}

export function saveWebAppEnvironmentsApi(
  id: string,
  envs: WebAppEnvironment[]
) {
  return jwtEnvAxios().post(SAVE_WEB_APP_ENVS_API_URL(id), { envs });
}

export function getWebAppSecretFilesApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_SECRET_FILES_API_URL(id));
}

export function saveWebAppSecretFilesApi(
  id: string,
  secretFiles: WebAppSecretFile[]
) {
  return jwtEnvAxios().post(SAVE_WEB_APP_SECRET_FILES_API_URL(id), {
    secretFiles,
  });
}

export function getWebAppPreviewsApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_PREVIEWS_API_URL(id));
}

export function getWebAppSharedApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_SHARED_API_URL(id));
}

export function createWebAppSharingApi(id: string, data: { Email: string }) {
  return jwtEnvAxios().post(CREATE_WEB_APP_SHARING_API_URL(id), {
    data,
  });
}

export function deleteWebAppSharingApi(id: string, sharedId: number) {
  return jwtEnvAxios().delete(DELETE_WEB_APP_SHARING_API_URL(id, sharedId));
}

// export function getWebAppManualScalingApi(id: string) {
//   return jwtEnvAxios().get(GET_WEB_APP_MANUAL_SCALING_API_URL(id));
// }

export function updateWebAppDockerCommandApi(
  id: string,
  data: { DockerCommand: string }
) {
  return jwtEnvAxios().post(UPDATE_WEB_APP_DOCKER_COMMAND_API_URL(id), data);
}

export function updateWebAppPreDeployCommandApi(
  id: string,
  data: { PreDeployCommand: string }
) {
  return jwtEnvAxios().post(UPDATE_WEB_APP_PREDEPLOY_COMMAND_API_URL(id), data);
}

export function updateWebAppHealthCheckPathApi(
  id: string,
  data: { Path: string }
) {
  return jwtEnvAxios().post(UPDATE_WEB_APP_HEALTH_CHECK_PATH_API_URL(id), data);
}

export function getWebAppMetricsApi(id: string, time: number) {
  return jwtEnvAxios().get(GET_WEB_APP_METRICS_API_URL(id, time));
}
export function getWebAppLogsApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_Logs_API_URL(id));
}
