import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import {
  WebAppCreateState,
  WebAppDeployTypes,
  // WebAppPlan,
} from "../../types/web-app";
import {
  createWebAppsApi,
  getWebAppCheckNameApi,
  getWebAppImagesApi,
  getWebAppTagsApi,
} from "../../apis/webAppsAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: WebAppCreateState = {
  //------------------step-1-------------//
  deployType: "image",

  //------------------step-2-------------//
  //----git:
  //----image:
  imageCredentialId: null,

  selectedImage: "",
  imageQuery: "",
  imagesLoading: false,
  imagesSearchResult: [],

  selectedTag: "",
  tagQuery: "",
  tagsLoading: false,
  tagsSearchResult: [],

  //----:

  //------------------step-3-------------//
  //notic: some data stored in form object in the WebAppCreatePage component
  isValidName: null,
  nameValidationLoading: false,
  hasDisk: false,

  //
  createLoading: false,
};

export const getWebAppImagesAsync = createAsyncThunk<
  { images: string[] },
  { query: string },
  IThunkRejectValue
>(
  "web-apps/validate-image",
  async ({ query }, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const { webAppCreate } = getState() as RootState;
      const { imageCredentialId } = webAppCreate;
      const response = await getWebAppImagesApi(
        query,
        imageCredentialId || undefined
      );
      return fulfillWithValue({ images: response.data.Result });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppTagsAsync = createAsyncThunk<
  { tags: string[] },
  undefined,
  IThunkRejectValue
>(
  "web-apps/validate-tag",
  async (_, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const { webAppCreate } = getState() as RootState;
      const { selectedImage, imageCredentialId } = webAppCreate;
      const response = await getWebAppTagsApi(
        selectedImage,
        imageCredentialId || undefined
      );
      return fulfillWithValue({ tags: response.data.Result });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppCheckNameAsync = createAsyncThunk<
  { isValidName: boolean | null },
  { name: string },
  IThunkRejectValue
>(
  "web-apps/check-name",
  async ({ name }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppCheckNameApi(name);
      return fulfillWithValue({ isValidName: !response.data.Result });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createWebAppsAsync = createAsyncThunk<
  boolean,
  { data: any },
  IThunkRejectValue
>("web-apps/create", async ({ data }, { rejectWithValue }) => {
  try {
    await createWebAppsApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const webAppCreateSlice = createSlice({
  name: "webAppCreate",
  initialState,
  reducers: {
    handleClearWebAppCreationSlice: (state) => {
      state.deployType = initialState.deployType;
      state.imageCredentialId = initialState.imageCredentialId;
      state.selectedImage = initialState.selectedImage;
      state.imageQuery = initialState.imageQuery;
      state.imagesSearchResult = initialState.imagesSearchResult;
      state.imagesLoading = initialState.imagesLoading;
      state.selectedTag = initialState.selectedTag;
      state.tagQuery = initialState.tagQuery;
      state.tagsSearchResult = initialState.tagsSearchResult;
      state.tagsLoading = initialState.tagsLoading;
      state.createLoading = initialState.createLoading;
      state.isValidName = initialState.isValidName;
    },

    //step-1
    handleChangeWebAppDeployType: (
      state,
      action: PayloadAction<WebAppDeployTypes>
    ) => {
      state.deployType = action.payload;
    },
    //step-2
    handleSetWebAppCredentialId: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.imageCredentialId = action.payload
        ? action.payload.toString()
        : null;
    },
    handleSetWebAppImageValue: (state, action: PayloadAction<string>) => {
      state.selectedImage = action.payload;
    },
    handleSetWebAppImageQuery: (state, action: PayloadAction<string>) => {
      state.imageQuery = action.payload;
    },
    handleSetWebAppImagesResult: (state, action: PayloadAction<string[]>) => {
      state.imagesSearchResult = action.payload;
    },

    handleSetWebAppTagValue: (state, action: PayloadAction<string>) => {
      state.selectedTag = action.payload;
    },
    handleSetWebAppTagQuery: (state, action: PayloadAction<string>) => {
      state.tagQuery = action.payload;
    },
    handleSetWebAppTagsResult: (state, action: PayloadAction<string[]>) => {
      state.tagsSearchResult = action.payload;
    },

    //step-3
    handleChangeWebAppIsValidName: (
      state,
      action: PayloadAction<boolean | null>
    ) => {
      state.isValidName = action.payload;
    },
    handleChangeWebAppHasDisk: (state, action: PayloadAction<boolean>) => {
      state.hasDisk = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebAppImagesAsync.pending, (state) => {
        state.imagesLoading = true;
      })
      .addCase(getWebAppImagesAsync.fulfilled, (state, { payload }) => {
        state.imagesLoading = false;
        state.imagesSearchResult = payload.images;
      })
      .addCase(getWebAppImagesAsync.rejected, (state, { payload }) => {
        state.imagesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppTagsAsync.pending, (state) => {
        state.tagsLoading = true;
      })
      .addCase(getWebAppTagsAsync.fulfilled, (state, { payload }) => {
        state.tagsSearchResult = payload.tags;
        state.tagsLoading = false;
      })
      .addCase(getWebAppTagsAsync.rejected, (state, { payload }) => {
        state.tagsLoading = false;
      });

    builder
      .addCase(createWebAppsAsync.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createWebAppsAsync.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(createWebAppsAsync.rejected, (state, { payload }) => {
        state.createLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppCheckNameAsync.pending, (state) => {
        state.nameValidationLoading = true;
      })
      .addCase(getWebAppCheckNameAsync.fulfilled, (state, action) => {
        state.nameValidationLoading = false;
        state.isValidName = action.payload.isValidName;
      })
      .addCase(getWebAppCheckNameAsync.rejected, (state, { payload }) => {
        state.nameValidationLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

//step-1
export const selectWebAppSelectedDeployType = (state: RootState) =>
  state.webAppCreate.deployType;

//step-2
export const selectWebAppImageCredentialId = (state: RootState) =>
  state.webAppCreate.imageCredentialId;

export const selectWebAppImageValue = (state: RootState) =>
  state.webAppCreate.selectedImage;
export const selectWebAppImageQuery = (state: RootState) =>
  state.webAppCreate.imageQuery;
export const selectWebAppImagesLoading = (state: RootState) =>
  state.webAppCreate.imagesLoading;
export const selectWebAppSearchImages = (state: RootState) =>
  state.webAppCreate.imagesSearchResult;

export const selectWebAppTagValue = (state: RootState) =>
  state.webAppCreate.selectedTag;
export const selectWebAppTagQuery = (state: RootState) =>
  state.webAppCreate.tagQuery;
export const selectWebAppTagsLoading = (state: RootState) =>
  state.webAppCreate.tagsLoading;
export const selectWebAppSearchTags = (state: RootState) =>
  state.webAppCreate.tagsSearchResult;

//step-3
export const selectWebAppIsValidName = (state: RootState) =>
  state.webAppCreate.isValidName;
export const selectWebAppNameValidationLoading = (state: RootState) =>
  state.webAppCreate.nameValidationLoading;
export const selectWebAppHasDisk = (state: RootState) =>
  state.webAppCreate.hasDisk;

export const selectWebAppCreateLoading = (state: RootState) =>
  state.webAppCreate.createLoading;

export const {
  handleClearWebAppCreationSlice,

  //step-1
  handleChangeWebAppDeployType,

  //step-2
  handleSetWebAppCredentialId,
  handleSetWebAppImageValue,
  handleSetWebAppImageQuery,
  handleSetWebAppImagesResult,
  handleSetWebAppTagValue,
  handleSetWebAppTagQuery,
  handleSetWebAppTagsResult,

  //step-3
  handleChangeWebAppIsValidName,
  handleChangeWebAppHasDisk,
  //
} = webAppCreateSlice.actions;
export default webAppCreateSlice.reducer;
