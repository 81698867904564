import { useCallback, useEffect, useMemo, useState } from "react";
import RevolutCheckout, { Mode } from "@revolut/checkout";
import { ReactComponent as RevolutIcon } from "./../../../assets/icons/revolut.svg";
import { Button, Typography, Loading, AnimatedFormError } from "djuno-design";

const CardDetailsBox: React.FC<
  React.PropsWithChildren<{
    beforInitFn: () => Promise<string | undefined>;
    afterInitFn: (orderToken: string) => Promise<void>;
    initLoading?: boolean;
    savingLoading?: boolean;
    username?: string;
  }>
> = ({
  beforInitFn,
  afterInitFn,
  initLoading,
  savingLoading,
  username,
  children,
}) => {
  // console.log("username:", username);
  const [revolutSetupLoading, setRevolutSetupLoading] = useState(true);
  const [revolutPayLoading, setRevolutPayLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const name = useMemo(() => {
    if (!username) return "unknown user";
    const wordsCount = username.split(" ").filter(Boolean).length;
    if (wordsCount < 2) {
      return username + " *[djuno-cloud]";
    }
    return username;
  }, [username]);

  console.log("rRevolut user name:", name);

  const handleInitiateRevolut = useCallback(() => {
    const cardFieldTarget = document.getElementById("card-field");
    const buttonTarget = document.getElementById("button-submit");

    //show card details inputs
    if (cardFieldTarget && buttonTarget) {
      try {
        setRevolutSetupLoading(true);
        beforInitFn().then((orderToken) => {
          console.log({ orderToken });
          if (orderToken) {
            const mode =
              (process.env.REACT_APP_REVOLUT_TYPE as Mode) || "sandbox";
            RevolutCheckout(orderToken, mode).then(function (instance) {
              setRevolutSetupLoading(false);
              const cardField = instance.createCardField({
                target: cardFieldTarget,
                onSuccess() {
                  console.log("onSuccess");
                  setRevolutPayLoading(false);
                  afterInitFn(orderToken);
                },
                onError(error) {
                  console.error("error");
                  console.log({ error });
                  setRevolutPayLoading(false);
                  setErrorMessage(`Something went wrong. ${error}`);
                  handleInitiateRevolut();
                },
                styles: {
                  default: {
                    color: "#858585",
                  },
                  autofilled: {
                    color: "#858585",
                  },
                },
              });

              buttonTarget.addEventListener("click", () => {
                setRevolutPayLoading(true);
                cardField.submit({
                  savePaymentMethodFor: "merchant",
                  name,
                });
              });
            });
          }
        });
      } catch (e) {
        console.log({ e });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleInitiateRevolut();
  }, [handleInitiateRevolut]);

  return (
    <>
      <div className="flex flex-col justify-center w-full min-h-[100px]">
        {initLoading && (
          <div className="w-full flex flex-col justify-center items-center h-full gap-2">
            <Loading borderSize={2} />
            <Typography.Text className="!text-sm">
              Please wait...
            </Typography.Text>
          </div>
        )}

        {/* {!savingLoading && ( */}
        <div className="flex items-center w-full text-slate-800 dark:text-slate-200">
          <div className="w-full " id="card-field"></div>
        </div>
        {/* )} */}

        {/* <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="text-red-500 mt-5 text-sm"
            >
              {errorMessage}
            </motion.div>
          )}
        </AnimatePresence> */}
        <AnimatedFormError error={errorMessage} />
      </div>
      {children}
      <div className="mt-4 flex justify-between items-center">
        <div className="flex items-center gap-1">
          <Typography.Text className="!text-xs" uiType="secondary">
            Powered by
          </Typography.Text>
          <Typography.Link href="https://revolut.com" target="_blank">
            <RevolutIcon className="h-2.5 text-slate-600 dark:text-slate-400" />
          </Typography.Link>
        </div>
        <Button
          loading={initLoading || revolutSetupLoading || revolutPayLoading}
          className="w-[40px]"
          uiType="primary"
          id="button-submit"
          disabled={savingLoading}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default CardDetailsBox;
