import { Container } from "@mui/material";
import { Button, Flex, Typography } from "djuno-design";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useMemo } from "react";

const newsItems = [
  {
    title:
      "DJIB Launches First Ever Enterprise Grade Decentralised Data Storage Drive",
    description:
      "ZUG, Switzerland, Sept. 01, 2022 (GLOBE NEWSWIRE) -- Today DJIB launched the first ever end-to-end encrypted ...",
    url: "https://www.bloomberg.com/press-releases/2022-09-01/djib-launches-first-ever-enterprise-grade-decentralised-data-storage-drive",
    imageUrl: "https://webcdn.djuno.io/cdn/bloomberg.png",
  },
  {
    title: "The Impact of Policy and Regulation on Data Storage and Management",
    description:
      "The tech world has erupted amid the rapid growth of Web3, the third iteration of the Internet. Web3 is ...",
    url: "https://www.enterprisesecuritytech.com/post/the-impact-of-policy-and-regulation-on-data-storage-and-management",
    imageUrl: "https://webcdn.djuno.io/cdn/enterprisesecuritytech.png",
  },
  {
    title:
      "How decentralised storage networks can change the game for the pharmaceutical industry",
    description:
      "The way pharmaceutical companies manage data carries inherent risks, as exemplified by the constant …",
    url: "https://pharmaphorum.com/views-and-analysis/how-decentralised-storage-networks-can-change-the-game-for-pharma",
    imageUrl: "https://webcdn.djuno.io/cdn/storage-networks.jpg",
  },
  {
    title: "Would the insurance industry benefit from Web3 adoption?",
    description:
      "Due to the complexity of the insurance industry, the adoption of new technologies has ...",
    url: "https://www.dig-in.com/opinion/would-the-insurance-benefit-from-web3-blockchain",
    imageUrl:
      "https://webcdn.djuno.io/cdn/would-the-insurance-benefit-from-web3-blockchain.png",
  },
  {
    title: "Web3 storage is not ready for enterprise backup",
    description:
      "Centralized network architecture refers to the architecture where systems and components ...",
    url: "https://blocksandfiles.com/2022/12/05/web3-storage-is-not-ready-for-enterprise-backup",
    imageUrl:
      "https://webcdn.djuno.io/cdn/web3-storage-is-not-ready-for-enterprise-backup.png",
  },
  {
    title: "How the Insurance Industry Will Benefit from Web3 Adoption",
    description:
      "The insurance industry has always been an incredibly complex beast that is sometimes ...",
    url: "https://www.iamagazine.com/strategies/how-the-insurance-industry-will-benefit-from-web3-adoption",
    imageUrl:
      "https://webcdn.djuno.io/cdn/how-the-insurance-industry-will-benefit-from-web3-adoption.png",
  },
  {
    title: "Blockchain Faces Data Storage ‘Glass Ceiling’",
    description:
      "Blockchain has had a rocky start in shaking its image problem and lingering associations ...",
    url: "https://www.sdxcentral.com/articles/interview/djuno-ceo-blockchain-faces-data-storage-glass-ceiling/2023/01",
    imageUrl:
      "https://webcdn.djuno.io/cdn/djuno-ceo-blockchain-faces-data-storage-glass-ceiling.png",
  },
];

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  770: { items: 2 },
  1024: { items: 3 },
  1440: { items: 4 },
  2560: { items: 5 },
};

const NewsBox = () => {
  const items = useMemo(() => {
    return newsItems.map((item, index) => <NewsItem index={index} {...item} />);
  }, []);

  return (
    <div className="w-full py-14 my-16">
      <Container>
        <Flex direction="col" items="center" className="gap-10">
          <Flex direction="col" items="center" className="gap-4">
            <Typography.Text size="3xl">In The News</Typography.Text>
            <Typography.Text
              size="base"
              uiType="secondary"
              className="tracking-wide"
            >
              Read the publications, views, opinions, and analysis by Djuno
              aiming to keep the users up to dated
            </Typography.Text>
          </Flex>
          <AliceCarousel
            autoPlay={false}
            autoPlayStrategy="none"
            autoPlayInterval={3000}
            animationDuration={2000}
            animationType="slide"
            infinite
            mouseTracking
            items={items}
            responsive={responsive}
            disableButtonsControls
          />
        </Flex>
      </Container>
    </div>
  );
};

const NewsItem: React.FC<{
  index: number;
  title: string;
  description: string;
  url: string;
  imageUrl: string;
}> = ({ index, title, url, description, imageUrl }) => {
  return (
    <div className="item p-3 h-full" data-value={index}>
      <Flex direction="col" items="center" className="text-center gap-3 h-full">
        <div className="h-[200px] w-full overflow-hidden rounded-xl">
          <img
            src={imageUrl}
            alt=""
            rel="noreferrer"
            className="w-full h-full object-cover"
          />
        </div>
        <Flex
          direction="col"
          items="center"
          justify="between"
          className="text-center h-full flex-1 gap-5"
        >
          <Flex
            direction="col"
            items="center"
            className="text-center gap-3 flex-1"
          >
            <a href={url}>
              <Typography.Text
                size="sm"
                className="font-semibold tracking-wide"
              >
                {title.slice(0, 50) + "..."}
              </Typography.Text>
            </a>
            <Typography.Text
              size="sm"
              uiType="secondary"
              className="tracking-wide"
            >
              {description.slice(0, 80) + "..."}
            </Typography.Text>
          </Flex>
          <a
            href={url}
            className="btn-primary-line"
            target="_blank"
            rel="noreferrer"
          >
            <Button uiType="primary">Read More</Button>
          </a>
        </Flex>
      </Flex>
    </div>
  );
};

const _items = [
  <div className="item" data-value="1">
    <div className="blog-post-thumb">
      <div className="img">
        <img
          src="https://webcdn.djuno.io/cdn/bloomberg.png"
          alt=""
          rel="noreferrer"
        />
      </div>
      <div className="blog-content">
        <h3>
          <a href="https://www.bloomberg.com/press-releases/2022-09-01/djib-launches-first-ever-enterprise-grade-decentralised-data-storage-drive">
            DJIB Launches First Ever Enterprise Grade Decentralised Data Storage
            Drive
          </a>
        </h3>
        <div className="text">
          ZUG, Switzerland, Sept. 01, 2022 (GLOBE NEWSWIRE) -- Today DJIB
          launched the first ever end-to-end encrypted ...
        </div>
        <a
          href="https://www.bloomberg.com/press-releases/2022-09-01/djib-launches-first-ever-enterprise-grade-decentralised-data-storage-drive"
          className="btn-primary-line"
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  </div>,
  <div className="item" data-value="2">
    <div className="blog-post-thumb">
      <div className="img">
        <img
          src="https://webcdn.djuno.io/cdn/enterprisesecuritytech.png"
          alt=""
          rel="noreferrer"
        />
      </div>
      <div className="blog-content">
        <h3>
          <a href="https://www.enterprisesecuritytech.com/post/the-impact-of-policy-and-regulation-on-data-storage-and-management">
            The Impact of Policy and Regulation on Data Storage and Management
          </a>
        </h3>
        <div className="text">
          The tech world has erupted amid the rapid growth of Web3, the third
          iteration of the Internet. Web3 is ...
        </div>
        <a
          href="https://www.enterprisesecuritytech.com/post/the-impact-of-policy-and-regulation-on-data-storage-and-management"
          className="btn-primary-line"
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  </div>,
  <div className="item" data-value="3">
    <div className="blog-post-thumb">
      <div className="img">
        <img
          src="https://webcdn.djuno.io/cdn/storage-networks.jpg"
          alt=""
          rel="noreferrer"
        />
      </div>
      <div className="blog-content">
        <h3>
          <a href="https://pharmaphorum.com/views-and-analysis/how-decentralised-storage-networks-can-change-the-game-for-pharma">
            How decentralised storage networks can change the game for the
            pharmaceutical industry
          </a>
        </h3>
        <div className="text">
          The way pharmaceutical companies manage data carries inherent risks,
          as exemplified by the constant …
        </div>
        <a
          href="https://pharmaphorum.com/views-and-analysis/how-decentralised-storage-networks-can-change-the-game-for-pharma"
          className="btn-primary-line"
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  </div>,
  <div className="item" data-value="4">
    <div className="blog-post-thumb">
      <div className="img">
        <img
          src="https://webcdn.djuno.io/cdn/would-the-insurance-benefit-from-web3-blockchain.png"
          alt=""
          rel="noreferrer"
        />
      </div>
      <div className="blog-content">
        <h3>
          <a href="https://www.dig-in.com/opinion/would-the-insurance-benefit-from-web3-blockchain">
            Would the insurance industry benefit from Web3 adoption?
          </a>
        </h3>
        <div className="text">
          Due to the complexity of the insurance industry, the adoption of new
          technologies has ...
        </div>
        <a
          href="https://www.dig-in.com/opinion/would-the-insurance-benefit-from-web3-blockchain"
          className="btn-primary-line"
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  </div>,
  <div className="item" data-value="5">
    <div className="blog-post-thumb">
      <div className="img">
        <img
          src="https://webcdn.djuno.io/cdn/web3-storage-is-not-ready-for-enterprise-backup.png"
          alt=""
          rel="noreferrer"
          id="web3"
        />
      </div>
      <div className="blog-content">
        <h3>
          <a href="https://blocksandfiles.com/2022/12/05/web3-storage-is-not-ready-for-enterprise-backup/">
            Web3 storage is not ready for enterprise backup
          </a>
        </h3>
        <div className="text">
          Centralized network architecture refers to the architecture where
          systems and components ...
        </div>
        <a
          href="https://blocksandfiles.com/2022/12/05/web3-storage-is-not-ready-for-enterprise-backup/"
          className="btn-primary-line"
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  </div>,
  <div className="item" data-value="6">
    <div className="blog-post-thumb">
      <div className="img">
        <img
          src="https://webcdn.djuno.io/cdn/how-the-insurance-industry-will-benefit-from-web3-adoption.png"
          alt=""
          rel="noreferrer"
        />
      </div>
      <div className="blog-content">
        <h3>
          <a href="https://www.iamagazine.com/strategies/how-the-insurance-industry-will-benefit-from-web3-adoption">
            How the Insurance Industry Will Benefit from Web3 Adoption
          </a>
        </h3>
        <div className="text">
          The insurance industry has always been an incredibly complex beast
          that is sometimes ...
        </div>
        <a
          href="https://www.iamagazine.com/strategies/how-the-insurance-industry-will-benefit-from-web3-adoption"
          className="btn-primary-line"
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  </div>,
  <div className="item" data-value="7">
    <div className="blog-post-thumb">
      <div className="img">
        <img
          src="https://webcdn.djuno.io/cdn/djuno-ceo-blockchain-faces-data-storage-glass-ceiling.png"
          alt=""
          rel="noreferrer"
        />
      </div>
      <div className="blog-content">
        <h3>
          <a href="https://www.sdxcentral.com/articles/interview/djuno-ceo-blockchain-faces-data-storage-glass-ceiling/2023/01/">
            Blockchain Faces Data Storage ‘Glass Ceiling’
          </a>
        </h3>
        <div className="text">
          Blockchain has had a rocky start in shaking its image problem and
          lingering associations ...
        </div>
        <a
          href="https://www.sdxcentral.com/articles/interview/djuno-ceo-blockchain-faces-data-storage-glass-ceiling/2023/01/"
          className="btn-primary-line"
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  </div>,
];
export default NewsBox;
